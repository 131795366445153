import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface TimelineBundles {
    selectedTimelineBundles: any |null
}

const initialState: TimelineBundles = {
    selectedTimelineBundles:null
}

export const timelineBundleSlice = createSlice({
    name: 'timelineBundle',
    initialState: initialState,
    reducers: {
        updateselectedTimeline: (state, action: PayloadAction<any>) => {
            state.selectedTimelineBundles= action.payload
        },
        resetTimeline: () => initialState
    },
})

export const timelineBundleActions = timelineBundleSlice.actions
export default timelineBundleSlice.reducer
