import React from 'react'

// Material Components
import { makeStyles, Theme } from '@material-ui/core/styles'
import InfoIcon from '@mui/icons-material/Info';
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
    infoContainer: {
        display: 'flex',
        border: '1px solid #1BA098',
        backgroundColor: 'rgba(27, 160, 152, 0.09)',
        padding: '1.1rem 1.1rem',
        borderRadius: theme.shape.borderRadius,
        paddingLeft: '1rem'
    },
    infoIcon: {
        color: "#4c9799",
        width: '15px',
        height: '15px',
    },
    infoBody: {
        marginLeft: '6px',
        color: '#14a4a7',
    },
    [theme.breakpoints.up('lg')]: {
        infoBody: {
            fontSize: theme.typography.pxToRem(15),
        },
        infoIcon: {
            width: '15px',
            height: '15px',
        },
    },
}))

interface InfoCardProps {
    className?: string
    children: React.ReactNode
}

const InfoCard: React.FC<InfoCardProps> = ({ className, children }) => {
    const classes = useStyles()
    return (
        <div className={classes.infoContainer}>
            <InfoIcon className={classes.infoIcon} />
            <Typography
                component="p"
                variant="body2"
                className={`${classes.infoBody} ${className}`}
            >
                {children}
            </Typography>
        </div>
    )
}
export default InfoCard
