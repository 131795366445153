export const getItemContent = (resource: any) => {
    let code;
    if ((resource.resourceType == 'Condition') || (resource.resourceType == 'DiagnosticReport')) {     
        {resource.code?.coding &&
            resource.code?.coding.length &&
            resource.code?.coding[0].display && 
            (code=resource.code?.coding[0].display)}
        return code
    }
    if (resource.resourceType == 'DocumentReference') {
         let items;
         {!!resource.category?.length &&
            (items = resource.category
            .filter(
                (item: any) =>
                    item.coding && item.coding.length && item.coding[0].display
            )
            ?.map((x: any) => (
                x.coding[0].display
            )))}
        return items
    }

    if (resource.resourceType == 'Observation') {
        let code;
            {resource.code?.coding &&
                resource.code?.coding.length &&
                resource.code?.coding[0].display && (code=resource.code?.coding[0].display)}
             return  code
    }
    if (resource.resourceType == 'Procedure') {
        let code
        {resource.code?.coding &&
            resource.code?.coding.length &&
            resource.code?.coding[0].display && 
            (code=resource.code?.coding[0].display)}
        return code
    }
    if (resource.resourceType == 'Immunization') {
        let  VACCINE_CODE
        {resource.vaccineCode?.coding &&
            resource.vaccineCode?.coding.length &&
            resource.vaccineCode?.coding[0].display && 
            (VACCINE_CODE = resource.vaccineCode?.coding[0].display)}

        return VACCINE_CODE
    }
    if (resource.resourceType == 'Coverage') {
        let group,subgroup
        {resource.class && (group=resource.class[0].name)}
        {resource.grouping && (subgroup = resource.grouping.subGroup)}
        return group ?? subgroup
    }
    if (resource.resourceType == 'AllergyIntolerance') {
        let code
        {resource.code?.coding &&
                resource.code?.coding.length &&
                resource.code?.coding[0].display && 
            (
                code = resource.code?.coding[0].display
            )}
            
        return  code
    }
    if (resource.resourceType == 'Encounter') {
        let code
         {resource.code?.coding &&
            resource.code?.coding.length &&
            resource.code?.coding[0].display && 
            (code = resource.type[0].coding[0].display)}
        
        return code
    }
    if (resource.resourceType == 'CarePlan') {
        let Intent; 
        {resource.intent && (Intent = resource.intent)}

        return Intent
    }
    if (resource.resourceType == 'CareTeam') {
        let Intent;  
        {resource.name && (Intent = resource.name)}
        return Intent
    }
   
    
    if (resource.resourceType == 'Medication') {
        let code;
        {resource.code?.coding &&
            resource.code?.coding.length &&
            resource.code?.coding[0].display && (code = resource.code.coding[0].display)}
        return code
    }
    if (resource.resourceType == 'MedicationKnowledge') {
        let code;
        {resource.code?.coding &&
            resource.code?.coding.length &&
            resource.code?.coding[0].display && (code = resource.code.coding[0].display)}
        return code
    }
    if (resource.resourceType == 'MedicationRequest') {
        let Intent
        {resource.intent && (Intent = resource.intent)}
        return Intent
    }
    if(resource.resourceType == 'ExplanationOfBenefit'){
        let type;
        {resource.type &&
            Array.isArray(resource.type.coding) &&
            resource.type.coding.length > 0 &&
            (resource.type.coding[0].display || resource.type.coding[0].code) && (
             type= resource.type.coding[0].display || resource.type.coding[0].code)}
        return type
    }
    if (resource.resourceType == 'InsurancePlan') {
        let name
        {resource.name && (name = resource.name)}
        return name
    }
    if(resource.resourceType == 'Basic'){
        let code;
        {resource.code?.coding &&
            resource.code?.coding.length &&
            resource.code?.coding[0].display && (code = resource.code.coding[0].display)}
        return code
    }

    return resource.resourceType
}
