import React from 'react'

// Material Icons
import PersonIcon from '@mui/icons-material/Person';

// Custom Components
import BaseLoadingModal, { LoadingModalProps } from '../BaseLoadingModal'

const PatientDetailsLoadingModal: React.FC<LoadingModalProps> = ({
    open,
    onClose,
    ...props
}: LoadingModalProps) => {
    return (
        <BaseLoadingModal
            open={open}
            onClose={onClose}
            icon={<PersonIcon />}
            heading="Please wait...!"
            body="Your Data is being loaded"
            hideBackdrop={true}
            {...props}
        />
    )
}
export default PatientDetailsLoadingModal
