import React, { useEffect, useState } from 'react'
import ToSNPPcomp from '../../components/ToSNPPcomp/ToSNPPcomp';
import { fetchprivacyandpolicy } from '../../functions/ToSNPP/PrivacyPolicyFuncs';
const PrivacyAndPolicy = () => {

    const [Tnc, setTnc] = useState("");
    const [showTnc, setShowTnc] = useState(false);
    const [showSpinner, setShowSpinner] = useState(true);

    useEffect(() => {
        fetchprivacyandpolicy(setTnc, setShowTnc, setShowSpinner)
    }, []);
    return (
        <div>
            <ToSNPPcomp showSpinner={showSpinner} showTnc={showTnc} Tnc={Tnc} />
        </div>
    )
}

export default PrivacyAndPolicy
