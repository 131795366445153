import React, { useEffect, useState } from 'react'
import { Button, Carousel, CloseButton, Modal, Tab, Tabs } from 'react-bootstrap'
import GuideStepper from '../../GuideStepper/GuideStepper';
import SwipeableTextMobileStepper from './MobileStepperModal';
import MobileStepperModal from './MobileStepperModal'
//@ts-ignore
import introvideo from "../../../pages/HomePage/intro.mp4"
import { Step, StepLabel, Stepper } from '@mui/material';
import { Link } from 'react-router-dom';
import './ThreeStepModal.scss'

//@ts-ignore
const ThreeStepModal = ({ show, handleClose, setShow }) => {
    const modalshow = sessionStorage.getItem("3modal")
    const storedTheme = sessionStorage.getItem("theme");
    console.log(modalshow);

    const [watchVideo, setWatchVideo] = useState(false)
    const [watchTxt, setWatchTxt] = useState("Watch Video")
    const [closeDisable, setCloseDisable] = useState(true)

    const handleClickVideo = (txt: string) => {
        if (txt == 'Watch Video') {
            setWatchTxt('View as steps')
            setWatchVideo(true)
            setCloseDisable(false)
        }
        else {
            setWatchTxt('Watch Video')
            setWatchVideo(false)
        }
    }
    useEffect(() => {
        sessionStorage.setItem("3modal", "1")
    }, [])

    return (
        <div>
            {modalshow == "1" ?
                <Modal
                    show={show}
                    onHide={handleClose}
                    animation={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    size='xl'
                    className={storedTheme != 'dark' ? 'three_step_modal_wrapper' : 'three_step_modal_wrapper_dark'}
                >
                    <Modal.Header>
                        <Modal.Title>Quick Guide :</Modal.Title>
                        <span style={{ 'textAlign': 'right' }}>
                            <Button variant='primary' onClick={() => handleClickVideo(watchTxt)}>
                                {watchTxt}
                            </Button>
                            <CloseButton onClick={handleClose} disabled={closeDisable} />
                        </span>
                    </Modal.Header>
                    <Modal.Body style={{ 'textAlign': 'center' }}>

                        {!watchVideo ?
                            <>
                                <SwipeableTextMobileStepper setCloseDisable={setCloseDisable} storedTheme={storedTheme} /></>
                            :
                            <div className='intro_video'>
                                <video autoPlay loop muted controls plays-inline className="back-video">
                                    <source src={introvideo} type="video/mp4" />
                                </video>
                            </div>}

                        <a onClick={() => { handleClose() }} style={{ 'color': '#0d6efd' }}>Skip Intro</a>
                    </Modal.Body>
                </Modal>
                : null}
        </div>
    )
}

export default ThreeStepModal