import moment from "moment";

import { TimelineResources } from "../../constants/constants";

export const getNameAlias = (name: string) => {
  const matches = name.match(/\b(\w)/g);

  const alias = (matches && (matches[0] || "") + (matches[1] || "")) || "";

  return alias;
};

export const getTimelineNameResource = (resourceName?: string|undefined) => {
  return TimelineResources?.find((x) => x.resource === resourceName)?.name;
};

export const formatDatePeriod = (
  startDate = "",
  endDate = "",
  dateTime = ""
) => {
  const format = dateTime ? "D MMM, YYYY, h:mm a" : "D MMM, YYYY";

  if (startDate && endDate)
    return `${moment(new Date(startDate)).format(format)} - ${moment(
      new Date(endDate)
    ).format(format)}}`;

  if (startDate && !endDate)
    return `${moment(new Date(startDate)).format(format)}`;

  if (!startDate && endDate)
    return `${moment(new Date(endDate)).format(format)}`;

  return "-";
};
