import "./TimelineDetailsCardItem.css";

const TimelineDetailsCardItem = ({ label = "", value = "" }) => {
  const storedTheme = sessionStorage.getItem("theme");
  return (
    <div id={storedTheme != 'dark' ? 'timeline-details-card-item' : 'timeline-details-card-item-dark'}>
      <span className="lable-title">{label}</span>

      <span className="lable-value">{value}</span>
    </div>
  );
};

export default TimelineDetailsCardItem;
