import { formatDatePeriod } from "../util/index";
import TimelineDetailsCardDropdown from "../TimelineDetailsCardDropdown/TimelineDetailsCardDropdown";
import TimelineDetailsCardItem from "../TimelineDetailsCardItem/TimelineDetailsCardItem";
import './TimelineCard.css'
import TimelineDetailsStatus from "../TimelineDetailsCardItem/TimelineStatus";

const MedicationKnowTimelineCard = ({ resource }: any) => {
  const storedTheme = sessionStorage.getItem("theme");
  return (
    <div id="medications-timeline-card">
      <div className="status-div">
        {resource.status && (
          <TimelineDetailsStatus label="Status :" value={resource.status} />
        )}
      </div>
      <div className={storedTheme != 'dark' ? 'display-details' : 'display-details-dark'}>
        {resource.intent && (
          <TimelineDetailsCardItem label="INTENT" value={resource.intent} />
        )}
        {resource.medicationCodeableConcept?.coding &&
          resource.medicationCodeableConcept?.coding.length &&
          resource.medicationCodeableConcept?.coding[0].display && (
            <TimelineDetailsCardItem
              label="CODEABLECONCENPT "
              value={`${resource.medicationCodeableConcept?.coding[0].display}`}
            />
          )}

        {resource.requester && resource.requester.display && (
          <TimelineDetailsCardItem
            label="REQUESTER"
            value={`${resource.requester.display}`}
          />
        )}
        {resource.authoredOn && (
          <TimelineDetailsCardItem
            label="AUTHORED ON"
            value={formatDatePeriod(resource.authoredOn)}
          />
        )}
        {resource.recorded && (
          <TimelineDetailsCardItem
            label="RECORDED"
            value={formatDatePeriod(resource.recorded)}
          />
        )}

        {!!resource.category?.length && (
          <TimelineDetailsCardDropdown
            header="Category"
            items={resource.category.map((item: any) => (
              <TimelineDetailsCardItem label="Value" value={item} />
            ))}
          />
        )}

        {resource.code?.coding &&
          resource.code?.coding.length &&
          (
            <TimelineDetailsCardDropdown
              header="Code"
              items={resource.code?.coding.map((item: any) => (
                <TimelineDetailsCardItem label="" value={item.display} />
              ))}
            />
          )}

        {resource.doseForm?.coding &&
          resource.doseForm?.coding.length &&
          (
            <TimelineDetailsCardDropdown
              header="Dose Form"
              items={resource.doseForm?.coding.map((item: any) => (
                <TimelineDetailsCardItem label="" value={item.display} />
              ))}
            />
          )}
        {resource?.synonym &&
          resource?.synonym.length &&
          resource?.synonym[0] && (
            <TimelineDetailsCardItem
              label="Synonym"
              value={`${resource.synonym[0]}`}
            />
          )}

        {!!resource.encounter?.length && (
          <TimelineDetailsCardDropdown
            header="Encounter"
            items={resource.encounter.map((item: any) => (
              <TimelineDetailsCardItem label="" value={item.reference} />
            ))}
          />
        )}
        {resource.onsetDateTime && (
          <TimelineDetailsCardItem
            label="ONSET"
            value={formatDatePeriod(resource.onsetDateTime)}
          />
        )}
        {resource.recordedDate && (
          <TimelineDetailsCardItem
            label="RECORDED DATE"
            value={formatDatePeriod(resource.recordedDate)}
          />
        )}
        {resource.recorder &&
          resource.recorder.practitioner &&
          resource.recorder.practitioner.name && (
            <TimelineDetailsCardItem
              label="RECORDER"
              value={resource.recorder.practitioner.name}
            />
          )}

        {!!resource.note?.length && (
          <TimelineDetailsCardDropdown
            header="Notes"
            items={resource.note.map((item: any) => (
              <TimelineDetailsCardItem label="Text" value={item.text || ""} />
            ))}
          />
        )}

        {!!resource.reaction?.length && (
          <TimelineDetailsCardDropdown
            header="Reaction"
            items={resource.reaction.map((item: any) => {
              const resultItems = [];

              if (
                !!item.substance?.length &&
                item.substance[0].coding &&
                item.substance[0].coding.length &&
                item.substance[0].coding.display
              ) {
                resultItems.push(
                  <TimelineDetailsCardItem
                    label="SUBSTANCE"
                    value={item.substance[0].coding.display}
                  />
                );
              }

              if (
                !!item.manifestation?.length &&
                item.manifestation[0].coding &&
                item.manifestation[0].coding.length &&
                item.manifestation[0].coding.display
              ) {
                resultItems.push(
                  <TimelineDetailsCardItem
                    label="MANIFESTATION"
                    value={item.manifestation[0].coding.display}
                  />
                );
              }
            })}
          />
        )}
      </div>

    </div>
  );
};

export default MedicationKnowTimelineCard;
