import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Image,
  InputGroup,
} from "react-bootstrap";
import "./LoginPage.css";
import ModalLogin1 from "../../components/Modals/LoginPageModals/ModalLogin1";
import LoginSideBar from "../../components/SideBar/LoginSideBar/LoginSideBar";
import DeskFooterLogin from "../../components/Footer/DeskFooter/DeskFooterLogin";
import MobFooter from "../../components/Footer/MobFooter/MobFooter";
import nenyLogo from "../../images/HMNENY.jpg"
import wnyLogo from "../../images/HMWNY.jpg"
import Logo from "../../components/Logo/Logo";
import { handleHmnyClient, handlePostPayer, handlePostPayerNENY, handlePostPayerWNY } from "../../functions/LoginPage/LoginPageFuncs";
import ErrorToast from "../../components/Toasts/ErrorToast";
import appLogo from "../../images/app-logo.svg";
import { Link } from "@mui/material";
import { useNavigate } from "react-router";
import LoginPlanList from "../../components/PlansList/LoginPlanList";
import { searchItems } from "../../functions/SearchFuncs/SearchFuncs";


//@ts-ignore
const LoginPage = ({ clientName, clientLogo, linkTo, oauth_link }) => {
  // window.location.reload()
  const sesplanlist = sessionStorage.getItem("plans_list")
  const storedTheme = sessionStorage.getItem("theme");
  const planlist = sesplanlist == null ? [] : JSON.parse(sesplanlist)
  const clientLocal = localStorage.getItem("client")
  const path = window.location.pathname
  const path2 = path.replace('/', '')
  console.log("path", path2);
  const history = useNavigate()
  const [logologin, setLogologin] = useState("")

  const [hmnyClient, sethmnyClient] = useState(false);

  const [iCareclient, setICaeclient] = useState(false);

  const [spinner, setSpinner] = useState(true);

  const [btndisable, setBtndisable] = useState(true);

  const [showToast, setShowToast] = useState(false)

  const formSubmitHandler = (e: any) => {
    e.preventDefault();
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const showSpinnerTrue = () => setSpinner(true)
  const [searchInput, setSearchInput] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  var back = "<--";
  useEffect(() => {
    handleHmnyClient(clientName, sethmnyClient)
  }, []);

  return (
    <Container fluid className={storedTheme != 'dark' ? 'page-wrapper-login' : 'page-wrapper-login-dark'}>
      <Row className="content-wrapper-login">
        <Col lg={4} className="left-bar-login">
          <LoginSideBar />
        </Col>
        <Col lg={8} xs={12} sm={12} className="right-bar-login">
          <div className="loginHeader_conatiner">
            <div className="btnLogo_container">
              <Button className='back_btn' data-testid="back_button" onClick={() => history(-1)}>{back} Back</Button>
              <Logo />
            </div>
            <div className="appLogo-container">
              <img src={appLogo} alt="logo" className="HDCLogo" />
              <h1 className="app-title">HealthDataClarity</h1>
            </div>
          </div>

          {hmnyClient &&
            <Form
              className="form-complete-login hmny_form"
              onSubmit={formSubmitHandler}
              data-testid="form"
            >
              <>
                <h3>My current insurance coverage is through <br />(choose 1):</h3>
                <Image src={nenyLogo} className="PlanLogo_neny" />
                <Button
                  variant="primary"
                  type="submit"
                  className="btn-login-hmny"
                  onClick={() => { handlePostPayerNENY(handleShow, setSpinner, setBtndisable, setShowToast, setShow) }}
                  data-testid="btn_login_neny"
                  data-hover="-->"
                >
                  Select
                </Button>

                <br />
                <Image src={wnyLogo} className="PlanLogo_wny" />

                <Button
                  variant="primary"
                  type="submit"
                  className="btn-login-hmny"
                  onClick={() => {
                    handlePostPayerWNY(handleShow, setSpinner, setBtndisable, setShowToast, setShow);
                  }}
                  data-testid="btn_login_wny"
                  data-hover="-->"
                >
                  Select
                </Button>

              </>

            </Form>
          }
          {!hmnyClient &&
            <Form
              className="form-complete-login"
              onSubmit={formSubmitHandler}
              data-testid="form"
            >
              <Row className="search_login_row">
                <Col>
                  <InputGroup className="mb-3 search_bar_login">

                    <Form.Control
                      placeholder="Search here By Health Plan Name, Payer name or HNo.."
                      aria-label="payer"
                      aria-describedby="basic-addon1"
                      onChange={e => {
                        searchItems(e.target.value, setSearchInput, planlist, setFilteredResults, " ");
                      }}
                      className={storedTheme != 'dark' ? "search_input_box" : 'search_input_box search_input_box_dark'}
                      type='search'
                    />

                  </InputGroup>
                </Col>
                <Col>
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn-login"
                    onClick={() => {
                      handleShow();
                      handlePostPayer(clientName, setSpinner, setBtndisable, setShowToast, setShow);
                    }}
                    data-testid="btn_login"
                  >
                    Login with member ID and Password
                  </Button>
                </Col>
              </Row>


            </Form>
          }
          <span className="planlisted_txt" style={{ "color": storedTheme != 'dark' ? '' : 'white' }}>Plans Listed under {clientLocal}</span>
          <hr style={{ "color": storedTheme != 'dark' ? '' : 'white' }} />
          <div className="login_plan_List">
            {searchInput.length > 0 ?
              <>
                {filteredResults.length > 0 ?
                  <LoginPlanList filteredItems={filteredResults} client_name={clientLocal} />
                  :
                  <p>No results found</p>
                }
              </>
              :
              <LoginPlanList filteredItems={planlist} client_name={clientLocal} />
            }
          </div>
          <ModalLogin1
            show={show}
            handleClose={handleClose}
            oauth_link={oauth_link}
            clientName={clientName}
            spinner={spinner}
            btndisable={btndisable}
            showSpinnerTrue={showSpinnerTrue} />

        </Col>
      </Row>
      <MobFooter />
      {showToast &&
        <ErrorToast show={showToast} setShowToast={setShowToast} />
      }
    </Container>
  );
};
//@ts-ignore
export default LoginPage;
