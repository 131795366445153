import "./TimelineDetailsCardItem.css";

const TimelineDetailsStatus = ({ label = "", value = "" }) => {
    const storedTheme = sessionStorage.getItem("theme");
    return (
        <div id={storedTheme != 'dark' ? 'timeline-details-status-item' : 'timeline-details-status-item-dark'}>
            <span className="status-title">{label}</span>

            <span className="status-value">{value}</span>
        </div>
    );
};

export default TimelineDetailsStatus;
