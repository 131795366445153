function ProfileIcon({ className }: { className?: string }) {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30">
            <g clipPath="url(#clip0_13_153)">
                <path className={className}
                    d="M23.75 3.75H6.25C4.8625 3.75 3.75 4.875 3.75 6.25V23.75C3.75 25.125 4.8625 26.25 6.25 26.25H23.75C25.125 26.25 26.25 25.125 26.25 23.75V6.25C26.25 4.875 25.125 3.75 23.75 3.75ZM23.75 6.25V19.7375C20.65 17.9625 16.5375 17.5 15 17.5C13.4625 17.5 9.35 17.9625 6.25 19.7375V6.25H23.75ZM6.25 23.75V22.7875C8.425 20.825 12.9 20 15 20C17.1 20 21.575 20.825 23.75 22.7875V23.75H6.25ZM15 16.25C17.425 16.25 19.375 14.3 19.375 11.875C19.375 9.45 17.425 7.5 15 7.5C12.575 7.5 10.625 9.45 10.625 11.875C10.625 14.3 12.575 16.25 15 16.25ZM15 10C16.0375 10 16.875 10.8375 16.875 11.875C16.875 12.9125 16.0375 13.75 15 13.75C13.9625 13.75 13.125 12.9125 13.125 11.875C13.125 10.8375 13.9625 10 15 10Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_13_153">
                    <rect width="30" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default ProfileIcon
