function MedicationDispenseIcon({ className }: { className?: string }) {

    return (
        <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={className} d="M41.6119 46.0001H5.38815C2.97897 46.0001 1 44.7738 1 43.2809V13.2633C1 11.5038 3.32314 10.0642 6.16252 10.0642H40.8375C43.6769 10.0642 46 11.5038 46 13.2633V43.2809C46 44.7738 44.021 46.0001 41.6119 46.0001Z" fill="black" fillOpacity="0.5" stroke="black" strokeWidth="1.133" strokeMiterlimit="10" />
            <path className={className} d="M45.3976 22.3804H1.51611V33.2038H45.3976V22.3804Z" fill="white" />
            <path className={className} d="M34.7291 7.50488H12.186V10.2241H34.7291V7.50488Z" fill="black" fillOpacity="0.5" />
            <path className={className} d="M39.9776 9.79745C39.9776 9.79745 36.4498 9.42423 34.729 7.82471V9.85077L39.9776 9.79745Z" fill="black" />
            <path className={className} d="M7.02344 9.79745C7.02344 9.79745 10.5512 9.42423 12.272 7.82471V9.85077L7.02344 9.79745Z" fill="black" />
            <path className={className} d="M36.7074 7.50472H10.2925C9.94829 7.50472 9.60413 7.29146 9.60413 7.07819V1.42654C9.60413 1.21327 9.94829 1 10.2925 1H36.7074C37.0515 1 37.3957 1.21327 37.3957 1.42654V7.02487C37.3957 7.29145 37.0515 7.50472 36.7074 7.50472Z" stroke="black" strokeWidth="1.133" strokeMiterlimit="10" />
            <path className={className} d="M34.04 1V2.65284" stroke="black" strokeWidth="1.133" strokeMiterlimit="10" strokeLinecap="round" />
            <path className={className} d="M29.824 1V2.65284" stroke="black" strokeWidth="1.133" strokeMiterlimit="10" strokeLinecap="round" />
            <path className={className} d="M25.6078 1V2.65284" stroke="black" strokeWidth="1.133" strokeMiterlimit="10" strokeLinecap="round" />
            <path className={className} d="M21.3918 1V2.65284" stroke="black" strokeWidth="1.133" strokeMiterlimit="10" strokeLinecap="round" />
            <path className={className} d="M17.1758 1V2.65284" stroke="black" strokeWidth="1.133" strokeMiterlimit="10" strokeLinecap="round" />
            <path className={className} d="M12.9596 1V2.65284" stroke="black" strokeWidth="1.133" strokeMiterlimit="10" strokeLinecap="round" />
        </svg>
    )
}
export default MedicationDispenseIcon;