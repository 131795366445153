function AffiliationIcon({ className }: { className?: string }) {

    return (
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={className} d="M23.1081 43.2236C11.0368 43.2236 1.21619 33.6615 1.21619 21.9078C1.21619 10.1542 11.0368 0.592041 23.1081 0.592041C35.1794 0.592041 45 10.1542 45 21.9078C45 33.6615 35.1794 43.2236 23.1081 43.2236ZM23.1081 2.2973C12.0027 2.2973 2.96754 11.0947 2.96754 21.9078C2.96754 32.721 12.0027 41.5184 23.1081 41.5184C34.2135 41.5184 43.2486 32.721 43.2486 21.9078C43.2486 11.0947 34.2135 2.2973 23.1081 2.2973Z" fill="black" />
            <path className={className} d="M30.6225 29.6052H16.8099C16.2582 29.6052 15.8108 29.1504 15.8108 28.5895V23.8073C15.8108 21.1274 17.9554 18.9473 20.5911 18.9473H26.8407C29.477 18.9473 31.6216 21.1274 31.6216 23.8073V28.5895C31.6216 29.1504 31.1742 29.6052 30.6225 29.6052ZM17.809 27.5739H29.6234V23.8073C29.6234 22.2476 28.375 20.9786 26.8407 20.9786H20.5911C19.0568 20.9786 17.809 22.2476 17.809 23.8073V27.5739Z" fill="black" />
            <path className={className} d="M42.9246 20.1315H39.7781C39.3037 20.1315 38.9189 19.8663 38.9189 19.5394C38.9189 19.2124 39.3037 18.9473 39.7781 18.9473H42.9246C43.3991 18.9473 43.7838 19.2124 43.7838 19.5394C43.7838 19.8663 43.3991 20.1315 42.9246 20.1315Z" fill="black" />
            <path className={className} d="M6.22327 20.1315H2.29019C1.6971 20.1315 1.21619 19.8663 1.21619 19.5394C1.21619 19.2124 1.6971 18.9473 2.29019 18.9473H6.22327C6.81635 18.9473 7.29727 19.2124 7.29727 19.5394C7.29727 19.8663 6.81635 20.1315 6.22327 20.1315Z" fill="black" />
            <path className={className} d="M22.7676 6.012C22.284 6.012 21.8919 5.63021 21.8919 5.15937V2.03696C21.8919 1.56612 22.284 1.18433 22.7676 1.18433C23.2511 1.18433 23.6433 1.56612 23.6433 2.03696V5.15937C23.6433 5.63021 23.2511 6.012 22.7676 6.012Z" fill="black" />
            <path className={className} d="M22.7676 42.7221C22.284 42.7221 21.8919 42.3403 21.8919 41.8695V38.7474C21.8919 38.2766 22.284 37.8948 22.7676 37.8948C23.2511 37.8948 23.6433 38.2766 23.6433 38.7474V41.8695C23.6433 42.3403 23.2511 42.7221 22.7676 42.7221Z" fill="black" />
            <path className={className} d="M15.8314 26.0525H8.30113C7.74679 26.0525 7.2973 25.6606 7.2973 25.177V22.5537C7.2973 20.565 9.15605 18.9473 11.4406 18.9473H15.1603C16.1872 18.9473 17.165 19.2743 17.9144 19.8681C18.3247 20.1935 18.3551 20.747 17.9826 21.1049C17.6106 21.4632 16.9749 21.4893 16.5646 21.1644C16.1852 20.8638 15.6868 20.6984 15.1603 20.6984H11.4406C10.2633 20.6984 9.30495 21.5308 9.30495 22.5537V24.3014H15.8314C16.3857 24.3014 16.8352 24.6935 16.8352 25.177C16.8352 25.6606 16.3857 26.0525 15.8314 26.0525Z" fill="black" />
            <path className={className} d="M37.8621 25.8665H31.2934C30.8098 25.8665 30.4177 25.4848 30.4177 25.0139C30.4177 24.543 30.8098 24.1612 31.2934 24.1612H36.9865V22.4593C36.9865 21.4631 36.1506 20.6525 35.1236 20.6525H31.8787C31.419 20.6525 30.9842 20.8136 30.6541 21.1058C30.2962 21.4231 29.7416 21.3965 29.4171 21.0489C29.0918 20.7004 29.1178 20.1612 29.4757 19.8444C30.1291 19.2657 30.9825 18.9473 31.8787 18.9473H35.1236C37.1165 18.9473 38.7378 20.5226 38.7378 22.4593V25.0139C38.7378 25.4848 38.3457 25.8665 37.8621 25.8665Z" fill="black" />
            <path className={className} d="M23.1084 20.1316C20.9918 20.1316 19.4595 16.9285 19.4595 14.7694C19.4595 12.5025 21.0964 10.658 23.1084 10.658C25.12 10.658 26.7568 12.5025 26.7568 14.7694C26.7568 16.9285 25.2248 20.1316 23.1084 20.1316ZM23.1084 12.6276C22.0603 12.6276 21.2073 13.5884 21.2073 14.7694C21.2073 16.1091 22.2272 18.162 23.1084 18.162C23.9896 18.162 25.0089 16.1091 25.0089 14.7694C25.0089 13.5884 24.1564 12.6276 23.1084 12.6276Z" fill="black" />
            <path className={className} d="M33.2821 19.4082C31.5527 19.4082 30.4054 17.2533 30.4054 15.8277C30.4054 14.2832 31.6958 13.0264 33.2821 13.0264C34.8684 13.0264 36.1593 14.2832 36.1593 15.8277C36.1593 17.2533 35.0117 19.4082 33.2821 19.4082ZM33.2821 14.7316C32.6617 14.7316 32.1567 15.2233 32.1567 15.8277C32.1567 16.6371 32.8648 17.7029 33.2821 17.7029C33.6999 17.7029 34.408 16.6371 34.408 15.8277C34.408 15.2233 33.903 14.7316 33.2821 14.7316Z" fill="black" />
            <path className={className} d="M12.6068 19.4082C10.8774 19.4082 9.72974 17.2533 9.72974 15.8277C9.72974 14.2832 11.0205 13.0264 12.6068 13.0264C14.1931 13.0264 15.4835 14.2832 15.4835 15.8277C15.4835 17.2533 14.3364 19.4082 12.6068 19.4082ZM12.6068 14.7316C11.9861 14.7316 11.4811 15.2233 11.4811 15.8277C11.4811 16.6371 12.189 17.7029 12.6068 17.7029C13.0241 17.7029 13.7322 16.6371 13.7322 15.8277C13.7322 15.2233 13.2272 14.7316 12.6068 14.7316Z" fill="black" />
        </svg>
    )
}
export default AffiliationIcon;