import { ModalProps, makeStyles, Theme, Modal } from '@material-ui/core'
import React from 'react'

// Material Components

export interface BaseModalProps extends ModalProps {
    modalBodyClasses?: string
    modalClasses?: string
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(5),
        width: '30%',
    },
    modalContainer: {
        padding: theme.spacing(0, 2),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContainerDark: {
        padding: theme.spacing(0, 2),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(39, 39, 39, 0.95)'
    },
    modalBody: {
        backgroundColor: '#F4F5F7',
        width: '100%',
        padding: theme.spacing(6.25, 3.125),
        borderRadius: theme.typography.pxToRem(25),
        '&:focus-visible': {
            outline: 'none',
        },
    },
    [theme.breakpoints.up('sm')]: {
        modalBody: {
            maxWidth: '32.8125rem',
        },
    },
}))

const BaseModal: React.FC<BaseModalProps> = ({
    children,
    open,
    onClose,
    modalBodyClasses,
    modalClasses,
    ...props
}: BaseModalProps) => {
    const classes = useStyles()
    const storedTheme = sessionStorage.getItem("theme");
    return (
        <Modal
            open={open}
            onClose={onClose}
            className={`${storedTheme != 'dark' ? classes.modalContainer : classes.modalContainerDark} ${modalClasses}`}
            {...props}
        >
            <div className={`${classes.modalBody} ${modalBodyClasses}`}>
                {children}
            </div>
        </Modal>
    )
}

export default BaseModal
