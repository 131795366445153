import moment from "moment";
import { format } from "path";

export const getItemDisplay = (entry: any) => {  
    let resourceDate = entry.resource?.created ??
        entry.resource?.servicedPeriod?.start ??
        entry.resource?.billablePeriod?.start ??
        entry.resource?.servicedDate ??
        entry.resource?.onsetDateTime ??
        entry.resource?.performedDateTime ??
        entry.resource?.occurrenceDateTime ??
        entry.resource?.effectiveDateTime ??
        entry.resource?.period?.start ??
        entry.resource?.date ??
        entry.resource?.meta?.lastUpdated
    if (resourceDate) {
      const dateObject = new Date(resourceDate);
      if (isNaN(dateObject.getTime())) {
        return "_";
      }
      return moment(dateObject).format("D MMM  YYYY")
    }
    return "Date Not found..";
  };

  export const getYearValue = (entry: any) => {
    let resourceDate = entry.resource?.created ??
        entry.resource?.servicedPeriod?.start ??
        entry.resource?.billablePeriod?.start ??
        entry.resource?.servicedDate ??
        entry.resource?.onsetDateTime ??
        entry.resource?.performedDateTime ??
        entry.resource?.occurrenceDateTime ??
        entry.resource?.effectiveDateTime ??
        entry.resource?.period?.start ??
        entry.resource?.date ??
        entry.resource?.meta?.lastUpdated
    if (resourceDate) {
      const dateObject = new Date(resourceDate);
      if (isNaN(dateObject.getTime())) {
        return "_";
      }
      return dateObject.toLocaleString("en-US", {
        year:'numeric'
      });
    }
    return "Date Not found..";
  };

  export const StartTime = (entry: any) => {
    let resourceDate = entry.resource?.created ??
        entry.resource?.servicedPeriod?.start ??
        entry.resource?.billablePeriod?.start ??
        entry.resource?.servicedDate ??
        entry.resource?.onsetDateTime ??
        entry.resource?.performedDateTime ??
        entry.resource?.occurrenceDateTime ??
        entry.resource?.effectiveDateTime ??
        entry.resource?.period?.start ??
        entry.resource?.date ??
        entry.resource?.meta?.lastUpdated
    return new Date(resourceDate);
  };
  export const EndTime = (entry: any) => {
    let resourceDate = entry.resource?.created ??
        entry.resource?.servicedPeriod?.end ??
        entry.resource?.billablePeriod?.end ??
        entry.resource?.servicedDate ??
        entry.resource?.onsetDateTime ??
        entry.resource?.performedDateTime ??
        entry.resource?.occurrenceDateTime ??
        entry.resource?.effectiveDateTime ??
        entry.resource?.period?.end ??
        entry.resource?.date ??
        entry.resource?.meta?.lastUpdated
    return new Date(resourceDate);
  };
  