import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface Timeline {
    selectedTimeline?: Array<any>|null
}

const initialState: Timeline = {
    selectedTimeline:null
}

export const timelineSlice = createSlice({
    name: 'timeline',
    initialState: initialState,
    reducers: {
        updateselectedTimeline: (state, action: PayloadAction<Array<any>>) => {
            state.selectedTimeline = action.payload
        },
        resetTimeline: () => initialState
    },
})

export const timelineActions = timelineSlice.actions
export default timelineSlice.reducer
