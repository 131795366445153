import React, { useEffect, useState } from 'react'
import ToSNPPcomp from '../../components/ToSNPPcomp/ToSNPPcomp';
import { fetchTermsNConditions } from '../../functions/ToSNPP/TermsOfServicefuncs';
const TermsAndCondition = () => {
    const [Tnc, setTnc] = useState("");
    const [showTnc, setShowTnc] = useState(false);
    const [showSpinner, setShowSpinner] = useState(true);
    useEffect(() => {
        fetchTermsNConditions(setTnc, setShowTnc, setShowSpinner)
    }, [])
    return (
        <div>
            <ToSNPPcomp showSpinner={showSpinner} showTnc={showTnc} Tnc={Tnc} />
        </div>
    )
}

export default TermsAndCondition
