import Timeline from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import { EndTime, StartTime } from "../../functions/TimelineList/timelineList";
import RawDataView from "../../components/JsonData/RawDataView";
import { useEffect, useRef, useState } from "react";
import { timelineActions } from "../../redux/feature/TimelineSlice";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { getItemContent } from "../../functions/Timeline/timeline";
import AppLayout from "../../components/Layout/AppLayout";
import { Button, Container } from "react-bootstrap";
import "./timeline.scss";
import Switch from "@mui/material/Switch";
import InfoCard from "../../components/InfoCard/InfoCard";
import { GetBulkPatientBundles } from "../../apis/apis";
import { timelineBundleActions } from "../../redux/feature/timelineBundleSlice";
import { AxiosError } from "axios";
import Loading from "../../components/Loading/Loading";
import ErrorModal from "../../components/Modals/ErrorModal/ErrorModal";
import { appInsights } from "../../AppInsights/Appinsights";

function Timeline_Page(): JSX.Element {
  const dispatch = useAppDispatch();
  const timeline = useAppSelector((state) => state.timeline);
  const auth = useAppSelector((state) => state.auth);
  const dataBundle = useAppSelector((state) => state.timelineBundle);
  const [showRawData, setShowRawData] = useState(false);
  const [allBundlesView, setallBundlesView] = useState(true);
  const [checked, setChecked] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [showTimeline, setShowTimeline] = useState(false);
  const [isError, setIsError] = useState(false);
  const ref = useRef()
  const storedTheme = sessionStorage.getItem("theme");
  let tries = 0;
  const client_local = localStorage.getItem("client");
  const getPatinetData = async () => {
    appInsights.trackEvent({ name: `Fetch All the bundles for timeline view` });
    appInsights.trackEvent({ name: "Fetching... " });
    const FetchBundle_URL = `${process.env.REACT_APP_API_CLARITY}/api/v1/resourcebundle/All`;
    try {
      const res = await GetBulkPatientBundles(
        client_local,
        auth.authToken as string
      );
      const data = await res.data;
      dispatch(timelineBundleActions.updateselectedTimeline(data));
      responsestatus(res.status);
      appInsights.trackEvent({
        name: `Fetch timeline bundle URL: ${FetchBundle_URL}`,
      });
    } catch (err) {
      responsestatus(500);
      console.log("fetch failed");
      const error = err as AxiosError;
      console.log(error);
      appInsights.trackException({
        exception: Error(
          `Failed to fetch timeline bundle URL: ${FetchBundle_URL} and error is : ${error}`
        ),
      });
    }
  };
  const responsestatus = (res: number) => {
    if (res === 200) {
      setIsLoading(false);
      setShowTimeline(true);
    } else {
      setIsLoading(false);
      setShowTimeline(false);
      setIsError(true);
    }
  };

  const resources = dataBundle.selectedTimelineBundles?.entries
    ?.map((item: { resource: { resourceType: any; }; }, idx: any) => item.resource.resourceType)
    .sort();
  const groups = Array.from(new Set(resources)).map((item, idx) => {
    return { id: idx, title: item as string };
  });
  const items = dataBundle.selectedTimelineBundles?.entries.map((item: { resource: { resourceType: any; }; }, idx: any) => {
    return {
      id: idx,
      group: groups.findIndex(
        (group) => group.title === item.resource.resourceType
      ),
      title: getItemContent(item.resource),
      start_time: StartTime(item),
      end_time: EndTime(item),
      itemProps: {
        onDoubleClick: () => {
          onTimelineClick(item);
        },
      },
    };
  });

  const groupBy = require("lodash.groupby");
  const entries = dataBundle.selectedTimelineBundles?.entries.flatMap((entry: any) => entry);
  const groupedEntries = groupBy(
    entries,
    (entry: any) => entry.resource.resourceType
  );
  const resourceList = Object.entries(groupedEntries).sort();

  const onTimelineClick = (item: any) => {
    dispatch(timelineActions.updateselectedTimeline(item));
    setShowRawData(true);
  };



  let scrollRef: any;
  const animateScroll = (invert: boolean) => {
    scrollRef = scrollRef ?? ref.current
    const width = (invert ? -1 : 1) * parseFloat(scrollRef.style.width); // cos curve in both directions
    const duration = 2000;

    const startTime = performance.now();
    let lastWidth = 0;
    const animate = () => {
      let normalizedTime = (performance.now() - startTime) / duration;
      if (normalizedTime > 1) {
        // not overanimate
        normalizedTime = 1;
      }

      // http://www.wolframalpha.com/input/?i=plot+0.5+(1%2Bcos(%CF%80+(x-1)))*1000+from+0+to+1 --> 1000 is the simulated width
      const calculatedWidth = Math.floor(
        width * 0.5 * (1 + Math.cos(Math.PI * (normalizedTime - 1)))
      );
      scrollRef.scrollLeft += calculatedWidth - lastWidth;
      lastWidth = calculatedWidth;

      if (normalizedTime < 1) {
        requestAnimationFrame(animate);
      }
    };
    requestAnimationFrame(animate);
  };

  const onPrevClick = () => {
    animateScroll(true);
  };

  const onNextClick = () => {
    animateScroll(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (event.target.checked === true) {
      setallBundlesView(true);
    } else {
      setallBundlesView(false);
    }
  };


  useEffect(() => {
    getPatinetData();
  }, []);
  const handleErrorModalClose = (
    event: Record<string, unknown>,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return
    }
    setIsError(false)
  }
  if (isLoading && dataBundle.selectedTimelineBundles === null) {
    return <Loading />;
  }

  return (
    <AppLayout subHeading="Timeline" >
      <div id={storedTheme !== 'dark' ? 'timelinePage' : 'timelinePage-dark'}>
        {showTimeline ? (<>
          <div className="header_container">
            <InfoCard>Double Click on the timeline to see the modal</InfoCard>
            <div className="options_container">
              <div className="btn_div">
                <Button onClick={() => onPrevClick()}>{"< Prev"}</Button>
                <Button onClick={() => onNextClick()} style={{ 'marginLeft': '0.5rem' }}>{"Next >"}</Button>
              </div>
              <div className="switch_div">
                <p className="switch_opt" style={{ color: storedTheme !== 'dark' ? 'black' : 'white' }}>Individual</p>
                <Switch
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <p className="switch_opt" style={{ color: storedTheme !== 'dark' ? 'black' : 'white' }}>All</p>
              </div>
            </div>
          </div>
          <Container className="timeline_container">
            {allBundlesView ? (
              <Timeline
                groups={groups}
                items={items}
                defaultTimeStart={moment().add(-12, "hour")}
                defaultTimeEnd={moment().add(12, "hour")}
                canResize={false}
                canMove={false}
                scrollRef={(el) => {
                  scrollRef = el
                  ref.current = el
                }}

              />
            ) : (
              <>
                {resourceList?.map((item, idx) => {
                  const groups = [{ id: idx, title: '' }];
                  const items = (item[1] as any[]).map((itm, index) => {
                    return {
                      id: index,
                      group: idx,
                      title: getItemContent(itm.resource),
                      start_time: StartTime(itm),
                      end_time: EndTime(itm),
                      itemProps: {
                        onDoubleClick: () => {
                          onTimelineClick(itm);
                        },
                      },
                    };
                  });
                  return (
                    <details className="details_div">
                      <summary className="summary_div" style={{ color: storedTheme !== 'dark' ? 'black' : 'white' }}>{item[0]}</summary>
                      <Timeline
                        groups={groups}
                        items={items}
                        defaultTimeStart={moment().add(-12, "hour")}
                        defaultTimeEnd={moment().add(12, "hour")}
                        canResize={false}
                        canMove={false}
                        scrollRef={(el) => (scrollRef = el)}
                      />
                    </details>
                  );
                })}
              </>
            )}
          </Container>
          <RawDataView
            show={showRawData}
            //@ts-ignore
            name={timeline.selectedTimeline?.resource.resourceType}
            //@ts-ignore
            content={timeline.selectedTimeline}
            handleClose={() => {
              setShowRawData(false);
            }}
          />
        </>) : null}
        <ErrorModal open={isError} onClose={handleErrorModalClose}>
          An error occurred while trying to retrieve your healthcare
          data. Please try again after some time or contact our{' '}
          <a>support</a>.
        </ErrorModal>
      </div>
    </AppLayout>
  );
}

export default Timeline_Page;
