import React from 'react'
import { Container, Row, Col, Nav, InputGroup, Button, Image, Form } from 'react-bootstrap';
import { searchItems } from '../../functions/SearchFuncs/SearchFuncs';
import MenuBar from '../MenuBar/MenuBar';
import Onyx from '../../images/Onyx.svg'
import { useAppSelector } from '../../redux/app/hooks';
import { useNavigate } from 'react-router-dom';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import '../../pages/LandingPage/LandingPage.scss'
import { GoToHome } from '../../functions/LogOut/Logout';
import MenuBarMobile from '../MenuBar/MenuBar_mobile';
import HealthDataClarityLogo from '../Logo/HealthDataClarityLogo';

//@ts-ignore
const LandingNav = ({ setSearchInput, setLandingPageVar, LandingPageVar, handleShow, setSearchVar, searchVar }) => {
    let history = useNavigate();
    const storedTheme = sessionStorage.getItem("theme");
    const UI = useAppSelector((state) => state.UI);

    return (
        <div>
            <div className={storedTheme !== 'dark' ? 'nav_container' : 'nav_container nav_container_dark'}>
                <div className={'navbar_wrapper'}>
                    <Container fluid>
                        <Row>
                            <Col className='nav_left_content'>
                                <HealthDataClarityLogo />
                                <div className='onyx_logo_landing2'>
                                    <p className='power_by' data-testid='powered_by'>Powered By :</p>
                                    <Image src={Onyx} alt='Onyx_Logo' className='onyx_logo_landing' data-testid='onyx_logo' />
                                </div>
                            </Col>
                            <Col xs={6}>
                                <Nav className="justify-content-end navigation_bar" activeKey="/home">
                                    <Nav.Item className={storedTheme !== 'dark' ? 'nav_item' : 'nav_item nav_item_dark'}>
                                        <Nav.Link onClick={() => GoToHome(history)} className='nav_txt'>Home</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className={storedTheme !== 'dark' ? 'nav_item' : 'nav_item nav_item_dark'}>
                                        <Nav.Link href='https://www.onyxhealth.io/' target={"_blank"} className='nav_txt'>About</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className={storedTheme !== 'dark' ? 'nav_item' : 'nav_item nav_item_dark'}>
                                        <MenuBar obtlist={UI.payers} name={'Payer Pages'} />
                                    </Nav.Item>
                                    <Nav.Item className={storedTheme !== 'dark' ? 'nav_item' : 'nav_item nav_item_dark'}>
                                        <Nav.Link href={`${process.env.REACT_APP_DEVELOPER_PORTAL_URL}`} target={"_blank"} eventKey="link-2" className='nav_txt'>Developer Portal</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className={storedTheme !== 'dark' ? 'nav_item' : 'nav_item nav_item_dark'}>
                                        <Nav.Link onClick={() => history('/#contact')} target={"_blank"} className='nav_txt'>Contact Us</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <div className='navbar_991'>
                                    <MenuBarMobile obtlist={UI.payers} name={'Menu'} />
                                </div>
                            </Col>
                        </Row>


                        <Row className='search_move'>
                            <Col>
                                <InputGroup className="mb-3 search_bar_landing">

                                    <Form.Control
                                        placeholder="Search here By Health Plan Name, Payer name or HNo.."
                                        aria-label="payer"
                                        aria-describedby="basic-addon1"
                                        onChange={e => {
                                            searchItems(e.target.value, setSearchInput, UI.plans_list, setSearchVar, searchVar);
                                        }}
                                        className={storedTheme !== 'dark' ? "search_input_box" : "search_input_box search_input_box_dark"}
                                        type='search'
                                        data-testid="search_bar"
                                    />

                                </InputGroup>
                            </Col>
                            <Col xs={3}>
                                <Button onClick={handleShow} className={storedTheme !== 'dark' ? 'watch_tut_btn' : 'watch_tut_btn dark_btn_1'}><OndemandVideoIcon sx={{ fontSize: 10 }} />Watch Tutorial</Button>
                            </Col>
                            {/* <ThemeToggle /> */}
                        </Row>


                    </Container>
                </div>
                {/* <h1 className='header_txt'>Search here By Health Plan Name, Payer name or HNo</h1> */}
            </div>
        </div>
    )
}

export default LandingNav