function ListIcon({ className }: { className?: string }) {

    return (
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_232_76)">
                <path className={className} d="M44.0217 0.642822H8.80435C8.26411 0.642822 7.82609 1.09287 7.82609 1.64904V32.8416H0.978261C0.43802 32.8416 0 33.2916 0 33.8478V39.885C0 42.6591 2.1944 44.9161 4.8913 44.9161H40.1088C42.8056 44.9161 45 42.6591 45 39.885V34.854V33.8478V1.64904C45 1.09287 44.562 0.642822 44.0217 0.642822ZM1.95652 39.885V34.854H35.2174V39.885C35.2174 41.022 35.5999 42.0602 36.2206 42.9037H4.8913C3.27297 42.9037 1.95652 41.5496 1.95652 39.885ZM43.0435 39.885C43.0435 41.5496 41.727 42.9037 40.1088 42.9037C38.4904 42.9037 37.1739 41.5496 37.1739 39.885V33.8478C37.1739 33.2916 36.7359 32.8416 36.1956 32.8416H9.78261V2.65525H43.0435V33.8478V34.854V39.885Z" fill="black" />
                <path className={className} d="M38.1522 6.68018H14.674C14.1337 6.68018 13.6956 7.13021 13.6956 7.68639C13.6956 8.24255 14.1337 8.6926 14.674 8.6926H38.1522C38.6925 8.6926 39.1305 8.24255 39.1305 7.68639C39.1305 7.13021 38.6925 6.68018 38.1522 6.68018Z" fill="black" />
                <path className={className} d="M38.1522 11.7112H14.674C14.1337 11.7112 13.6956 12.1612 13.6956 12.7174C13.6956 13.2736 14.1337 13.7236 14.674 13.7236H38.1522C38.6925 13.7236 39.1305 13.2736 39.1305 12.7174C39.1305 12.1612 38.6925 11.7112 38.1522 11.7112Z" fill="black" />
                <path className={className} d="M38.1522 16.7422H14.674C14.1337 16.7422 13.6956 17.1923 13.6956 17.7484C13.6956 18.3046 14.1337 18.7546 14.674 18.7546H38.1522C38.6925 18.7546 39.1305 18.3046 39.1305 17.7484C39.1305 17.1923 38.6925 16.7422 38.1522 16.7422Z" fill="black" />
                <path className={className} d="M38.1522 21.7732H14.674C14.1337 21.7732 13.6956 22.2232 13.6956 22.7794C13.6956 23.3355 14.1337 23.7856 14.674 23.7856H38.1522C38.6925 23.7856 39.1305 23.3355 39.1305 22.7794C39.1305 22.2232 38.6925 21.7732 38.1522 21.7732Z" fill="black" />
                <path className={className} d="M38.1522 26.8042H14.674C14.1337 26.8042 13.6956 27.2543 13.6956 27.8104C13.6956 28.3666 14.1337 28.8167 14.674 28.8167H38.1522C38.6925 28.8167 39.1305 28.3666 39.1305 27.8104C39.1305 27.2543 38.6925 26.8042 38.1522 26.8042Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_232_76">
                    <rect width="45" height="45" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default ListIcon;
