function LocationIcon({ className }: { className?: string }) {

    return (
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={className} d="M35.9051 34.0487L38.12 39.0304C38.2571 39.3433 38.6227 39.4839 38.9321 39.3433C39.2414 39.2061 39.3821 38.844 39.245 38.5311L37.0301 33.5495C36.893 33.2401 36.5274 33.0995 36.218 33.2366C35.9051 33.3737 35.768 33.7394 35.9051 34.0487Z" fill="black" />
            <path className={className} d="M43.2774 42.416L37.0582 28.4203C36.9598 28.1988 36.7383 28.0547 36.4957 28.0547H29.4715C31.3664 25.0066 33.0258 21.7969 34.0242 18.8613C34.5867 17.3426 34.8996 15.7465 34.9559 14.1258C34.9805 9.66094 32.611 5.52305 28.7473 3.28359C24.8836 1.04414 20.1164 1.04414 16.2528 3.28359C12.3891 5.52305 10.0196 9.66094 10.0406 14.1258C10.1004 15.7465 10.4133 17.3426 10.9758 18.8613C11.9742 21.7969 13.6336 25.0066 15.5285 28.0547H8.50432C8.26174 28.0547 8.04026 28.1988 7.94182 28.4203L1.72268 42.416C1.6383 42.6059 1.65588 42.8273 1.76838 42.9996C1.88088 43.1754 2.07776 43.2809 2.28518 43.2809H42.7149C42.9223 43.2809 43.1192 43.1754 43.2317 42.9996C43.3442 42.8273 43.3617 42.6059 43.2774 42.416ZM11.2711 14.1293C11.25 10.1004 13.384 6.37031 16.868 4.35234C20.352 2.33438 24.6481 2.33438 28.1321 4.35234C31.616 6.37031 33.75 10.1004 33.7289 14.1293C33.6692 15.6094 33.3774 17.0754 32.8571 18.4676C31.7883 21.6105 29.9356 25.084 27.8508 28.3184C27.8473 28.3254 27.8438 28.3324 27.8403 28.3395C26.2196 30.8602 24.4371 33.2684 22.5 35.557C20.5629 33.2684 18.7805 30.8602 17.1598 28.3395C17.1563 28.3324 17.1528 28.3254 17.1457 28.3184C15.061 25.084 13.2082 21.6105 12.143 18.4676C11.6227 17.0754 11.3309 15.6094 11.2711 14.1293ZM3.23088 42.0504L8.9051 29.2852H16.3125C18.0422 31.9535 19.9547 34.4988 22.0395 36.9C22.1555 37.0336 22.3242 37.1109 22.5 37.1109C22.6793 37.1109 22.8481 37.0336 22.9641 36.9C25.0453 34.4988 26.9578 31.9535 28.6875 29.2852H36.0949L41.7656 42.0504H3.23088Z" fill="black" />
            <path className={className} d="M22.5 20.6649C26.1106 20.6614 29.0356 17.7364 29.0356 14.1259C29.0356 10.5153 26.1106 7.59033 22.5 7.59033C18.8895 7.59033 15.9645 10.5188 15.9645 14.1259C15.968 17.7364 18.893 20.6579 22.5 20.6649ZM22.5 8.8208C25.4285 8.8208 27.8051 11.1974 27.8051 14.1259C27.8051 17.0579 25.4321 19.431 22.5 19.431C19.5715 19.4345 17.1949 17.0579 17.1949 14.1259C17.1985 11.1974 19.5715 8.82432 22.5 8.8208Z" fill="black" />
        </svg>
    )
}
export default LocationIcon;
