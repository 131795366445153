import { formatDatePeriod } from "../util/index";
import TimelineDetailsCardDropdown from "../TimelineDetailsCardDropdown/TimelineDetailsCardDropdown";
import TimelineDetailsCardItem from "../TimelineDetailsCardItem/TimelineDetailsCardItem";
import './TimelineCard.css'
import TimelineDetailsStatus from "../TimelineDetailsCardItem/TimelineStatus";

const PractitionerRoleTimelineCard = ({ resource }: any) => {
  const storedTheme = sessionStorage.getItem("theme");
  return (
    <div id="practitionerRole-timeline-card">
      <div className="status-div">
        {resource.clinicalStatus &&
          resource.clinicalStatus.coding &&
          resource.clinicalStatus.coding.length &&
          (resource.clinicalStatus.coding[0].display ||
            resource.clinicalStatus.coding[0].code) && (
            <TimelineDetailsStatus
              label="Clinical Status :"
              value={
                resource.clinicalStatus.coding[0].display ||
                resource.clinicalStatus.coding[0].code
              }
            />
          )}

        {resource.status && resource.status.length > 0 && (
          <TimelineDetailsStatus label="Status :" value={`${resource.status}`} />
        )}
      </div>
      <div className={storedTheme != 'dark' ? 'display-details' : 'display-details-dark'}>
        {resource.specialty && (
          <TimelineDetailsCardItem
            label="SPECIALTY"
            value={resource.specialty[0].coding[0].display}
          />
        )}
        {resource.telecom && resource.telecom[0]?.system == 'phone' && (
          < TimelineDetailsCardItem label="Phone" value={resource.telecom[0]?.value} />
        )}
        {resource.address && (
          <TimelineDetailsCardItem
            label="Address"
            value={`${resource.address[0].line + "," + resource.address[0].city + "," + resource.address[0].state + "," + resource.address[0].postalCode}` || `${resource.address[0].text}`}
          />
        )}
      </div>
    </div>
  );
};

export default PractitionerRoleTimelineCard;
