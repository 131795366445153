import React from 'react'
import './TOSNPPcomp.scss'
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap'
import ReactMarkdown from 'react-markdown'
import DeskFooterLogin from '../Footer/DeskFooter/DeskFooterLogin'
import MobFooter from '../Footer/MobFooter/MobFooter'
import LoginSideBar from '../SideBar/LoginSideBar/LoginSideBar'
import { GotoPreviousPage } from '../../functions/ToSNPP/PrivacyPolicyFuncs'
import { useNavigate } from 'react-router'

//@ts-ignore
const ToSNPPcomp = ({ showSpinner, showTnc, Tnc }) => {
    var back = "<--";
    const history = useNavigate()
    const storedTheme = sessionStorage.getItem("theme");
    return (
        <div><Container fluid className={storedTheme != 'dark' ? 'page-wrapper-login' : 'page-wrapper-login-dark'}>
            <Row className="content-wrapper-login">
                <Col lg={4} className="left-bar-login">
                    <LoginSideBar />
                </Col>
                <Col lg={8} xs={12} sm={12} className="right-bar-login">
                    <div>
                        <div>
                            <Button className='back_button' data-testid="back_button" onClick={() => history(-1)}>{back} Back</Button>
                        </div>
                        <br />
                        {showSpinner ?
                            <div className='terms_right_items'>
                                <Spinner animation={'border'} variant='primary' />

                                <p>Loading...</p>
                            </div>
                            : null}
                        {showTnc ?
                            <div className={storedTheme != 'dark' ? 'scroll_bar' : 'scroll_bar-dark'} data-testid="tnc_container"><ReactMarkdown>{Tnc}</ReactMarkdown></div>
                            : null}
                    </div>
                </Col>
            </Row>
            <MobFooter />
        </Container>
        </div>
    )
}

export default ToSNPPcomp