import React, { useEffect, useState } from "react";
import AppLayout from "../../components/Layout/AppLayout";
import { useAppSelector, useAppDispatch } from "../../redux/app/hooks";
import "./Profile.css";
import PatientDetailsLoadingModal from "../../components/Modals/LoadingModal/PatientDetailsLoadingModal/PatientDetailsLoadingModal";
import { Button } from "react-bootstrap";
import ProfileView from "../../components/JsonData/ProfileView ";
import ErrorModal from "../../components/Modals/ErrorModal/ErrorModal";
import { Fetch_patient_details, fetchData } from "../../functions/ProfilePage/ProfilePage_funcs"
import { ProfileActions } from "../../redux/feature/ProfileSlice";

function ProfilePage(): JSX.Element {
  const storedTheme = sessionStorage.getItem("theme");
  const patient = useAppSelector((state) => state.patient)
  const dispatch = useAppDispatch()
  const client_local = localStorage.getItem("client")
  const auth = useAppSelector((state) => state.auth)
  const bundleList = useAppSelector((state) => state.BundleList);
  const profile = useAppSelector((state) => state.profile)
  const [showRawData, setShowRawData] = useState(false);


  useEffect(() => {
    if (patient.id === '' || patient.firstName === ' ' || patient.lastName === '' || patient.dateOfBirth === '')
      Fetch_patient_details(dispatch, client_local, auth);
  }, []);

  // useEffect(() => {
  //   if (bundleList.selectedBundleList === null)
  //     fetchData(dispatch, client_local, auth);
  // }, []);



  const handleErrorModalClose = (
    event: Record<string, unknown>,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return
    }
    dispatch(ProfileActions.setprofileError(false))
  }

  if (profile.profileLoading) {
    return (
      <div>
        <PatientDetailsLoadingModal
          open={profile.profileLoading}
          onClose={() =>

            dispatch(ProfileActions.setprofileLoading(false))
          }
        />
      </div>
    );
  }

  const ProfileCard = () => {
    return (
      <div className="profile-card" id='profile-page'>
        {
          <ProfileView
            show={showRawData}
            //@ts-ignore
            content={profile.resourceData}
            handleClose={() => {
              setShowRawData(false);
            }}
          />
        }
        {profile.showBundle ? (
          <>
            <div className="details">
              <p>
                <span className="label">First name:</span>{" "}
                <span className="label-item">{patient.firstName}</span>
              </p>
              <p>
                <span className="label">Last name:</span>{" "}
                <span className="label-item">{patient.lastName}</span>
              </p>
              <p>
                <span className="label">Email:</span>{" "}
                <span className="label-item">{patient.email}</span>
              </p>
              <p>
                <span className="label">Gender:</span>{" "}
                <span className="label-item">{patient.gender}</span>
              </p>
              <p>
                <span className="label">Address:</span>{" "}
                <span className="label-item">
                  {patient.address}
                </span>
              </p>
              <p>
                <span className="label">Phone Number:</span>{" "}
                <span className="label-item">
                  {patient.phoneNumber}
                </span>
              </p>
            </div>
            <div className="name-container">
              {/* <div className="alias-container">{getNameAlias(name)}</div> */}
              <Button
                className="view-button"
                onClick={() => {
                  setShowRawData(true);
                }}
              >View Raw Data</Button>
            </div>
          </>) : null}
      </div>

    );
  };

  return (
    <AppLayout subHeading="Profile">
      <div id={storedTheme != 'dark' ? 'profile-page' : 'profile-page-dark'}>
        <ProfileCard />
        <ErrorModal open={!!profile?.profileError} onClose={handleErrorModalClose}>
          An error occurred while trying to retrieve your healthcare
          data. Please try again after some time or contact our{' '}
          <a>support</a>.
        </ErrorModal>
      </div>
    </AppLayout>
  );
};

export default ProfilePage;
