import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function LogosCarosel() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  return (
    <Carousel
      swipeable={false}
      draggable={true}
      showDots={false}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={true}
      autoPlaySpeed={1500}
      keyBoardControl={true}
      transitionDuration={5}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
      <div><img src="https://p2pgetmydatastorage.blob.core.windows.net/logos/plan-logos/CVS_Aetna/AETNA_HEALTH_INC._FL_crop.png" className="caros_img" /></div>
      <div><img src="https://p2pgetmydatastorage.blob.core.windows.net/logos/plan-logos/Humana/CHA_HMO_INC..png" className="caros_img" /></div>
      <div><img src="https://p2pgetmydatastorage.blob.core.windows.net/logos/plan-logos/Humana/EMPHESYS_INSURANCE_COMPANY.png" className="caros_img" /></div>
      <div><img src="https://p2pgetmydatastorage.blob.core.windows.net/logos/plan-logos/UHC_Optum/CARE_IMPROVEMENT_PLUS_SOUTH_CENTRAL_INSURANCE_CO._nfqhy9X.png" className="caros_img" /></div>
      <div><img src="https://p2pgetmydatastorage.blob.core.windows.net/logos/plan-logos/Anthem/FREEDOM_HEALTH_INC..png" className="caros_img" /></div>
      <div><img src="https://p2pgetmydatastorage.blob.core.windows.net/logos/plan-logos/Anthem/MATTHEW_THORNTON_HEALTH_PLAN_INC..png" className="caros_img" /></div>
      <div><img src="https://p2pgetmydatastorage.blob.core.windows.net/logos/plan-logos/Anthem/HEALTHKEEPERS_INC..png" className="caros_img" /></div>
      <div><img src="https://p2pgetmydatastorage.blob.core.windows.net/logos/plan-logos/Anthem/MMM_HEALTHCARE_LLC_AvJJee0.png" className="caros_img" /></div>
    </Carousel>
  );
}

export default LogosCarosel;