function CarePlanIcon({ className }: { className?: string }) {

    return (
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={className} d="M32.2978 13.814H19.9454C19.4717 13.814 19.0876 14.1878 19.0876 14.6492C19.0876 15.1104 19.4717 15.4843 19.9454 15.4843H32.2978C32.7715 15.4843 33.1556 15.1104 33.1556 14.6492C33.1556 14.1878 32.7715 13.814 32.2978 13.814Z" fill="black" />
            <path className={className} d="M29.2965 18.0678C29.1369 17.9124 28.9157 17.823 28.6901 17.823C28.4645 17.823 28.2431 17.9124 28.0835 18.0678C27.924 18.2231 27.8323 18.4386 27.8323 18.6582C27.8323 18.8779 27.924 19.0934 28.0835 19.2488C28.2431 19.4042 28.4645 19.4934 28.6901 19.4934C28.9157 19.4934 29.1369 19.4042 29.2965 19.2488C29.456 19.0934 29.5479 18.8779 29.5479 18.6582C29.5479 18.4386 29.456 18.2231 29.2965 18.0678Z" fill="black" />
            <path className={className} d="M20.551 4.95492C20.3915 4.79957 20.1702 4.71021 19.9446 4.71021C19.719 4.71021 19.4976 4.79957 19.3382 4.95492C19.1786 5.11027 19.0868 5.32576 19.0868 5.54542C19.0868 5.76508 19.1786 5.98057 19.3382 6.13591C19.4976 6.29127 19.719 6.38064 19.9446 6.38064C20.1702 6.38064 20.3915 6.29127 20.551 6.13591C20.7106 5.98057 20.8024 5.76508 20.8024 5.54542C20.8024 5.32576 20.7106 5.11027 20.551 4.95492Z" fill="black" />
            <path className={className} d="M25.0816 17.823H19.9454C19.4717 17.823 19.0876 18.197 19.0876 18.6582C19.0876 19.1196 19.4717 19.4934 19.9454 19.4934H25.0816C25.5554 19.4934 25.9394 19.1196 25.9394 18.6582C25.9394 18.197 25.5554 17.823 25.0816 17.823Z" fill="black" />
            <path className={className} d="M32.2978 22.8342H19.9454C19.4717 22.8342 19.0876 23.2082 19.0876 23.6695C19.0876 24.1308 19.4717 24.5047 19.9454 24.5047H32.2978C32.7715 24.5047 33.1556 24.1308 33.1556 23.6695C33.1556 23.2082 32.7715 22.8342 32.2978 22.8342Z" fill="black" />
            <path className={className} d="M29.2965 27.088C29.1369 26.9327 28.9157 26.8433 28.6901 26.8433C28.4645 26.8433 28.2431 26.9327 28.0835 27.088C27.924 27.2434 27.8323 27.4588 27.8323 27.6785C27.8323 27.8982 27.924 28.1137 28.0835 28.2691C28.2431 28.4243 28.4645 28.5137 28.6901 28.5137C28.9157 28.5137 29.1369 28.4243 29.2965 28.2691C29.456 28.1137 29.5479 27.8982 29.5479 27.6785C29.5479 27.4588 29.456 27.2434 29.2965 27.088Z" fill="black" />
            <path className={className} d="M25.0816 26.8433H19.9454C19.4717 26.8433 19.0876 27.2172 19.0876 27.6785C19.0876 28.1399 19.4717 28.5137 19.9454 28.5137H25.0816C25.5554 28.5137 25.9394 28.1399 25.9394 27.6785C25.9394 27.2172 25.5554 26.8433 25.0816 26.8433Z" fill="black" />
            <path className={className} d="M32.2978 31.8547H19.9454C19.4717 31.8547 19.0876 32.2287 19.0876 32.69C19.0876 33.1513 19.4717 33.5252 19.9454 33.5252H32.2978C32.7715 33.5252 33.1556 33.1513 33.1556 32.69C33.1556 32.2287 32.7715 31.8547 32.2978 31.8547Z" fill="black" />
            <path className={className} d="M29.2965 36.1085C29.1369 35.9532 28.9157 35.8638 28.6901 35.8638C28.4645 35.8638 28.2431 35.9532 28.0835 36.1085C27.924 36.2638 27.8323 36.4793 27.8323 36.699C27.8323 36.9187 27.924 37.1342 28.0835 37.2894C28.2431 37.4448 28.4645 37.5342 28.6901 37.5342C28.9157 37.5342 29.1369 37.4448 29.2965 37.2894C29.456 37.1342 29.5479 36.9187 29.5479 36.699C29.5479 36.4793 29.456 36.2638 29.2965 36.1085Z" fill="black" />
            <path className={className} d="M25.0816 35.8638H19.9454C19.4717 35.8638 19.0876 36.2377 19.0876 36.699C19.0876 37.1602 19.4717 37.5342 19.9454 37.5342H25.0816C25.5554 37.5342 25.9394 37.1602 25.9394 36.699C25.9394 36.2377 25.5554 35.8638 25.0816 35.8638Z" fill="black" />
            <path className={className} d="M33.9484 4.37608H27.3966C26.4496 3.49735 25.1694 2.95613 23.7609 2.95613H23.0095C22.4351 1.8488 21.2549 1.11865 19.9456 1.11865C18.6363 1.11865 17.4561 1.8488 16.8816 2.95613H16.1284C14.72 2.95613 13.4398 3.49735 12.4927 4.37608H5.94291C3.63674 4.37608 1.7605 6.20295 1.7605 8.44843V39.8094C1.7605 42.0549 3.63674 43.8818 5.94291 43.8818H33.9483C36.2544 43.8818 38.1307 42.0549 38.1307 39.8094V8.44843C38.1308 6.20295 36.2546 4.37608 33.9484 4.37608ZM16.1285 4.62656H17.4655C17.8513 4.62656 18.1896 4.37574 18.2923 4.01368C18.4968 3.29264 19.1767 2.78909 19.9456 2.78909C20.7146 2.78909 21.3945 3.29264 21.5989 4.01368C21.7016 4.37574 22.04 4.62656 22.4258 4.62656H23.7609C25.6228 4.62656 27.1553 6.02555 27.3082 7.80047H12.5812C12.7342 6.02555 14.2667 4.62656 16.1285 4.62656ZM36.4152 39.8096C36.4152 41.134 35.3086 42.2115 33.9484 42.2115H5.94291C4.58272 42.2115 3.47608 41.134 3.47608 39.8096V8.44843C3.47608 7.12402 4.58272 6.04651 5.94291 6.04651H11.291C11.01 6.67393 10.8531 7.36565 10.8531 8.09279V8.63568C10.8531 9.09697 11.2371 9.4709 11.7109 9.4709H28.1786C28.6524 9.4709 29.0364 9.09697 29.0364 8.63568V8.09279C29.0364 7.36574 28.8796 6.67393 28.5985 6.04651H33.9484C35.3086 6.04651 36.4152 7.12402 36.4152 8.44843V39.8096Z" fill="black" />
            <path className={className} d="M14.4911 13.6133C14.156 13.2871 13.6129 13.2871 13.2779 13.6133L9.60986 17.1848L8.4799 16.0846C8.14493 15.7584 7.60178 15.7584 7.26672 16.0846C6.93176 16.4107 6.93176 16.9396 7.26672 17.2658L9.00332 18.9566C9.17084 19.1198 9.39036 19.2013 9.60986 19.2013C9.82937 19.2013 10.049 19.1198 10.2164 18.9566L14.4911 14.7945C14.826 14.4684 14.826 13.9395 14.4911 13.6133Z" fill="black" />
            <path className={className} d="M12.3971 31.8547H8.27978C7.80603 31.8547 7.422 32.2287 7.422 32.69V36.699C7.422 37.1604 7.80603 37.5342 8.27978 37.5342H12.3971C12.871 37.5342 13.2549 37.1604 13.2549 36.699V32.69C13.2549 32.2287 12.871 31.8547 12.3971 31.8547ZM11.5394 35.8638H9.13758V33.5252H11.5394V35.8638Z" fill="black" />
            <path className={className} d="M12.3971 22.8342H8.27978C7.80603 22.8342 7.422 23.2082 7.422 23.6695V27.6786C7.422 28.1398 7.80603 28.5137 8.27978 28.5137H12.3971C12.871 28.5137 13.2549 28.1398 13.2549 27.6786V23.6695C13.2549 23.2082 12.871 22.8342 12.3971 22.8342ZM11.5394 26.8434H9.13758V24.5047H11.5394V26.8434Z" fill="black" />
        </svg>
    )
}
export default CarePlanIcon;