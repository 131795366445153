import { formatDatePeriod } from "../util/index";
import TimelineDetailsCardDropdown from "../../components/TimelineDetailsCardDropdown/TimelineDetailsCardDropdown";
import TimelineDetailsCardItem from "../../components/TimelineDetailsCardItem/TimelineDetailsCardItem";
import './TimelineCard.css'
import TimelineDetailsStatus from "../TimelineDetailsCardItem/TimelineStatus";

const DeviceTimelineCardItem = ({ resource }: any) => {
    const storedTheme = sessionStorage.getItem("theme");
    return (
        <div id="DeviceTimelineCardItem">
            <div className="status-div">
                {resource.clinicalStatus &&
                    resource.clinicalStatus.coding &&
                    resource.clinicalStatus.coding.length &&
                    (resource.clinicalStatus.coding[0].display ||
                        resource.clinicalStatus.coding[0].code) && (
                        <TimelineDetailsStatus
                            label="Clinical Status :"
                            value={
                                resource.clinicalStatus.coding[0].display ||
                                resource.clinicalStatus.coding[0].code
                            }
                        />
                    )}

                {resource.status && resource.status.length > 0 && (
                    <TimelineDetailsStatus label="Status :" value={`${resource.status}`} />
                )}
            </div>
            <div className={storedTheme != 'dark' ? 'display-details' : 'display-details-dark'}>
                {resource.type?.coding &&
                    resource.type?.coding.length &&
                    resource.type?.coding[0].display && (
                        <TimelineDetailsCardItem
                            label="CODE"
                            value={`${resource.type.coding[0].display}`}
                        />
                    )}
                {resource.manufactureDate && (
                    <TimelineDetailsCardItem
                        label="Manufacture Date"
                        value={formatDatePeriod(resource.manufactureDate)}
                    />
                )}
                {resource.expirationDate && (
                    <TimelineDetailsCardItem
                        label="Expiration Date"
                        value={formatDatePeriod(resource.expirationDate)}
                    />
                )}
            </div>
        </div>
    );
};

export default DeviceTimelineCardItem;
