import React from 'react'

// Material Components
import { makeStyles, Theme } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        position: 'relative',
        '& svg': {
            color: '#1BA098',
            width: '4rem',
            height: '4rem',
        },
    },
    spinner: {
        top: 0,
        left: 0,
        position: 'absolute',
    },
    noIconSpinner: {
        position: 'static',
    },
}))

interface IconSpinnerProps {
    icon?: React.ReactNode
}

const IconSpinner: React.FC<IconSpinnerProps> = ({
    icon,
    ...props
}: IconSpinnerProps) => {
    const classes = useStyles()

    return (
        <div className={classes.wrapper}>
            {icon}
            <CircularProgress
                size={65}
                thickness={5}
                className={icon ? classes.spinner : classes.noIconSpinner}
                {...props}
            />
        </div>
    )
}
export default IconSpinner
