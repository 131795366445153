import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface PatientState {
    id?:string
    firstName?: string
    lastName?: string
    dateOfBirth?: string
    gender?: string
    address?: string
    email?: string
    phoneNumber?: string
}
const initialState: PatientState = {
    id:'',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',
    address: '',
    email: '',
    phoneNumber: '',
}

export const patientSlice = createSlice({
    name: 'patient',
    initialState: initialState,
    reducers: {
        updateId: (state, action: PayloadAction<string>) => {
            state.id = action.payload
        },
        updateFirstName: (state, action: PayloadAction<string>) => {
            state.firstName = action.payload
        },
        updateLastName: (state, action: PayloadAction<string>) => {
            state.lastName = action.payload
        },
        updateDob: (state, action: PayloadAction<string>) => {
            state.dateOfBirth = action.payload
        },
        updateGender: (state, action: PayloadAction<string>) => {
            state.gender = action.payload
        },
        updateAddress: (state, action: PayloadAction<string>) => {
            state.address = action.payload
        },

        updateEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload
        },
        updatePhoneNumber: (state, action: PayloadAction<string>) => {
            state.phoneNumber = action.payload
        },
        resetPatientData: () => initialState
    }
})

export const patientActions = patientSlice.actions
export default patientSlice.reducer