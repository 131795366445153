import { formatDatePeriod } from "../../components/util/index";
import TimelineDetailsCardDropdown from "../../components/TimelineDetailsCardDropdown/TimelineDetailsCardDropdown";
import TimelineDetailsCardItem from "../../components/TimelineDetailsCardItem/TimelineDetailsCardItem";
import TimelineDetailsStatus from "../TimelineDetailsCardItem/TimelineStatus";
import './TimelineCard.css'


const ProceduresTimelineCard = ({ resource }: any) => {
    const storedTheme = sessionStorage.getItem("theme");
    return (
        <div id="procedures-timeline-card">
            {!!resource.instantiatesCanonical?.length && (
                <TimelineDetailsCardDropdown
                    header="INSTANTIATE CANONICAL"
                    items={resource.instantiatesCanonical.map((item: any) => (
                        <TimelineDetailsCardItem label="STATUS" value={item.status} />
                    ))}
                />
            )}{" "}
            <div className="status-div">
                {resource.status && (
                    <TimelineDetailsStatus label="Status : " value={resource.status} />
                )}
            </div>
            <div className={storedTheme != 'dark' ? 'display-details' : 'display-details-dark'}>
                {resource.criticality && (
                    <TimelineDetailsCardItem
                        label="CRITICALITY"
                        value={`${resource.criticality}`}
                    />
                )}
                {resource.statusReason &&
                    resource.statusReason?.coding &&
                    resource.statusReason?.coding.length &&
                    resource.statusReason?.coding[0].display && (
                        <TimelineDetailsCardItem
                            label="STATUS REASONING"
                            value={`${resource.statusReason?.coding[0].display}`}
                        />
                    )}
                {resource.code?.coding &&
                    resource.code?.coding.length &&
                    resource.code?.coding[0].display && (
                        <TimelineDetailsCardItem
                            label="CODE"
                            value={`${resource.code?.coding[0].display}`}
                        />
                    )}
                {resource.performedDateTime && (
                    <TimelineDetailsCardItem
                        label="PERFORMED DATE"
                        value={formatDatePeriod(resource.performedDateTime)}
                    />
                )}
                {resource.search && (
                    <TimelineDetailsCardItem label="SEARCH" value={resource.search} />
                )}
            </div>
        </div>
    );
};

export default ProceduresTimelineCard;
