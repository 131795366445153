import { Button } from "@mui/material";
import { Modal, OverlayTrigger, Tab, Tabs, Tooltip } from "react-bootstrap";
import JSONTree from "react-json-tree";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useAppSelector } from "../../redux/app/hooks";
import { AxiosError } from "axios";
import { DownloadPatientBundles } from "../../apis/apis";
import './RawData.css'
//@ts-ignore
const ProfileView = ({
  content = '',
  show = false,
  handleClose = () => { },
}) => {
  const storedTheme = sessionStorage.getItem("theme");
  let tries = 0;
  const client_local = localStorage.getItem("client")
  const data = JSON.stringify(content, null, 2)
  const resource = useAppSelector((state) => state.resource)
  const auth = useAppSelector((state) => state.auth)
  const downloadPatinetBundle = async (res: any) => {
    try {
      const result = await DownloadPatientBundles(client_local, res, auth.authToken as string);
      const data = await new Blob([result.data])
      console.log(result.status)
      // responsestatus(result.status)
      console.log(data)
      const url = window.URL.createObjectURL(data)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = 'download-data.zip'
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    } catch (err) {
      tries += 1;
      if (tries > 3) {

        console.log(tries);
        console.log("fetch failed");
        // responsestatus(500)
        const error = err as AxiosError;
        console.log(error);
      }
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      // size="lg"
      centered
      dialogClassName="modal-90w"
      scrollable
      className={storedTheme != 'dark' ? 'view-data-modal' : 'view-data-modal-dark'}
    >
      <Modal.Header closeButton className="Header" style={{ 'color': storedTheme != 'dark' ? 'black' : 'white' }}>
        <Modal.Title className="headerTitle">JSON Output</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalBody">
        <OverlayTrigger placement='bottom' overlay={<Tooltip>
          Download
        </Tooltip>} >
          <Button
            className="downloadBtn"
            color={"inherit"}

            onClick={() => {
              downloadPatinetBundle('Patient')
            }}>
            <FileDownloadIcon style={{ 'color': storedTheme != 'dark' ? 'black' : 'white' }} />
          </Button>
        </OverlayTrigger>
        <Tabs defaultActiveKey="raw" id="uncontrolled-tab-example" className="mb-3">
          <Tab eventKey="raw" title="Raw">
            <div className="rawData" style={{ 'color': storedTheme != 'dark' ? 'black' : 'white' }}>
              <pre pams-content-load id="json-data">{data}</pre>
            </div>
          </Tab >
          <Tab eventKey="pretty" title="Pretty">
            <div className="body-content">
              <JSONTree
                data={content}
                theme={{
                  extend: 'monokai',
                  tree: {
                    backgroundColor: '#F3F6F9',
                  },
                  valueText: {
                    flex: 1,
                    flexWrap: 'wrap',
                  },
                }}
              ></JSONTree>
            </div>
          </Tab >

        </Tabs>
      </Modal.Body>
    </Modal>
  );
};
export default ProfileView;