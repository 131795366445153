import { useEffect, useState } from "react";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import '../../pages/HomePage/HomePage.scss'
import { useScrollBy } from "react-use-window-scroll";


export const ScrollOnClick = () => {
    const [icon, setIcon] = useState(<ArrowDownwardIcon className="scroll-icon"/>)
    
    var x = window.innerHeight - 100;

    const [goUp, setGoUp] = useState(false)    


    const toggleIcons = () => {
        if(window.pageYOffset >= 2300){
            setGoUp(true);
            setIcon(<ArrowUpwardIcon className="scroll-icon"/>)
        }
        else{
            setGoUp(false);
            setIcon(<ArrowDownwardIcon className="scroll-icon"/>)
        }
    };

    const scrollBy = useScrollBy();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const scrollDown = () => {
        scrollBy(x, 0)
        // window.scrollTo({
        //     top: m,
        //     behavior: 'smooth'
        // });
    };
    const scroll = () => {
        if(goUp){
            scrollToTop();
        }
        else{
            // setM(m+x);
            scrollDown();
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', toggleIcons);

        return() => {
            window.removeEventListener('scroll', toggleIcons);
        }
    },[])

    
    return(
        <div className="scroll-icon-container">
            <button className="scroll-icon-button" type="button" onClick={scroll}>
                {icon}
            </button>
        </div>
    );
};