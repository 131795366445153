import { CLAIMS_AND_CLINICAL_RESOURCE } from "../../constants/constants";
import AllergyTimelineCard from "../../components/TimelineCard/AlergyTimelineCard";
import PractitionerTimelineCard from "../../components/TimelineCard/Practitioner";
import ProceduresTimelineCard from "../../components/TimelineCard/ProceduresTimelineCard";
import ImmunizationsCardItem from "../../components/TimelineCard/ImmunizationCard";
import ConidtionsTimelineCardItem from "../../components/TimelineCard/ConditionsCard";
import CoverageTimelineCardItem from "../../components/TimelineCard/Coverage";
import MedicationsTimelineCard from "../../components/TimelineCard/Medications";
import MedicationRequestTimelineCard from "../../components/TimelineCard/MedicationReq";
import PatientTimelineCard from "../../components/TimelineCard/Patient";
import ObservationsTimelineCard from "../../components/TimelineCard/Observation";
import CarePlanTimelineCard from "../../components/TimelineCard/CarePlanTimelineCard";
import CareTeamTimelineCard from "../../components/TimelineCard/CareTeam";
import DeviceTimelineCardItem from "../../components/TimelineCard/Device";
import DiagnosticReportTimelineCard from "../../components/TimelineCard/DiagnosticCard";
import DocumentReferTimelineCard from "../../components/TimelineCard/DocumentReference";
import EncounterTimelineCard from "../../components/TimelineCard/Encounter";
import OrganizationTimelineCard from "../../components/TimelineCard/Organization";
import GoalTimelineCard from "../../components/TimelineCard/goal";
import LocationTimelineCard from "../../components/TimelineCard/location";
import PractitionerRoleTimelineCard from "../../components/TimelineCard/PractitionerRole";
import ProvenanceTimelineCard from "../../components/TimelineCard/Provenance";
import ClaimsTimelineCard from "../../components/TimelineCard/ClaimsTimelineCard";
import MedicationKnowTimelineCard from "../../components/TimelineCard/MedicationKnowledge";
import InsurancePlanCard from "../../components/TimelineCard/InsPlanTimelineCard";
import BasicTimelineCard from "../../components/TimelineCard/BasicTimelineCard";

const renderDetailsContent = (resource: any) => {
  if (resource) {
    const { resourceType } = resource?.resource;
    switch (resourceType) {
      case CLAIMS_AND_CLINICAL_RESOURCE.allergy:
        return <AllergyTimelineCard resource={resource?.resource} />;
      case CLAIMS_AND_CLINICAL_RESOURCE.claims:
        return <ClaimsTimelineCard resource={resource?.resource} />;
      case CLAIMS_AND_CLINICAL_RESOURCE.procedure:
        return <ProceduresTimelineCard resource={resource?.resource} />;
      case CLAIMS_AND_CLINICAL_RESOURCE.immunization:
        return <ImmunizationsCardItem resource={resource?.resource} />;
      case CLAIMS_AND_CLINICAL_RESOURCE.condition:
        return <ConidtionsTimelineCardItem resource={resource?.resource} />;
      case CLAIMS_AND_CLINICAL_RESOURCE.coverage:
        return <CoverageTimelineCardItem resource={resource?.resource} />;
      case CLAIMS_AND_CLINICAL_RESOURCE.medication:
        return <MedicationsTimelineCard resource={resource?.resource} />;
      case CLAIMS_AND_CLINICAL_RESOURCE.medicationRequest:
        return <MedicationRequestTimelineCard resource={resource?.resource} />;
      case CLAIMS_AND_CLINICAL_RESOURCE.knowledge:
        return <MedicationKnowTimelineCard resource={resource?.resource} />;
      case CLAIMS_AND_CLINICAL_RESOURCE.patient:
        return <PatientTimelineCard resource={resource?.resource} />;
      case CLAIMS_AND_CLINICAL_RESOURCE.observation:
        return <ObservationsTimelineCard resource={resource?.resource} />;
      case CLAIMS_AND_CLINICAL_RESOURCE.carePlan:
        return <CarePlanTimelineCard resource={resource?.resource} />;
      case CLAIMS_AND_CLINICAL_RESOURCE.careTeam:
        return <CareTeamTimelineCard resource={resource?.resource} />;
      case CLAIMS_AND_CLINICAL_RESOURCE.device:
        return <DeviceTimelineCardItem resource={resource?.resource} />;
      case CLAIMS_AND_CLINICAL_RESOURCE.diagnosticReport:
        return <DiagnosticReportTimelineCard resource={resource?.resource} />;
      case CLAIMS_AND_CLINICAL_RESOURCE.documentReference:
        return <DocumentReferTimelineCard resource={resource?.resource} />;
      case CLAIMS_AND_CLINICAL_RESOURCE.encounter:
        return <EncounterTimelineCard resource={resource?.resource} />;
      case CLAIMS_AND_CLINICAL_RESOURCE.organization:
        return <OrganizationTimelineCard resource={resource?.resource} />;
      case CLAIMS_AND_CLINICAL_RESOURCE.practitioner:
        return <PractitionerTimelineCard resource={resource?.resource} />;
      case CLAIMS_AND_CLINICAL_RESOURCE.goal:
        return <GoalTimelineCard resource={resource?.resource} />;
      case CLAIMS_AND_CLINICAL_RESOURCE.location:
        return <LocationTimelineCard resource={resource?.resource} />;
      case CLAIMS_AND_CLINICAL_RESOURCE.role:
        return <PractitionerRoleTimelineCard resource={resource?.resource} />;
      case CLAIMS_AND_CLINICAL_RESOURCE.provenance:
        return <ProvenanceTimelineCard resource={resource?.resource} />;
      case CLAIMS_AND_CLINICAL_RESOURCE.insurancePlan:
        return <InsurancePlanCard resource={resource?.resource} />;
      case CLAIMS_AND_CLINICAL_RESOURCE.basic:
        return <BasicTimelineCard resource={resource?.resource} />;
      default:
        return '';
    }
  }
};
export default renderDetailsContent;
