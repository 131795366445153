import { formatDatePeriod } from "../../components/util/index";
import TimelineDetailsCardDropdown from "../../components/TimelineDetailsCardDropdown/TimelineDetailsCardDropdown";
import TimelineDetailsCardItem from "../../components/TimelineDetailsCardItem/TimelineDetailsCardItem";
import TimelineDetailsStatus from "../TimelineDetailsCardItem/TimelineStatus";
import './TimelineCard.css'

const CareTeamTimelineCard = ({ resource }: any) => {
    const storedTheme = sessionStorage.getItem("theme");
    return (
        <div id="careTeam-timeline-card">
            <div className="status-div">
                {resource.clinicalStatus &&
                    resource.clinicalStatus.coding &&
                    resource.clinicalStatus.coding.length &&
                    (resource.clinicalStatus.coding[0].display ||
                        resource.clinicalStatus.coding[0].code) && (
                        <TimelineDetailsStatus
                            label="Clinical Status :"
                            value={
                                resource.clinicalStatus.coding[0].display ||
                                resource.clinicalStatus.coding[0].code
                            }
                        />
                    )}

                {resource.status && resource.status.length > 0 && (
                    <TimelineDetailsStatus label="Status :" value={`${resource.status}`} />
                )}
            </div>
            <div className={storedTheme != 'dark' ? 'display-details' : 'display-details-dark'}>
                {resource.type && (
                    <TimelineDetailsCardItem label="Type" value={resource.type} />
                )}
                {resource.name && (
                    <TimelineDetailsCardItem label="NAME" value={resource.name} />
                )}
                {resource.participant[0]?.role && (
                    <TimelineDetailsCardItem label="Partcipant Role" value={resource.participant[0]?.role[0]?.coding[0]?.display} />
                )}



                {resource.criticality && (
                    <TimelineDetailsCardItem
                        label="CRITICALITY"
                        value={`${resource.criticality}`}
                    />
                )}


                {resource.category &&
                    resource.category?.length > 0 &&
                    resource.category[0] &&
                    resource.category[0].coding[0] && (
                        <TimelineDetailsCardItem
                            label="CATEGORY"
                            value={`${resource.category[0].coding[0].code}`}
                        />
                    )}

                {resource.code?.coding &&
                    resource.code?.coding.length &&
                    resource.code?.coding[0].display && (
                        <TimelineDetailsCardItem
                            label="CODE"
                            value={`${resource.code?.coding[0].display}`}
                        />
                    )}

                {resource.effectiveDateTime && (
                    <TimelineDetailsCardItem
                        label="EFFECTIVE"
                        value={formatDatePeriod(
                            `${new Date(resource.effectiveDateTime).toDateString()}`
                        )}
                    />
                )}

                {!!resource.encounter?.length && (
                    <TimelineDetailsCardDropdown
                        header="Encounter"
                        items={resource.encounter.map((item: any) => (
                            <TimelineDetailsCardItem label="" value={item.reference} />
                        ))}
                    />
                )}
                {resource.onsetDateTime && (
                    <TimelineDetailsCardItem
                        label="ONSET"
                        value={formatDatePeriod(resource.onsetDateTime)}
                    />
                )}
                {resource.recordedDate && (
                    <TimelineDetailsCardItem
                        label="RECORDED DATE"
                        value={formatDatePeriod(resource.recordedDate)}
                    />
                )}
                {resource.recorder &&
                    resource.recorder.practitioner &&
                    resource.recorder.practitioner.name && (
                        <TimelineDetailsCardItem
                            label="RECORDER"
                            value={resource.recorder.practitioner.name}
                        />
                    )}

                {!!resource.note?.length && (
                    <TimelineDetailsCardDropdown
                        header="Notes"
                        items={resource.note.map((item: any) => (
                            <TimelineDetailsCardItem label="Text" value={item.text || ""} />
                        ))}
                    />
                )}

                {!!resource.reaction?.length && (
                    <TimelineDetailsCardDropdown
                        header="Reaction"
                        items={resource.reaction.map((item: any) => {
                            const resultItems = [];

                            if (
                                !!item.substance?.length &&
                                item.substance[0].coding &&
                                item.substance[0].coding.length &&
                                item.substance[0].coding.display
                            ) {
                                resultItems.push(
                                    <TimelineDetailsCardItem
                                        label="SUBSTANCE"
                                        value={item.substance[0].coding.display}
                                    />
                                );
                            }

                            if (
                                !!item.manifestation?.length &&
                                item.manifestation[0].coding &&
                                item.manifestation[0].coding.length &&
                                item.manifestation[0].coding.display
                            ) {
                                resultItems.push(
                                    <TimelineDetailsCardItem
                                        label="MANIFESTATION"
                                        value={item.manifestation[0].coding.display}
                                    />
                                );
                            }
                        })}
                    />
                )}
            </div>
        </div>
    );
};

export default CareTeamTimelineCard;
