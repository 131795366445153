import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface HealthData {
    isLoading?: boolean
    isError?: boolean
}

const initialState: HealthData = {
   isLoading :true,
   isError:false
}

export const HealthDataSlice = createSlice({
    name: 'HeathData',
    initialState: initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        setIsError: (state, action: PayloadAction<boolean>) => {
            state.isError = action.payload
        },
        resetHealthData: () => initialState
    },
})

export const HealthDataActions = HealthDataSlice.actions
export default HealthDataSlice.reducer
