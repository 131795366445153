import { formatDatePeriod } from "../util/index";
import TimelineDetailsCardDropdown from "../../components/TimelineDetailsCardDropdown/TimelineDetailsCardDropdown";
import TimelineDetailsCardItem from "../../components/TimelineDetailsCardItem/TimelineDetailsCardItem";
import './TimelineCard.css'

const PatientTimelineCard = ({ resource }: any) => {
    const storedTheme = sessionStorage.getItem("theme");
    console.log(resource.name[0].given);
    return (
        <div id="PatientTimelineCard">
            <div className={storedTheme != 'dark' ? 'display-details' : 'display-details-dark'}>

                {resource.name && (
                    <TimelineDetailsCardItem
                        label="NAME"
                        value={resource.name[0].given + " " + resource.name[0].family}
                    />
                )}
                {resource.birthDate && (
                    <TimelineDetailsCardItem
                        label="Date of Birth"
                        value={formatDatePeriod(resource.birthDate)}
                    />
                )}
                {resource.gender && (
                    <TimelineDetailsCardItem
                        label="GENDER"
                        value={resource.gender}
                    />
                )}
                {resource.address && (
                    <TimelineDetailsCardItem
                        label="Address"
                        value={resource.address[0].line + "," + resource.address[0].city + "," + resource.address[0].state + "," + resource.address[0].postalCode + "," + resource.address[0].country}
                    />
                )}
                {resource.telecom && resource.telecom[0]?.system == 'phone' && (

                    < TimelineDetailsCardItem label="Phone" value={resource.telecom[0]?.value} />
                )}
                {resource.telecom && resource.telecom[1]?.system == 'email' && (

                    < TimelineDetailsCardItem label="Email" value={resource.telecom[1]?.value} />
                )}
            </div>
        </div>
    );
};

export default PatientTimelineCard;
