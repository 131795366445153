import axios from "axios";
import { blob } from "stream/consumers";

export const fetchTermsAndConditions = async () => {

  try {
    return await axios.get(
      `${process.env.REACT_APP_TERMS_AND_PRIVACY}/api/HttpTriggerTOS`, {
      headers: {
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_Apim_Subscription_Key}`,
        "clientId":3,
      },
    }
    );
  } catch (err) {
    throw new Error(`${err}`);
  }
};
export const fetchPrivacyPolicy = async () => {

  try {
    return await axios.get(
      `${process.env.REACT_APP_TERMS_AND_PRIVACY}/api/HttpTriggerPP`, {
      headers: {
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_Apim_Subscription_Key}`,
        "clientId":3,
      },
    }
    );
  } catch (err) {
    throw new Error(`${err}`);
  }
};

export const confirmPatientDetails = async (client_local: any,token:string) => {
  try {
    return await axios.get(`${process.env.REACT_APP_API_CLARITY}/filldetails/`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        AuthToken: token,
        ClientName: client_local
      },
    });
  } catch (err) {
    throw new Error(`${err}`);
  }
};


export const GetPatientBundles = async (client_local: any,selectedResource:any,token:string) => {
  console.log(`${process.env.REACT_APP_API_CLARITY}/api/v1/resourcebundle/${selectedResource}`)
  try {
    return await axios.get(`${process.env.REACT_APP_API_CLARITY}/api/v1/resourcebundle/${selectedResource}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        AuthToken: token,
        ClientName: client_local
      },
    });
    
  } catch (err) {
    throw new Error(`${err}`);
  }
};
export const GetResourceList = async (client_local: any,token:string) => {
  try {
    return await axios.get(`${process.env.REACT_APP_API_CLARITY}/api/v1/listresources/`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        AuthToken: token,
        ClientName: client_local
      },
    });
  } catch (err) {
    throw new Error(`${err}`);
  }
};
export const DownloadPatientBundles = async (client_local: any,selectedResource:any,token:string) => {
  console.log(`${process.env.REACT_APP_API_CLARITY}/api/v1/downloadzip/${selectedResource}`)
  try {
    return await axios.get(`${process.env.REACT_APP_API_CLARITY}/api/v1/downloadzip/${selectedResource}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        AuthToken: token,
        ClientName: client_local
      },responseType:"blob",
    });
    
  } catch (err) {
    throw new Error(`${err}`);
  }
};
export const DownloadBulkPatinetBundles = async (client_local: any,token:string) => {
  console.log(`${process.env.REACT_APP_API_CLARITY}/api/v1/downloadzip/All`)
  try {
    return await axios.get(`${process.env.REACT_APP_API_CLARITY}/api/v1/downloadzip/All`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        AuthToken: token,
        ClientName: client_local
      },responseType:"blob",
    });
    
  } catch (err) {
    throw new Error(`${err}`);
  }
};

export const GetBulkPatientBundles = async (client_local: any,token:string) => {
  console.log(`${process.env.REACT_APP_API_CLARITY}/api/v1/resourcebundle/All`)
  try {
    return await axios.get(`${process.env.REACT_APP_API_CLARITY}/api/v1/resourcebundle/All`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        AuthToken: token,
        ClientName: client_local
      },
    });
    
  } catch (err) {
    throw new Error(`${err}`);
  }
};

export const fetchActivePayerList = async () => {
  const GET_PAYERLIST_URL = `${process.env.REACT_APP_API_CLARITY}/api/get-payerlist/`;
  try {
    return await axios.get(GET_PAYERLIST_URL, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_Apim_Subscription_Key}`,
      },
    });
  } catch (err) {
    throw new Error(`${err}`);
  }
};
export const Postpayername = async (selectElement: any, payerName: any) => {
  const Payer = { oldplan: selectElement, oldplanName: payerName };
  const OLD_PAYER_GETDETAILS_FROM_DB_URL = `${process.env.REACT_APP_OAUTH_OLD_PAYER}/payer`;
  try {
    return await axios
      .post(OLD_PAYER_GETDETAILS_FROM_DB_URL, Payer)
      .then(() => console.log("Payer Sent"))

  } catch (err) {
    throw new Error(`${err}`);
  }
};
export const getNewpayerlist = async (payerName: any, redirect: string | undefined) => {
  const Payer = { newplanName: payerName, redirect: redirect };
  const NEW_PAYER_GETDETAILS_FROM_DB_URL = `${process.env.REACT_APP_OAUTH_NEW_PAYER}/gotopayer`;
  try {
    return await axios
      .post(NEW_PAYER_GETDETAILS_FROM_DB_URL, Payer)
      .then(() => console.log("Payer Sent"))

  } catch (err) {
    throw new Error(`${err}`);
  }
};
export const GetClientName = async () => {
  const OLD_PAYER_GETCLIENTDETAILS_URL = `${process.env.REACT_APP_OAUTH_NEW_PAYER}/clientname`;
  const requestOptions: object = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(OLD_PAYER_GETCLIENTDETAILS_URL, requestOptions);
    const data = await res.data;
    var clientname = data["ClientName"];
    console.log(data);
    console.log(clientname);

    return res;
  } catch (err) {
    throw new Error(`${err}`);
  }
};

export const fetchHealthPlanList = async () => {
  const GET_PLANLIST_URL = `${process.env.REACT_APP_API_CLARITY}/api/plans/with_payers/`;
  try {
    return await axios.get(GET_PLANLIST_URL, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_Apim_Subscription_Key}`,
      },
    });
  } catch (err) {
    throw new Error(`${err}`);
  }
};

// Digital Insurance Card
export const C4DIC_Coverage = async (client_local: any) => {
  try {
    return await axios.get(`${process.env.REACT_APP_API_CLARITY}/api/v1/c4dic-resource/Coverage`, {
      headers: {
        ClientName: client_local
      },
    });
  } catch (err) {
    throw new Error(`${err}`);
  }
};

export const C4DIC_Patient = async (patientId: string,client_local: any) => {
  try {
    return await axios.get(`${process.env.REACT_APP_API_CLARITY}/api/v1/c4dic-resource/Patient/${patientId}`,{
      headers: {
        ClientName: client_local
      },
    });
  } catch (err) {
    throw new Error(`${err}`);
  }
};

export const C4DIC_Organization= async (payorID: string,client_local: any) => {
  console.log(payorID);
  
  try {
    return await axios.get(`${process.env.REACT_APP_API_CLARITY}/api/v1/c4dic-resource/${payorID}`,{
      headers: {
        ClientName: client_local
      },
    });
  } catch (err) {
    throw new Error(`${err}`);
  }
};


//One Id
export const One_Id= async (postData:object) => {
  try {
    return await axios.post(`${process.env.REACT_APP_API_ONEID}`,postData);
  } catch (err) {
    throw new Error(`${err}`);
  }
};

//getPatientId
export const getPatientId = async (client_local: any,token:string) => {
  try {
    return await axios.get(`${process.env.REACT_APP_API_CLARITY}/api/v1/get-fhirid/`, {
      headers: {
        AuthToken: token,
        ClientName: client_local
      },
    });
  } catch (err) {
    throw new Error(`${err}`);
  }
};
