import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { appInsights } from "../../AppInsights/Appinsights";
import RawDataView from "../../components/JsonData/RawDataView";
import AppLayout from "../../components/Layout/AppLayout";
import Loading from "../../components/Loading/Loading";
import TimelineList from "../../components/TimelineList/TimelineList";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { bundleActions } from "../../redux/feature/BundleSlice";
import { timelineActions } from "../../redux/feature/TimelineSlice";
import "./ResourcePage.scss";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import SortIcon from "@mui/icons-material/Sort";
import { getYearValue } from "../../functions/TimelineList/timelineList";
import DownloadData from "../../components/DownloadData/DownloadData";
import emptyResourceLogo from "../../images/empty-resource.svg";
import * as React from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import ClearIcon from '@mui/icons-material/Clear';
import YearPicker from "../../components/YearPicker/YearPicker";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router";
import Typography from "@mui/material/Typography";
import ErrorModal from "../../components/Modals/ErrorModal/ErrorModal";
import useWebSocket from "react-use-websocket";
import LodaingResourceView from "../../components/Loading/LodaingResourceView";
import { LinearProgress } from "@mui/material";
import { getResourceDate } from "../../functions/ResourcePage/ResourcePage_funs";

interface ResourceComponentProps {
  resource: string;
  count: string;
}

function ResourcePage({ resource, count, ...props }: ResourceComponentProps): JSX.Element {
  const storedTheme = sessionStorage.getItem("theme");
  const res_count = count.replace(/\D/g, '')
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const auth = useAppSelector((state) => state.auth);
  const timeline = useAppSelector((state) => state.timeline);
  const [showRawData, setShowRawData] = useState(false);
  const [showRange, setshowRange] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [year, setYear] = useState<Date | null>(null);
  const [filter, setfilter] = useState(false);
  const [rangeFilter, setrangeFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dateRange, setDateRange] = useState();
  const [sortButtonDisabled, setSortButtonDisabled] = useState(true);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [downloadData, setDownloadData] = useState(false);
  const [isError, setIsError] = useState(false)
  const [progress, setProgress] = useState(0);
  const [displayData, setDisplayData] = useState<any[]>([]);
  const [displayResults, setDisplayResults] = useState<any[]>([]);

  const client_local = localStorage.getItem("client");
  const endpoint = `${process.env.REACT_APP_API_CLARITY_WEBSOCKET}/display/`;
  const msg = {
    'AuthToken': auth?.authToken,
    'ClientName': client_local,
    'resource': resource
  };

  const { lastMessage, readyState, sendJsonMessage } = useWebSocket(endpoint);

  const receivedMessages = useAppSelector((state) => state.bundle.selectedBundle);

  if (readyState === 3) {
    appInsights.trackEvent({
      name: `Error: connection lost since websocket state is ${readyState}`,
    });
    setIsError(true)
  }


  useEffect(() => {
    const fetchDataAndDispatch = async () => {
      try {
        const res = await sendJsonMessage(msg);
      } catch (error) {
        console.error('Error sending JSON message:', error);
        appInsights.trackEvent({
          name: `Error: ${error}`,
        });
        setIsError(true);
      }
    };

    fetchDataAndDispatch();
  }, []);

  useEffect(() => {
    if (lastMessage !== null) {
      const parsedMessage = JSON.parse(lastMessage.data);
      dispatch(bundleActions.updateSelectedBundle(parsedMessage));
      if (parsedMessage.Status === "End of Stream") {
        setProgress(100);
        setSortButtonDisabled(false);
      } else {
        setSortButtonDisabled(true);
        setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
      }
      if (parsedMessage.Error) {
        appInsights.trackEvent({
          name: `Error: ${parsedMessage.Error}`,
        });
        setIsError(true);
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    const mergedEntry = receivedMessages.flatMap((message) => message?.entry ?? []);
    mergedEntry.sort((a, b) => {
      const dateA = getResourceDate(a);
      const dateB = getResourceDate(b);

      if (!dateA) return -1;
      if (!dateB) return 1;

      const dateObjectA = new Date(dateA);
      const dateObjectB = new Date(dateB);

      return dateObjectB.getTime() - dateObjectA.getTime();
    });
    let selectedYear = year?.getFullYear().toString();
    const filterResults = mergedEntry.filter((entry) => selectedYear?.includes(getYearValue(entry)));
    let newDisplayData = filter ? filterResults : mergedEntry;

    setDisplayData(newDisplayData);

  }, [receivedMessages, year, filter]);

  useEffect(() => {
    const filterRangeResults = displayData.filter((entry) => {
      let resourceDate =
        entry.resource?.created ??
        entry.resource?.servicedPeriod?.start ??
        entry.resource?.billablePeriod?.start ??
        entry.resource?.servicedDate ??
        entry.resource?.onsetDateTime ??
        entry.resource?.performedDateTime ??
        entry.resource?.occurrenceDateTime ??
        entry.resource?.effectiveDateTime ??
        entry.resource?.period?.start ??
        entry.resource?.date ??
        entry.resource?.meta?.lastUpdated;
      const itemDate = new Date(resourceDate);
      return +itemDate >= +startDate && +itemDate <= +endDate && entry;
    });

    const newDisplayResults = rangeFilter ? filterRangeResults : displayData;

    setDisplayResults(newDisplayResults);
  }, [displayData, startDate, endDate, rangeFilter]);


  const onTimelineClick = (item: any) => {
    dispatch(timelineActions.updateselectedTimeline(item));
    setShowRawData(true);
  };

  const handleErrorModalClose = (
    event: Record<string, unknown>,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return
    }
    setIsError(false)
  }

  const dateRangeSelector = (date_range: any) => {
    setDateRange(date_range);
    setStartDate(date_range?.[0]);
    setEndDate(date_range?.[1]);
    if (date_range === null) {
      setrangeFilter(false);
    }
    else { setrangeFilter(true); }
  };

  const EmptyResourceView = () => {
    return (
      <div className="empty-container">
        <img src={emptyResourceLogo} alt="EmptyResourceLogo" className="empty-logo" />
        <p style={{ color: storedTheme !== 'dark' ? 'black' : 'white' }}><b>No Records Found..!</b> for the selected year/ date range. Please try for another year/ date range.</p>
      </div>

    );
  };


  return (
    <AppLayout subHeading="My Health Data">
      <Container id={storedTheme !== 'dark' ? 'ResourcePage' : 'ResourcePage-dark'}>
        {
          <DownloadData
            show={downloadData}
            handleClose={() => {
              setDownloadData(false);
            }}
            title={resource + '  resource'}
            resourceSelected={resource}
          />
        }
        <Button
          id="back-button"
          onClick={() => {
            navigate(-1)
            dispatch(bundleActions.resetBundle())
          }}
          sx={{ padding: 0 }}
        >
          <ArrowBackIosIcon sx={{ fontSize: '1rem' }} />
          Back
        </Button>
        <Row id="mainContainer">
          <Container style={{ 'height': '100%' }}>
            <Row className="filterDiv">
              <Col>
                <Typography component="h5"
                  sx={{
                    fontWeight: 500,
                    fontSize: { xs: '1rem', sm: '1rem', lg: '1.2rem' },
                    fontFamily: 'inter',
                    color: storedTheme !== 'dark' ? 'black' : 'white'
                  }}
                  onClick={() => {
                    setfilter(false);
                    setrangeFilter(false);
                  }}
                >
                  {resource}
                </Typography>
              </Col>
              <Col xl={3} lg={4} md={4} sm={4}>
                <Row>
                  {!sortButtonDisabled ? (<>
                    {!showRange ? (
                      <YearPicker value={year} onChange={(e: any) => { setYear(e); setfilter(true); }} onClear={() => { setYear(null); setfilter(false); }} />
                    ) : (
                      <>
                        <p style={{ 'marginBottom': '0', 'fontSize': '0.7rem', color: storedTheme !== 'dark' ? 'black' : 'white' }}>Select Date Range</p>
                        <DateRangePicker
                          onChange={(e: any) => {
                            dateRangeSelector(e)
                          }}
                          value={dateRange}
                          calendarAriaLabel="Select Date Range"
                          clearIcon={<ClearIcon onClick={() => { setrangeFilter(false) }} />}
                        />
                      </>
                    )}
                  </>) : null}
                </Row>
              </Col>
              <Col>
                <Row className="filterOptions_div">
                  <Col xl={7} lg={7} md={7} sm={7}>
                    <Button
                      id="export-button"
                      onClick={() => {
                        setDownloadData(true);
                      }}
                    >
                      <NorthEastIcon />
                      &nbsp; Export Medical Records
                    </Button>
                  </Col>
                  <Col xl={3} lg={5} md={5} sm={5}>
                    <Button
                      id="sort-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      disabled={sortButtonDisabled}
                    >
                      <SortIcon />
                      Sort
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          setshowRange(false);
                          handleClose();
                        }}
                      >
                        Year
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setshowRange(true);
                          handleClose();
                        }}
                      >
                        Range
                      </MenuItem>
                    </Menu>
                  </Col>
                </Row>
              </Col>
            </Row>
            <LinearProgress variant="determinate" value={progress} sx={{ position: 'sticky' }} />
            <Col className="resourceListContainer">
              {receivedMessages.length > 0 ? (
                <>
                  {!showRange ?
                    <>
                      {//@ts-ignore
                        displayData?.length > 0 ?
                          <TimelineList
                            data={displayData}
                            itemClick={onTimelineClick}
                          />
                          :
                          <EmptyResourceView />
                      }
                    </> :
                    <>
                      {//@ts-ignore
                        displayResults?.length > 0 ?
                          <TimelineList
                            data={displayResults}
                            itemClick={onTimelineClick}
                          />
                          :
                          <EmptyResourceView />
                      }
                    </>
                  }
                </>
              ) : <LodaingResourceView />}

            </Col>
            <Row className="disclaimerDiv">
              <Col>
                <p className="disclaimerTxt">
                  <b>DISCLAIMER:</b> The data present here are the resources from
                  the implimentation guides and follow the standards set by FHIR.{" "}
                </p>
              </Col>
            </Row>
          </Container>
        </Row>

        <RawDataView
          show={showRawData}
          name={resource}
          //@ts-ignore
          content={timeline.selectedTimeline}
          handleClose={() => {
            setShowRawData(false);
          }}
        />
        <ErrorModal open={isError} onClose={handleErrorModalClose}>
          An error occurred while trying to retrieve your healthcare
          data. Please try again after some time or contact our{' '}
          <a>support</a>.
        </ErrorModal>
      </Container >
    </AppLayout >
  );
}

export default ResourcePage;
