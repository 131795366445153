function CareTeamIcon({ className }: { className?: string }) {

    return (
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={className} d="M8.4386 30.3749C6.10986 30.3749 4.21775 28.1062 4.21775 25.3124C4.13865 24.1702 4.55631 23.0501 5.3663 22.2401C6.1763 21.4301 7.29637 21.0124 8.4386 21.0947C9.58082 21.0124 10.7009 21.4301 11.5109 22.2401C12.3177 23.0501 12.7385 24.1702 12.6563 25.3124C12.6563 28.1062 10.7673 30.3749 8.4386 30.3749ZM8.4386 22.7811C7.74567 22.7052 7.05591 22.9488 6.56548 23.4393C6.07189 23.9329 5.83142 24.6195 5.90736 25.3124C5.90736 27.176 7.04641 28.6884 8.4386 28.6884C9.83078 28.6884 10.9698 27.176 10.9698 25.3124C11.0458 24.6195 10.8021 23.9329 10.3117 23.4393C9.81812 22.9488 9.12836 22.7052 8.4386 22.7811Z" fill="#201602" />
            <path className={className} d="M1.68639 40.4999H-4.84233e-05V33.3966C-0.0127046 31.2355 1.60729 29.413 3.75568 29.1757L8.34355 28.6885L8.53023 30.3749L3.93919 30.8907C2.65143 31.033 1.68006 32.1246 1.68639 33.4219V40.4999Z" fill="#201602" />
            <path className={className} d="M3.37598 33.751H5.06242V40.4999H3.37598V33.751Z" fill="#201602" />
            <path className={className} d="M11.8114 33.751H13.501V40.4999H11.8114V33.751Z" fill="#201602" />
            <path className={className} d="M20.2499 30.3749C17.9211 30.3749 16.0322 28.1062 16.0322 25.3124C15.9499 24.1702 16.3676 23.0501 17.1776 22.2401C17.9876 21.4301 19.1076 21.0124 20.2499 21.0947C21.3921 21.0124 22.5122 21.4301 23.3222 22.2401C24.1322 23.0501 24.5498 24.1702 24.4675 25.3124C24.4675 28.1062 22.5786 30.3749 20.2499 30.3749ZM20.2499 22.7811C19.5569 22.7052 18.8703 22.9488 18.3767 23.4393C17.8863 23.9329 17.6427 24.6195 17.7186 25.3124C17.7186 27.176 18.8577 28.6884 20.2499 28.6884C21.642 28.6884 22.7811 27.176 22.7811 25.3124C22.857 24.6195 22.6134 23.9329 22.123 23.4393C21.6294 22.9488 20.9428 22.7052 20.2499 22.7811Z" fill="#201602" />
            <path className={className} d="M13.501 40.5001H11.8114V33.3968C11.7987 31.2357 13.4187 29.4132 15.5671 29.1759L20.1581 28.6697L20.3416 30.3593L15.7538 30.8719C14.466 31.0142 13.4915 32.109 13.501 33.4031V40.5001Z" fill="#201602" />
            <path className={className} d="M15.1875 33.751H16.8739V40.4999H15.1875V33.751Z" fill="#201602" />
            <path className={className} d="M28.6885 40.4999H26.9989V33.3966C27.0084 32.0993 26.0338 31.0077 24.7461 30.8654L20.1582 30.3749L20.3417 28.6885L24.9328 29.1947C27.0811 29.432 28.7011 31.2514 28.6885 33.4124V40.4999Z" fill="#201602" />
            <path className={className} d="M23.626 33.751H25.3124V40.4999H23.626V33.751Z" fill="#201602" />
            <path className={className} d="M32.0612 30.3749C29.7325 30.3749 27.8435 28.1062 27.8435 25.3124C27.7613 24.1702 28.1821 23.0501 28.9889 22.2401C29.7989 21.4301 30.919 21.0124 32.0612 21.0947C33.2034 21.0124 34.3235 21.4301 35.1335 22.2401C35.9435 23.0501 36.3612 24.1702 36.2821 25.3124C36.2821 28.1062 34.3899 30.3749 32.0612 30.3749ZM32.0612 22.7811C31.3714 22.7052 30.6817 22.9488 30.1881 23.4393C29.6977 23.9329 29.454 24.6195 29.53 25.3124C29.53 27.176 30.669 28.6884 32.0612 28.6884C33.4534 28.6884 34.5924 27.176 34.5924 25.3124C34.6684 24.6195 34.4279 23.9329 33.9343 23.4393C33.4439 22.9488 32.7541 22.7052 32.0612 22.7811Z" fill="#201602" />
            <path className={className} d="M26.9989 33.751H28.6885V40.4999H26.9989V33.751Z" fill="#201602" />
            <path className={className} d="M40.4999 40.4999H38.8134V33.3966C38.8198 32.0993 37.8484 31.0077 36.5606 30.8654L31.9696 30.3749L32.1563 28.6885L36.7442 29.1947C38.8925 29.432 40.5125 31.2514 40.4999 33.4124V40.4999Z" fill="#201602" />
            <path className={className} d="M35.4374 33.751H37.1238V40.4999H35.4374V33.751Z" fill="#201602" />
            <path className={className} d="M32.0613 18.5636C31.7639 18.5636 31.4886 18.4054 31.3368 18.1491L29.0492 14.3427H23.2621L20.9745 18.1491C20.8226 18.4054 20.5473 18.5636 20.2499 18.5636C19.9525 18.5636 19.6772 18.4054 19.5254 18.1491L17.2377 14.3427H11.4507L9.16309 18.1491C9.01122 18.4054 8.73594 18.5636 8.43852 18.5636C8.1411 18.5636 7.86267 18.4054 7.71079 18.1491L5.42635 14.3427H0.844802C0.376522 14.3427 0 13.9662 0 13.5011V0.844805C0 0.376524 0.376522 0 0.844802 0H39.655C40.1233 0 40.4999 0.376524 40.4999 0.844805V13.5011C40.4999 13.9662 40.1233 14.3427 39.655 14.3427H35.0735L32.7891 18.1491C32.6372 18.4054 32.3587 18.5636 32.0613 18.5636ZM22.7812 12.6563H29.5301C29.8275 12.6563 30.1059 12.8145 30.2578 13.0707L32.0613 16.0829L33.868 13.0707C34.0199 12.8145 34.2951 12.6563 34.5926 12.6563H38.8134V1.68645H1.68644V12.6563H5.90728C6.2047 12.6563 6.47998 12.8145 6.63185 13.0707L8.43852 16.0829L10.242 13.0707C10.3939 12.8145 10.6723 12.6563 10.9698 12.6563H17.7187C18.0161 12.6563 18.2914 12.8145 18.4433 13.0707L20.2499 16.0829L22.0566 13.0707C22.2085 12.8145 22.4837 12.6563 22.7812 12.6563Z" fill="#201602" />
            <path className={className} d="M25.3124 4.21777H36.2821V5.90738H25.3124V4.21777Z" fill="#201602" />
            <path className={className} d="M14.3427 4.21777H23.626V5.90738H14.3427V4.21777Z" fill="#201602" />
            <path className={className} d="M5.0625 4.21777H12.6562V5.90738H5.0625V4.21777Z" fill="#201602" />
            <path className={className} d="M18.5635 8.43848H28.6884V10.1249H18.5635V8.43848Z" fill="#201602" />
            <path className={className} d="M5.0625 8.43848H16.8739V10.1249H5.0625V8.43848Z" fill="#201602" />
        </svg>
    )
}
export default CareTeamIcon;