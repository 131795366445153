function LogoutIcon({ className }: { className?: string }) {
    const storedTheme = sessionStorage.getItem("theme");
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" >
            <g clipPath="url(#clip0_287_2)">
                <path className={className}
                    d="M15 30C6.71573 30 -2.49433e-06 23.2843 -1.60687e-06 15C-7.19422e-07 6.71573 6.71573 -1.66119e-06 15 -1.07016e-06C23.2843 -4.79127e-07 30 6.71573 30 15C30 23.2843 23.2843 30 15 30Z" fill="black" />
                <path style={{ 'fill': storedTheme !== 'dark' ? 'white' : 'black' }}
                    d="M22.5312 15.5312C22.8125 15.25 22.8125 14.7812 22.5312 14.4688L17.2812 9.21875C16.7812 8.75 16 9.09375 16 9.75V12.75H11.75C11.3125 12.75 11 13.0938 11 13.5V16.5C11 16.9375 11.3125 17.25 11.75 17.25H16V20.25C16 20.9375 16.8125 21.25 17.2812 20.7812L22.5312 15.5312ZM13 20.625V19.375C13 19.1875 12.8125 19 12.625 19H10C9.4375 19 9 18.5625 9 18V12C9 11.4688 9.4375 11 10 11H12.625C12.8125 11 13 10.8438 13 10.625V9.375C13 9.1875 12.8125 9 12.625 9H10C8.34375 9 7 10.3438 7 12V18C7 19.6562 8.34375 21 10 21H12.625C12.8125 21 13 20.8438 13 20.625Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_287_2">
                    <rect width="30" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default LogoutIcon
