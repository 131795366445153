function DocumentIcon({ className }: { className?: string }) {

    return (
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={className} d="M35.2952 2.21255L8.23565 0.493124C7.35354 0.43761 6.58848 1.07305 6.5294 1.9108L6.31773 4.91764H2.72124C1.83749 4.91764 1.11841 5.60076 1.11841 6.44034V43.4606C1.11841 44.3002 1.83749 44.9833 2.72124 44.9833H23.4336C23.6131 44.9833 23.791 44.9136 23.9183 44.7931L27.0451 41.836C27.0768 41.8424 27.1093 41.8476 27.1427 41.8496L32.4807 42.1888C33.3327 42.2422 34.1297 41.5839 34.1869 40.7712L36.7875 3.83357C36.846 3.00452 36.1678 2.26797 35.2952 2.21255ZM24.1206 42.7513V38.218C24.1206 38.098 24.2233 38.0004 24.3496 38.0004H29.1031L24.1206 42.7513ZM32.8161 40.6841C32.7937 40.8155 32.7125 40.883 32.5725 40.8866L28.3336 40.6171L31.2418 37.8666C31.3714 37.7442 31.4442 37.5778 31.4442 37.4043V6.44042C31.4442 5.60085 30.7251 4.91773 29.8414 4.91773H22.6979C21.8097 4.91773 21.8097 6.22289 22.6979 6.22289H29.8414C29.9676 6.22289 30.0703 6.32052 30.0703 6.44042V36.6953H24.3496C23.4658 36.6953 22.7468 37.3784 22.7468 38.218V43.6782H2.72124C2.59504 43.6782 2.49227 43.5805 2.49227 43.4606V6.44042C2.49227 6.32052 2.59504 6.22289 2.72124 6.22289H19.0398C19.928 6.22289 19.928 4.91773 19.0398 4.91773H7.69462L7.90015 1.99799C7.92249 1.86651 8.00374 1.79907 8.14387 1.79551L35.2035 3.51485C35.3418 3.53607 35.4128 3.61326 35.4166 3.74638L32.8161 40.6841Z" fill="black" />
            <path className={className} d="M25.6487 21.0869H6.91361C6.53425 21.0869 6.22668 21.3791 6.22668 21.7395C6.22668 22.0999 6.53425 22.3921 6.91361 22.3921H25.6487C26.0283 22.3921 26.3357 22.0999 26.3357 21.7395C26.3357 21.3791 26.0283 21.0869 25.6487 21.0869Z" fill="black" />
            <path className={className} d="M25.6487 25.6367H6.91361C6.53425 25.6367 6.22668 25.9289 6.22668 26.2893C6.22668 26.6497 6.53425 26.9418 6.91361 26.9418H25.6487C26.0283 26.9418 26.3357 26.6497 26.3357 26.2893C26.3357 25.9289 26.0283 25.6367 25.6487 25.6367Z" fill="black" />
            <path className={className} d="M6.22668 17.1901C6.22668 17.5505 6.53425 17.8427 6.91361 17.8427H25.6487C26.0283 17.8427 26.3357 17.5505 26.3357 17.1901C26.3357 16.8298 26.0283 16.5376 25.6487 16.5376H6.91361C6.53425 16.5376 6.22668 16.8298 6.22668 17.1901Z" fill="black" />
            <path className={className} d="M9.52734 12.6406C9.52734 13.0011 9.83491 13.2933 10.2143 13.2933H22.3488C22.7282 13.2933 23.0358 13.0011 23.0358 12.6406C23.0358 12.2802 22.7282 11.988 22.3488 11.988H10.2143C9.83491 11.988 9.52734 12.2802 9.52734 12.6406Z" fill="black" />
            <path className={className} d="M18.9561 38.6408C17.7206 38.644 16.4851 38.6464 15.2498 38.6484C14.8345 38.6489 14.4193 38.6495 14.0041 38.6497C13.2516 38.6503 12.7447 38.5067 12.5762 37.7205C12.4337 37.0557 11.4438 37.1451 11.2514 37.7205C10.7761 39.142 9.98985 38.585 9.54947 37.6289C9.11012 36.675 8.99746 35.6074 8.80558 34.5922C8.66453 33.8463 7.47696 34.0553 7.45626 34.7656C7.42897 35.7012 7.33701 36.6218 7.08706 37.529C6.82924 38.4651 6.44282 39.3646 6.05923 40.259C5.72483 41.0388 7.05454 41.3742 7.384 40.6061C7.7239 39.8135 8.06424 39.0167 8.31759 38.1946C8.78836 39.2701 9.76234 40.4865 11.1502 39.7696C11.4084 39.6362 11.6496 39.4467 11.8613 39.2255C12.5829 40.0178 13.6572 39.9554 14.6624 39.9543C16.0938 39.9526 17.525 39.9495 18.9563 39.946C19.84 39.9438 19.8417 38.6387 18.9561 38.6408Z" fill="black" />
        </svg>
    )
}
export default DocumentIcon;
