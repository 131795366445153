import { Button, Modal, Spinner } from "react-bootstrap";
import "./DownloadData.scss";
import { appInsights } from "../../AppInsights/Appinsights";
import { DownloadBulkPatinetBundles, DownloadPatientBundles } from "../../apis/apis";
import { useAppSelector } from "../../redux/app/hooks";
import { AxiosError } from "axios";
import { useState } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

//@ts-ignore
const DownloadData = ({ show = false, handleClose = () => { }, title = '', resourceSelected = '' }) => {
  const storedTheme = sessionStorage.getItem("theme");
  const auth = useAppSelector((state) => state.auth);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showThumbUpIcon, setShowThumbUpIcon] = useState(false);
  const [showDownloadBtn, setshowDownloadBtn] = useState(true);

  let tries = 0;
  const client_local = localStorage.getItem("client");
  const downloadPatinetBundles = async () => {
    appInsights.trackEvent({ name: `Download Patient Bundles` });
    appInsights.trackEvent({ name: "Downloading... " });
    const DownloadBundle_URL = `${process.env.REACT_APP_API_CLARITY}/api/v1/downloadzip/All`;
    try {
      const result = await DownloadBulkPatinetBundles(
        client_local,
        auth.authToken as string
      );
      const data = await new Blob([result.data]);
      responsestatus(result.status);
      appInsights.trackEvent({
        name: `Download patient bundles URL: ${DownloadBundle_URL}`,
      });
      const url = window.URL.createObjectURL(data);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "download-data.zip";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      tries += 1;
      if (tries > 3) {
        console.log("fetch failed");
        responsestatus(500);
        const error = err as AxiosError;
        console.log(error);
        appInsights.trackException({
          exception: Error(
            `Failed to download patient bundles URL: ${DownloadBundle_URL}`
          ),
        });
      }
    }
  };

  const downloadSelectedBundle = async (res: any) => {
    appInsights.trackEvent({ name: `Download Patient Bundle of ${res} ’bundle` })
    appInsights.trackEvent({ name: "Downloading... " });
    const DownloadBundle_URL = `${process.env.REACT_APP_API_CLARITY}/api/v1/downloadzip/${res}/`;
    try {
      const result = await DownloadPatientBundles(client_local, res, auth.authToken as string);
      const data = await new Blob([result.data])
      responsestatus(result.status);
      appInsights.trackEvent({
        name: `Download patient bundle URL: ${DownloadBundle_URL}`,
      });
      const url = window.URL.createObjectURL(data)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = 'download-data.zip'
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      appInsights.trackEvent({ name: "Downloaded " });

    } catch (err) {
      tries += 1;
      if (tries > 3) {
        console.log(tries);
        console.log("fetch failed");
        responsestatus(500);
        const error = err as AxiosError;
        console.log(error);
        appInsights.trackException({
          exception: Error(
            `Failed to download patient data URL: ${DownloadBundle_URL}`
          ),
        });
      }
    }
  }
  const responsestatus = (res: number) => {
    if (res === 200) {
      setShowSpinner(false);
      setShowThumbUpIcon(true)
      setshowDownloadBtn(false)
    } else {
      setShowSpinner(true);
    }
  };
  const Donefunction = () => {
    setShowSpinner(false)
    setShowThumbUpIcon(false)
    handleClose()
    setshowDownloadBtn(true)
  };

  return (
    <Modal
      show={show}
      onHide={Donefunction}
      centered
      className={
        storedTheme !== "dark"
          ? "download-data-modal"
          : "download-data-modal-dark"
      }
      data-testid="download-data-modal"
    >
      <Modal.Header
        className="Header"
        style={{ color: storedTheme !== "dark" ? "black" : "white" }}
        closeButton
      >
        <Modal.Title
          className="headerTitle"
          data-testid="Downloading your data leads to your own risk"
        >
          Export {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalBody">
        <p style={{ color: storedTheme !== "dark" ? "black" : "white" }}>
          <b>Note :</b> Download your data at your own risk.
        </p>
        <div className="download_div">
          {showSpinner ? (<Spinner animation="border" className="spinner" />) : ''}
          {showThumbUpIcon ? (<ThumbUpIcon className="thumbUpIcon" />) : ''}
          {showDownloadBtn ? (
            <Button
              variant="primary"
              onClick={() => {
                if (title === 'All Resources')
                  downloadPatinetBundles();
                else
                  downloadSelectedBundle(resourceSelected)
                setShowSpinner(true)
              }}
              className="modal-btn"
              disabled={showSpinner}
            >
              <FileDownloadIcon /> &nbsp; Download {title}
            </Button>) : (
            <Button
              variant="primary"
              onClick={() => {
                Donefunction()
              }}
              className="modal-btn"
            >
              <DownloadDoneIcon /> &nbsp; Done
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default DownloadData;
