import { configureStore } from '@reduxjs/toolkit'
import AuthSlice from '../feature/AuthSlice'
import BundleListSlice from '../feature/BundleListSlice'
import BundleSlice from '../feature/BundleSlice'
import DICSlice from '../feature/DICSlice'
import FilteredTimelineSlice from '../feature/FilteredTimelineSlice'
import filterSlice from '../feature/filterSlice'
import patientReducer from '../feature/patientSlice'
import ResourceSlice from '../feature/ResourceSlice'
import timelineBundleSlice from '../feature/timelineBundleSlice'
import TimelineSlice from '../feature/TimelineSlice'
import UIReducer from '../feature/UISlice'
import HealthDataSlice from '../feature/HealthDataSlice'
import ProfileSlice from '../feature/ProfileSlice'

export const store = configureStore({
    reducer: {
        patient: patientReducer,
        UI: UIReducer,
        auth: AuthSlice,
        resource:ResourceSlice,
        bundle:BundleSlice,
        DIC:DICSlice,
        timeline:TimelineSlice,
        BundleList:BundleListSlice,
        timelineBundle:timelineBundleSlice,
        filteredTimeline:FilteredTimelineSlice,
        filter:filterSlice,
        heathData:HealthDataSlice,
        profile:ProfileSlice
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
