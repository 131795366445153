import React from 'react'
import { Carousel, Row } from 'react-bootstrap'
import homeSlide1 from "../../../images/home_slide_1.png";
import homeSlide2 from "../../../images/home_slide_2.png";
import homeSlide3 from "../../../images/home_slide_3.png";
import homeSlide4 from "../../../images/home_slide_4.png";
import "./LoginSideBar.css";



const LoginSideBar = () => {
  return (
    <div>
      <Carousel controls={false} className="carouselContainer" >
        <Carousel.Item>
          <img
            className="d-block carousel-image"
            src={homeSlide1}
            alt="First slide"
          />
          <Carousel.Caption>
            <h3 className="carousel-header">HealthDataClarity</h3>
            <p className="carousel-description">
              Manage every aspect of your healthcare right here. We’ll
              connect to your insurance payers and providers. Then you can
              view your health history and share it with trusted contacts.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block carousel-image"
            src={homeSlide2}
            alt="Second slide"
          />
          <Carousel.Caption>
            <h3 className="carousel-header">HealthDataClarity</h3>
            <p className="carousel-description">
              Manage every aspect of your healthcare right here. We’ll
              connect to your insurance payers and providers. Then you can
              view your health history and share it with trusted contacts.{" "}
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block carousel-image"
            src={homeSlide3}
            alt="Third slide"
          />
          <Carousel.Caption>
            <h3 className="carousel-header">HealthDataClarity</h3>
            <p className="carousel-description">
              Manage every aspect of your healthcare right here. We’ll
              connect to your insurance payers and providers. Then you can
              view your health history and share it with trusted contacts.{" "}
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block  carousel-image"
            src={homeSlide4}
            alt="Fourth slide"
          />
          <Carousel.Caption>
            <h3 className="carousel-header">HealthDataClarity</h3>
            <p className="carousel-description">
              Manage every aspect of your healthcare right here. We’ll
              connect to your insurance payers and providers. Then you can
              view your health history and share it with trusted contacts.{" "}
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  )
}

export default LoginSideBar
