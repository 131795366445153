
export const Initial_LandindPageChanges = {
    "nav_bar" : false,
    "search_nav" : false,
    "obtained_Payer_List" : [],
    "show_new_Payers" : false,
    "show_dashboard_content" : "Loading Health Plans...",
    "card_view" : false,
    "card_view_text" : "Payer View",
    "searchParameter" : "",
    "obtained_Plan_List" : [],
    
}

export const initial_search_change = {
    "filteredResults" : [],
    "search_input" : "",
}

export const Initial_ProfilePage_changes = {
    'showRawData' : false,
    'showBundle' : false,
    'isLoading' : true,
    'resourceData' : {entry:[] }|| null
}

export const error_handling_change = {
    "isError" : false,
}

export const Initial_MyHealthDataPage_changes = {
    'isLoading' : true,
    "isError" : false,
}

export const StepperVar = [
    {
        label: "Step 1 : Choose Your New Health Plan",
        imgPath:
            "https://p2pgetmydatastorage.blob.core.windows.net/mmhdscreenshots/SS1.jpg"
    },
    {
        label: "Step 2 : Confirm your personal Information",
        imgPath:
            "https://p2pgetmydatastorage.blob.core.windows.net/mmhdscreenshots/SS2.jpg"
    },
    {
        label: "Step 3 : Choose Your Old Health Plan",
        imgPath:
            "https://p2pgetmydatastorage.blob.core.windows.net/mmhdscreenshots/SS3.jpg"
    },
    {
        label: "Done : Move Your Health Data",
        imgPath:
            "https://p2pgetmydatastorage.blob.core.windows.net/mmhdscreenshots/SS4.png"
    }
];

export const LogoCarouselImages = [
    "Humana/CHA_HMO_INC..png",
    "Humana/EMPHESYS_INSURANCE_COMPANY.png",
    "CVS_Aetna/AETNA_HEALTH_INC._FL_crop.png",
    "UHC_Optum/CARE_IMPROVEMENT_PLUS_SOUTH_CENTRAL_INSURANCE_CO._nfqhy9X.png",
    "Anthem/FREEDOM_HEALTH_INC..png",
    "Humana/COMPBENEFITS_INSURANCE_COMPANY_crop.png",
    "Anthem/HEALTHKEEPERS_INC..png",
    "Anthem/MMM_HEALTHCARE_LLC_AvJJee0.png",
    "UHC_Optum/ROCKY_MOUNTAIN_HEALTH_MAINTENANCE_ORGANIZATION_INC.png",
    "CVS_Aetna/COVENTRY_HEALTH_CARE_OF_KANSAS_INC..png"
]