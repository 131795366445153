
import appLogo from "../../images/app-logo.svg";
import AppLayout from "../Layout/AppLayout";

import "./Loading.css";

const Loading = () => (
  <AppLayout>
  <div id="app-loader">
    <img src={appLogo} alt="logo" className="animated-logo" />
  </div>
  </AppLayout>
);

export default Loading;
