import { SetStateAction, Dispatch } from "react";
import { fetchPrivacyPolicy } from "../../apis/apis";
import { useNavigate } from "react-router";

export const GotoPreviousPage=()=>{
    const navigate=useNavigate()
    navigate(-1)
}

export const fetchprivacyandpolicy = async (setTnc: { (value: SetStateAction<string>): void; (arg0: string): void; }, setShowTnc: Dispatch<SetStateAction<boolean>>, setShowSpinner: Dispatch<SetStateAction<boolean>>) => {

    try {
        const res = await fetchPrivacyPolicy();
        setTnc(res.data);
        responsestatus(res.status, setShowTnc, setShowSpinner);
    }
    catch (err) {
        setTnc("Some Error occured while loading data. Please try again after some time.")
        responsestatus(500, setShowTnc, setShowSpinner);
    }
}
export const responsestatus = (res: number, setShowTnc: (arg0: boolean) => void, setShowSpinner: (arg0: boolean) => void) => {
    setShowTnc(true)
    setShowSpinner(false)

}