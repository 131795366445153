import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TimelineResource } from '../../constants/constants'

export interface BundleList {
    selectedBundleList: TimelineResource[]
}

const initialState: BundleList = {
    selectedBundleList:[]
}

export const BundleListSlice = createSlice({
    name: 'BundleList',
    initialState: initialState,
    reducers: {
        updateselectedBundleList: (state, action: PayloadAction<TimelineResource>) => {
            state.selectedBundleList.push(action.payload);
            sessionStorage.setItem("ListResource", JSON.stringify(state.selectedBundleList))
        },
        resetBundleList: () => initialState
    },
})

export const BundleListActions = BundleListSlice.actions
export default BundleListSlice.reducer
