import React, { ReactNode, useEffect, useState } from 'react'

// Material Components
import { Button, makeStyles, Theme, Typography } from '@material-ui/core'
import ErrorOutline from '@mui/icons-material/ErrorOutline';

// Custom Components
import BaseModal, { BaseModalProps } from '../BaseModal'
import { useAppDispatch } from '../../../redux/app/hooks'
import { useNavigate } from 'react-router'
import { authActions } from '../../../redux/feature/AuthSlice'
import { deleteAllCookies } from '../../../utils/utils'
import { patientActions } from '../../../redux/feature/patientSlice'
import { resourceActions } from '../../../redux/feature/ResourceSlice'
import { bundleActions } from '../../../redux/feature/BundleSlice'
import { timelineActions } from '../../../redux/feature/TimelineSlice'
import { BundleListActions } from '../../../redux/feature/BundleListSlice'
import { timelineBundleActions } from '../../../redux/feature/timelineBundleSlice'
import { HealthDataActions } from '../../../redux/feature/HealthDataSlice';
import { ProfileActions } from '../../../redux/feature/ProfileSlice';


export interface FailureModalProps extends Omit<BaseModalProps, "children"> {
    children?: ReactNode;
}


const useStyles = makeStyles((theme: Theme) => ({
    modal: {
        backgroundColor: "white",
        boxShadow: '10px 0px 25px rgba(21, 21, 21, 0.05)'
    },
    modalDark: {
        backgroundColor: '#424949',
        boxShadow: ' 0px 4px 4px rgb(0 0 0 / 25%)'
    },
    modalContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
    },
    icon: {
        width: `${theme.typography.pxToRem(80)} !important`,
        height: `${theme.typography.pxToRem(80)} !important`,
        color: theme.palette.error.main,
    },
    heading: {
        marginTop: '5px',
        color: theme.palette.error.main,
    },
    body: {
        marginTop: '1.25rem',
        color: 'black',
    },
    bodyDark: {
        marginTop: '1.25rem',
        color: 'white',
    },
    caption: {
        color: 'slategray',
        marginTop: '2.5rem',
        width: '90%',
    },
    captionDark: {
        color: 'lightgray',
        marginTop: '2.5rem',
        width: '90%',
    },
    error_btn: {
        backgroundColor: 'red',
        color: 'white',
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(18),
        marginTop: '1rem',
        width: '90%',
        '&:hover': {
            backgroundColor: theme.palette.error.main,
        },
    },
    btnSecText: {
        textTransform: 'lowercase',
    },
    [theme.breakpoints.up('sm')]: {
        icon: {
            width: theme.typography.pxToRem(80),
            height: theme.typography.pxToRem(80),
        },
        heading: {
            fontSize: theme.typography.pxToRem(30),
        },
        body: {
            fontSize: theme.typography.pxToRem(14),
        },
        bodyDark: {
            fontSize: theme.typography.pxToRem(14),
        }
    },
}))

const ErrorModal: React.FC<FailureModalProps> = ({
    open,
    onClose,
    children,
    ...props
}) => {
    const classes = useStyles()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [remainingSeconds, setRemainingSeconds] = useState(15)

    const storedTheme = sessionStorage.getItem("theme");
    const handleLogOut = () => {
        sessionStorage.clear()
        localStorage.clear()
        dispatch(resourceActions.resetResource())
        dispatch(authActions.resetAuth())
        dispatch(patientActions.resetPatientData())
        dispatch(bundleActions.resetBundle())
        dispatch(timelineActions.resetTimeline())
        dispatch(BundleListActions.resetBundleList())
        dispatch(timelineBundleActions.resetTimeline())
        dispatch(HealthDataActions.resetHealthData())
        dispatch(ProfileActions.resetProfile())
        deleteAllCookies()
        navigate('/')
    }
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let intervalId: any = 0
        if (open) {
            intervalId = setInterval(() => {
                setRemainingSeconds((prevState) => (prevState -= 1))
                if (remainingSeconds === 1) {
                    handleLogOut()
                }
            }, 1000)
        } else if (!open && remainingSeconds !== 0 && intervalId !== null) {
            clearInterval(intervalId)
        }
        return () => clearInterval(intervalId)
    }, [remainingSeconds, open])

    return (
        <BaseModal open={open} onClose={onClose} {...props} modalBodyClasses={storedTheme != 'dark' ? classes.modal : classes.modalDark}>
            <div className={classes.modalContainer}>
                <ErrorOutline className={classes.icon} />
                <Typography
                    component="h2"
                    variant="h1"
                    className={classes.heading}
                >
                    Something went wrong
                </Typography>
                <Typography
                    component="p"
                    variant="body1"
                    className={storedTheme != 'dark' ? classes.body : classes.bodyDark}
                >
                    {children}
                </Typography>
                <Typography
                    component="p"
                    variant="body2"
                    className={storedTheme != 'dark' ? classes.caption : classes.captionDark}
                >
                    You will be automatically logged out in{' '}
                    {remainingSeconds.toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                    })}{' '}
                    seconds. To Logout immediately, click the button below.
                </Typography>
                <Button
                    className={classes.error_btn}
                    onClick={handleLogOut}
                    color="secondary"
                >
                    log me out (
                    {remainingSeconds.toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                    })}
                    <span className={classes.btnSecText}>s</span>)
                </Button>
            </div>
        </BaseModal>
    )
}
export default ErrorModal
