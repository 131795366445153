import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { useEffect } from "react";
import '../../../pages/LandingPage/Landing_darktheme.scss'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
    {
        label: " 1 : Choose Your Health Plan",
        imgPath:
            "https://p2pgetmydatastorage.blob.core.windows.net/hdc-screenshots/Screen_1.jpeg"
    },
    {
        label: " 2 : Select the resource to view your data ",
        imgPath:
            "https://p2pgetmydatastorage.blob.core.windows.net/hdc-screenshots/healthdata.jpeg"
    },
    {
        label: " 3 : Confirm your personal information",
        imgPath:
            "https://p2pgetmydatastorage.blob.core.windows.net/hdc-screenshots/profile.jpeg"
    },
    {
        label: " 4 : Digital Insurance Card ",
        imgPath:
            "https://p2pgetmydatastorage.blob.core.windows.net/hdc-screenshots/DIC.png"
    },
    {
        label: " 5 : Timeline View ",
        imgPath:
            "https://p2pgetmydatastorage.blob.core.windows.net/hdc-screenshots/timeline.jpeg"
    }
];
//@ts-ignore
function SwipeableTextMobileStepper({ setCloseDisable, storedTheme }) {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };
    useEffect(() => {
        // console.log('max', maxSteps, activeStep);

        if (activeStep == 3) {
            setCloseDisable(false)
        }
    }, [activeStep])


    return (
        <Box sx={{ flexGrow: 1 }} className={storedTheme != 'dark' ? 'three_step_modal_wrapper' : 'three_step_modal_wrapper_dark'}>
            <Paper
                square
                elevation={0}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    height: 50,
                    pl: 2,
                    bgcolor: "background.default"
                }}
            >
                <Typography className="step_label">{images[activeStep].label}</Typography>
            </Paper>
            <AutoPlaySwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {images.map((step, index) => (
                    <div key={step.label}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <Box
                                component="img"
                                sx={{
                                    display: "block",
                                    overflow: "hidden",
                                    width: "100%",
                                    objectFit: 'contain',
                                }}
                                className="modal_step_image"
                                src={step.imgPath}
                                alt={step.label}
                            />
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                        Next
                        {theme.direction === "rtl" ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === "rtl" ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        Back
                    </Button>
                }
            />
        </Box>
    );
}

export default SwipeableTextMobileStepper;
