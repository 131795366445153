import { useEffect, useState } from "react";
import { C4DIC_Coverage, C4DIC_Organization, C4DIC_Patient, confirmPatientDetails, getPatientId } from "../../apis/apis";
import AppLayout from "../../components/Layout/AppLayout";
import Loading from "../../components/Loading/Loading";
import DIC_ClientLogo from "../../components/Logo/DicClientLogo";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { patientActions } from "../../redux/feature/patientSlice";
import "./DigitalInsuranceCard.css"
import _ from "lodash";
import { Button, Container, Row, Col } from "react-bootstrap";
import FlipIcon from '@mui/icons-material/Flip';
import { appInsights } from '../../AppInsights/Appinsights'
import InfoCard from "../../components/InfoCard/InfoCard";
import ErrorModal from "../../components/Modals/ErrorModal/ErrorModal";

function DICPage(): JSX.Element {
    const storedTheme = sessionStorage.getItem("theme");
    const member = useAppSelector((state) => state.patient)
    const client_local = localStorage.getItem("client")
    const PayorId = localStorage.getItem("H_no")
    const phone = localStorage.getItem("phone_number")
    const address = localStorage.getItem("address")
    const auth = useAppSelector((state) => state.auth)
    const dispatch = useAppDispatch()
    const [patientId, setPatientId] = useState("")
    const [givenName, setGivenName] = useState("")
    const [lastName, setLastName] = useState("")
    const [dob, setdob] = useState("")
    const [gender, setGender] = useState("")
    const [patientAddress, setPatientAddress] = useState("")
    const [patientPhone, setPatientPhone] = useState("")
    const [payor, setPayor] = useState("")
    // const [payorReference, setPayorReference] = useState("")
    const [payorId, setPayorId] = useState("")
    const [patientInfoPhone, setPatientInfoPhone] = useState("")
    const [payorAddress, setPayorAddress] = useState("")
    const [payorName, setPayorName] = useState("")
    const [isLoading, setIsLoading] = useState(true);
    const [showCard, setShowCard] = useState(false);
    const [showIgDetails, setshowIgDetails] = useState(false)
    const [isError, setIsError] = useState(false);
    const responsestatus = (res: number) => {
        if (res === 200) {
            setShowCard(true)
            setIsLoading(false)
        } else {
            setShowCard(false)
            setIsLoading(false)
            setIsError(true)
        }
    }

    const getPatient_Id = async () => {
        try {
            const token = sessionStorage.getItem("auth_token");
            const patient_ID = await getPatientId(client_local, token as string)
            console.log(patient_ID);
            const res = patient_ID.data
            setPatientId(res)
            return res
        } catch (error) {
            console.log(error);

        }
    }

    const patient = async (res: any) => {
        console.log(res);
        appInsights.trackEvent({ name: `Fetch the DIC details from there IG's` })
        appInsights.trackEvent({ name: "Fetching... " });
        console.log(patientId);

        try {
            const patient = await C4DIC_Patient(res, client_local)
            const coverage = await C4DIC_Coverage(client_local)
            if (patient.status !== 200 && coverage.status !== 200) {
                console.log('error')
                throw Error('Error while fetching data')
            }
            const patientData = await patient.data
            const coverageData = await coverage.data

            // PATIENT DETAILS
            let [givenName, lastName, dob, gender, patientAddress, patientPhone] = Array(6).fill("")

            for (let i = 0; i < patientData['name'][0]['given'].length; i++) {
                givenName = givenName + patientData['name'][0]['given'][i] + " "
            }
            setGivenName(givenName)

            lastName = patientData['name'][0]['family']
            setLastName(lastName)

            dob = patientData['birthDate']
            setdob(dob)

            gender = patientData['gender']
            setGender(gender)

            for (let i = 0; i < patientData['address'][0]['line'].length; i++) {
                patientAddress = patientAddress + patientData['address'][0]['line'][i] + ", "
            }
            patientAddress = patientAddress + patientData['address'][0]['city'] + ", " + patientData['address'][0]['state'] + " " + patientData['address'][0]['postalCode']
            setPatientAddress(patientAddress)

            patientPhone = patientData['telecom'][0]['value']
            setPatientPhone(patientPhone)


            // COVERAGE DETAILS
            let [payor, payorReference] = Array(1).fill("")
            for (let i = 0; i < coverageData['entry'].length; i++) {
                let e = coverageData['entry'][i]['resource']
                let x = JSON.stringify(e)

                if (x.includes(patientId)) {
                    payor = e['identifier'][0]['assigner']['display']
                    setPayor(payor)
                    payorReference = e['payor'][0]['reference']
                }
                else {
                    payor = "Payor not found!"
                }
            }


            //ORGANIZATION DETAILS
            try {
                const payor = await C4DIC_Organization(payorReference, client_local)
                if (payor.status !== 200) {
                    console.log('error')
                    throw Error('Error while fetching data')
                }
                const payorData = await payor.data
                responsestatus(patient.status)
                let payor_name = payorData['name']
                setPayorName(payor_name)

                let payorId = payorData['identifier'][0]['value']
                setPayorId(payorId)
                let patientInfoPhone = ""
                let payorAddress = ""

                for (let i = 0; i < payorData['contact'].length; i++) {
                    let contact = payorData['contact'][i]

                    if (contact['purpose']['coding'][0]['code'] == "PATINF") {
                        for (let j = 0; j < contact['telecom'].length; j++) {
                            if (contact['telecom'][j]['system'] == "phone") {
                                patientInfoPhone = contact['telecom'][j]['value']
                                setPatientInfoPhone(patientInfoPhone)
                            }
                        }
                    }

                    if (contact['purpose']['coding'][0]['code'] == "PAYOR") {
                        for (let j = 0; j < contact['address']['line'].length; j++) {
                            payorAddress = payorAddress + contact['address']['line'][j] + " "
                        }
                        payorAddress = "(TYPE: " + contact['address']['type'] + ") " + payorAddress + ", " + contact['address']['city'] + ", " + contact['address']['state'] + ", " + contact['address']['postalCode']
                        setPayorAddress(payorAddress)
                    }
                }
            }
            catch (error) {
                console.log(error)
            }

            // dispatch(DICActions.updateGivenName(givenName))
            // dispatch(DICActions.updateLastName(patientData['name'][0]['family']))
            // dispatch(DICActions.updateDob(patientData['birthDate']))
            // dispatch(DICActions.updateGender(patientData['gender']))
            // dispatch(DICActions.updatePatientAddress(patientAddress))
            // dispatch(DICActions.updatePatientPhone(patientPhone))
            setshowIgDetails(true)
        }
        catch (error) {
            console.log(error)
            try {
                fetch_patient_details()
            }
            catch (error) {
                responsestatus(500)
            }
        }
    }

    const fetch_patient_details = async () => {
        console.log("enter fetchpatinetdetails");
        appInsights.trackEvent({ name: `Fetch DIC Patient Details API called for ${client_local} ’s IDP.` })
        appInsights.trackEvent({ name: "DIC Patient Details " });
        const FILL_DETAILS_URL = `${process.env.REACT_APP_API_CLARITY}/filldetails/`;
        try {
            const res = await confirmPatientDetails(client_local, auth.authToken as string)
            const data = await res.data
            appInsights.trackEvent({
                name: `DIC patient data URL: ${FILL_DETAILS_URL}`,
            });
            if (res.status === 404) {
                console.log("No patient Data Found")
                responsestatus(res.status);
                appInsights.trackEvent({ name: "No patient Data found while fetching data" })
                throw 'no patient data'
            }
            let [id, firstName, LastName, fullName, date, finalDate, gender, line] = Array(7).fill("");
            if (data["entry"][0]["resource"]["resourceType"] === "Patient") {
                if (_.has(data, "entry[0].resource")) {
                    if (_.has(data, "entry[0].resource.name")) {
                        firstName = _.has(data, "entry[0].resource.name[0].given")
                            ? data["entry"][0]["resource"]["name"][0]["given"]
                            : "";

                        LastName = _.has(data, "entry[0].resource.name[0].family")
                            ? data["entry"][0]["resource"]["name"][0]["family"]
                            : "";

                        fullName = firstName + " " + LastName;
                    }
                    id = _.has(data, "entry[0].resource.id") ? data["entry"][0]["resource"]["id"] : "";
                    date = _.has(data, "entry[0].resource.birthDate")
                        ? data["entry"][0]["resource"]["birthDate"]
                        : "";
                    if (date !== "") {
                        const day = date.substr(8, 2);
                        const month = date.substr(5, 2);
                        const year = date.substr(0, 4);
                        finalDate = day + "/" + month + "/" + year;
                    }
                    gender = _.has(data, "entry[0].resource.gender")
                        ? data["entry"][0]["resource"]["gender"]
                        : "";
                    if (_.has(data, "entry[0].resource.address")) {
                        const line_1 = data.entry[0].resource.address[0].line ?? ""
                        const city = data["entry"][0]["resource"]["address"][0]["city"] ?? ""
                        const district = data["entry"][0]["resource"]["address"][0]["district"] ?? ""
                        const state = data["entry"][0]["resource"]["address"][0]["state"] ?? ""
                        const postalCode = data["entry"][0]["resource"]["address"][0]["postalCode"] ?? ""
                        const country = data["entry"][0]["resource"]["address"][0]["country"] ?? ""
                        const address = [line_1, city, district, state, postalCode, country].filter(item => Boolean(item)).join(",")
                        line = data["entry"][0]["resource"]["address"][0]["text"] ?? address
                        dispatch(patientActions.updateAddress(line))
                    }
                    else {
                        dispatch(patientActions.updateAddress("Address not found"))
                    }
                    if (_.has(data, "entry[0].resource.telecom")) {
                        for (const telecom of data["entry"][0]["resource"]["telecom"]) {
                            if (telecom?.system === 'email') {
                                const email = telecom?.value ?? "Email not found"
                                dispatch(patientActions.updateEmail(email))
                            } else if (telecom?.system === 'phone') {
                                const phone = telecom?.value ?? " Phone Number not found"
                                dispatch(
                                    patientActions.updatePhoneNumber(phone)
                                )
                            }
                        }
                    }
                    else {
                        dispatch(patientActions.updatePhoneNumber("Phone Number not found "))
                        dispatch(patientActions.updateEmail("Email not found"))
                    }
                    if (_.has(data, "entry[0].resource.id")) {
                        try {
                            const fhirId = _.has(data, "entry[0].resource.id")
                                ? data["entry"][0]["resource"]["id"]
                                : "";

                            localStorage.setItem("newpayerfhirId", fhirId);
                        } catch (error) {
                            console.log(error);
                            console.log("newpayerfhirId not present");
                        }
                    }

                    dispatch(patientActions.updateFirstName(firstName))
                    dispatch(patientActions.updateLastName(LastName))
                    dispatch(patientActions.updateDob(finalDate))
                    dispatch(patientActions.updateGender(gender))
                    dispatch(patientActions.updateId(id))
                    responsestatus(res.status)
                }
            }
            else {
                console.log("No patient Data Found")
                responsestatus(res.status);
                appInsights.trackEvent({ name: "No patient Data found while fetching data" })
            }
        }
        catch (error) {
            console.log(error)
            responsestatus(500)
            appInsights.trackException({
                exception: Error(
                    `Failed to fetch patient data URL: ${FILL_DETAILS_URL}`
                ),
            });
        }
    }

    useEffect(() => {
        const dataRes = async () => {
            try {
                const res = await getPatient_Id();
                await patient(res)
            } catch (error) {
                console.log(error);
            }
        }
        dataRes()
        // fetch_patient_details()
    }, []);
    const handleErrorModalClose = (
        event: Record<string, unknown>,
        reason: 'backdropClick' | 'escapeKeyDown'
    ) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return
        }
        setIsError(false)
    }

    if (isLoading) {
        return <Loading />;
    }


    const flip = () => {
        const card = document.getElementById('card');
        try {
            //@ts-ignore
            card.classList.toggle('flipped');
        } catch (error) {
            console.log(error);

        }
    }


    return (
        <AppLayout subHeading="Digital Insurance Card" >
            <Container id={storedTheme !== 'dark' ? 'DIC_Page' : 'DIC_page-dark'}>
                <Row className="info_card">
                    {showIgDetails ?
                        <InfoCard>
                            Digital Insurance card details are from C4DIC IG
                        </InfoCard> :
                        <InfoCard>
                            Digital Insurance card details are from Patient resource
                        </InfoCard>}
                </Row>
                {showCard ? (
                    <Container id={storedTheme != 'dark' ? 'DIC_container' : 'DIC_container-dark'}>
                        {/* <input type="checkbox" id="flipCard" /> */}
                        <div className="cardContainer">
                            <div className="digitalCard" id='card'>
                                <div className="cardFront">
                                    <div className="payorLogo">
                                        <DIC_ClientLogo />
                                        {showIgDetails ? <>
                                            <h3 className="payorMainHead">{payorName}</h3>
                                        </> : <><h3 className="payorMainHead">{client_local}</h3></>}
                                    </div>

                                    <div className="cardDetails">
                                        {showIgDetails ? <><p className="p">ID: {patientId}</p><p className="p">NAME: {givenName} {lastName}</p><p className="p">DATE OF BIRTH: {dob}</p><p className="p">GENDER: {gender}</p></> :

                                            <><p className="p">ID: {member.id}</p><p className="p">NAME: {member.firstName} {member.lastName}</p><p className="p">DATE OF BIRTH: {member.dateOfBirth}</p><p className="p">GENDER: {member.gender}</p></>
                                        }
                                    </div>

                                    <div className="footnote">
                                        <p className="p">DIGITAL HEALTH INSURANCE CARD<br />A product by ONYX - SAFHIR</p>
                                    </div>
                                </div>

                                <div className="cardBack">
                                    <div className="cardDetails">
                                        {showIgDetails ?
                                            <><p className="address">ADDRESS: {patientAddress}</p><p className="p">PHONE: {patientPhone}</p></> :
                                            <><p className="address">ADDRESS: {member?.address}</p><p className="p">PHONE: {member?.phoneNumber}</p></>}
                                        <br /><hr />
                                        <h4 className="payorHeading">About payor</h4>
                                        {showIgDetails ?
                                            <><p className="p">ID: {payorId}</p><p className="p">ADDRESS: {payorAddress}</p><p className="p">PHONE: {patientInfoPhone}</p></> :
                                            <><p className="p">ID: {PayorId}</p><p className="p">ADDRESS: {address}</p><p className="p">PHONE: {phone}</p></>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <OverlayTrigger placement='bottom' overlay={<Tooltip>
                        Flip
                    </Tooltip>} > */}
                        <Col>
                            <Button
                                className="FlipBtn"
                                id="flip"
                                onClick={flip}
                                variant="text"
                            >
                                <FlipIcon style={{ "color": storedTheme != 'dark' ? 'black' : 'white' }} fontSize="large" />
                                Click Here to Flip
                            </Button>
                        </Col>
                        {/* </OverlayTrigger> */}
                    </Container>
                ) : null}
                <ErrorModal open={isError} onClose={handleErrorModalClose}>
                    An error occurred while trying to retrieve your healthcare
                    data. Please try again after some time or contact our{' '}
                    <a>support</a>.
                </ErrorModal>
            </Container>
        </AppLayout>
    );
};

export default DICPage;
