import { CLAIMS_AND_CLINICAL_RESOURCE } from '../../constants/constants';
import ClaimsIcon from '../../images/ClaimsIcon';
import InsuranceIcon from '../../images/InsuranceIcon';
import PatientIcon from '../../images/PatientIcon';
import AllergyIcon from '../../images/AllergyIcon';
import CarePlanIcon from '../../images/CarePlanIcon';
import ConditionsIcon from '../../images/ConditionsIcon';
import DeviceIcon from '../../images/DeviceIcon';
import CareTeamIcon from '../../images/CareTeamIcon';
import DiagnosticIcon from '../../images/DiagnosticIcon';
import DocumentIcon from '../../images/DocumentIcon';
import EnocunterIcon from '../../images/EnocunterIcon';
import HealthCareIcon from '../../images/HealthCareIcon';
import ImmunisationIcon from '../../images/ImmunisationIcon';
import AffiliationIcon from '../../images/AffiliationIcon';
import KnowledgeIcon from '../../images/KnowledgeIcon';
import ListIcon from '../../images/ListIcon';
import LocationIcon from '../../images/LocationIcon';
import MedicationsIcon from '../../images/MedicationsIcon';
import MedicationDispenseIcon from '../../images/MedicationDispenseIcon';
import MedicationRequest from '../../images/MedicationRequest';
import OrganizationIcon from '../../images/OrganizationIcon';
import ObservationIcon from '../../images/ObservationIcon';
import GoalIcon from '../../images/GoalIcon';
import PrescriptionIcon from '../../images/PrescriptionIcon';
import ProvenanceIcon from '../../images/ProvenanceIcon';
import ProceduresIcon from '../../images/ProceduresIcon';
import PractitionerIcon from '../../images/PractitionerIcon';
import RoleIcon from '../../images/RoleIcon';
import BasicIcon from '../../images/info';
interface GetResourceImageProps {
  resourceName?: string
  className?: string
  "data-testid"?: string
}
function GetResourceImage({ resourceName }: GetResourceImageProps): JSX.Element {
  const storedTheme = sessionStorage.getItem("theme");
  switch (resourceName) {
    case CLAIMS_AND_CLINICAL_RESOURCE.allergy:
      return <AllergyIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.affiliation:
      return <AffiliationIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.carePlan:
      return <CarePlanIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.careTeam:
      return <CareTeamIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.claims:
      return <ClaimsIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.condition:
      return <ConditionsIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.coverage:
      return <InsuranceIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.insurancePlan:
      return <InsuranceIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.basic:
      return <BasicIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.device:
      return <DeviceIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.diagnosticReport:
      return <DiagnosticIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.documentReference:
      return <DocumentIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.encounter:
      return <EnocunterIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.goal:
      return <GoalIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.healthcare:
      return <HealthCareIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.immunization:
      return <ImmunisationIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.insurance:
      return <InsuranceIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.knowledge:
      return <KnowledgeIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.list:
      return <ListIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.location:
      return <LocationIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.medication:
      return <MedicationsIcon className={storedTheme !== 'dark' ? '' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.medicationRequest:
      return <MedicationRequest className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.medicationDispense:
      return <MedicationDispenseIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.organization:
      return <OrganizationIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.observation:
      return <ObservationIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.prescription:
      return <PrescriptionIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.provenance:
      return <ProvenanceIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.procedure:
      return <ProceduresIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.practitioner:
      return <PractitionerIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.patient:
      return <PatientIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    case CLAIMS_AND_CLINICAL_RESOURCE.role:
      return <RoleIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />;
    default:
      return <></>;
  }
};

export default GetResourceImage