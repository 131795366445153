import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Card, Col, Image, Row } from 'react-bootstrap';
import './PlanList.scss'
import { useNavigate } from 'react-router';
import { useState } from 'react';
import { handlePostPayer } from '../../functions/LoginPage/LoginPageFuncs';
import ModalLogin3 from '../Modals/LoginPageModals/ModalLogin3';
import ErrorToast from '../Toasts/ErrorToast';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
//@ts-ignore
function GridComponent({ payername, planname, contract_number, card_img, street1, street2, city, state, zipcode, phone }) {
    let history = useNavigate();
    // console.log('result', planname);
    console.log(payername);
    const storedTheme = sessionStorage.getItem("theme");

    const [spinner, setSpinner] = useState(true);
    const [btndisable, setBtndisable] = useState(true);
    const [showToast, setShowToast] = useState(false)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const showSpinnerTrue = () => setSpinner(true)

    const handleCardClick = () => {
        handleShow();
        handlePostPayer(payername, setSpinner, setBtndisable, setShowToast, setShow);
        let address = [street1, street2, city, state, zipcode].filter(item => Boolean(item)).join(",")
        localStorage.setItem('logo', card_img)
        localStorage.setItem('H_no', contract_number)
        if (address === "") {
            localStorage.setItem('address', "Address not found")
        }
        else { localStorage.setItem('address', address) }
        localStorage.setItem('phone_number', phone ?? "Phone number not found")
    }
    return (
        <Grid item xs={12} sm={6} md={3} className='card_item'>
            <Card className={storedTheme != 'dark' ? 'planlist2_card' : 'planlist2_card planlist2_card_dark'} onClick={handleCardClick}>
                <Row>
                    <Col>
                        <Image src={`${card_img}`} alt='client_logo' className='plan2_card_img' />
                    </Col>
                    <Col xs={7} className='planlist2_card_content'>
                        {payername}<br />
                        <b className='card_plan2_name'>{planname}</b><br />
                        {contract_number}

                    </Col>
                </Row>
            </Card>
            <ModalLogin3
                show={show}
                handleClose={handleClose}
                oauth_link={''}
                clientName={payername}
                spinner={spinner}
                btndisable={btndisable}
                showSpinnerTrue={showSpinnerTrue} />
            {showToast &&
                <ErrorToast show={showToast} setShowToast={setShowToast} />
            }
        </Grid>
    )
}
//@ts-ignore
function PlanList2({ filteredItems }) {
    return (
        <Box sx={{ width: '100%' }}>
            <Grid container spacing={1} style={{ 'display': 'flex', 'flexWrap': 'wrap' }}>
                {filteredItems.map((val: any, i: any) => (
                    val.plans.map((plan: any, i2: any) => (
                        <GridComponent payername={val.name} planname={plan.legal_entity_name} contract_number={plan.contract_number} card_img={plan.logo} key={i2} street1={plan.legal_entity_street1} street2={plan.legal_entity_street2} city={plan.legal_entity_city} state={plan.legal_entity_sate_code} zipcode={plan.legal_entity_zipcode} phone={plan.directory_contact_phone} />
                    ))

                ))}

            </Grid>
        </Box>
    );
}
export default PlanList2