import React, { useMemo, useState } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom'
import LoginPage from "./pages/LoginPage/LoginPage";
import LandingPage from "./pages/LandingPage/LandingPage";
import { useAppSelector } from './redux/app/hooks';
import { RouteContext } from './Context/RouteContext';
import { PayerListContext } from './Context/PayerListContext';
import ProfilePage from './pages/Profile/Profile';
import TermsAndCondition from './pages/TermsAndConditions/TermsAndCondition';
import PrivacyAndPolicy from './pages/PrivacyPolicy/PrivacyAndPolicy';
import PrivateRoute from './components/privateRoute/PrivateRoute';
import DICPage from './pages/DigitalInsuranceCard/DigitalInsuranceCard';
import HomeMember from './pages/HomePage/HomeMember';
import HomePayor from './pages/HomePage/HomePayor';
import MyHealthData from './pages/MyHealthData/MyHealthData';
import ResourcePage from './pages/ResourcePage/ResourcePage';
import Timeline_Page from './pages/Timeline/Timeline';

interface ResourceInfo {
  resource: string;
  count: string;
}

function App(): JSX.Element {
  const [isAuth, SetIsAuth] = useState(false);
  const bundleList = useAppSelector((state) => state.BundleList)
  const providerValue16 = useMemo(
    () => ({ isAuth, SetIsAuth }),
    [isAuth, SetIsAuth]
  );
  const [payerlist, Setpayerlist] = useState();

  const providerValue1 = useMemo(
    () => ({ payerlist, Setpayerlist }),
    [payerlist, Setpayerlist]
  );
  var payerist;
  var obtlist;

  const get_payer_list = () => {
    obtlist = (localStorage.getItem('payers_list'))
    if (obtlist === null) {
      obtlist = localStorage.getItem('payers_list');
      payerist = []
    }
    else {
      obtlist = localStorage.getItem('payers_list');
      //@ts-ignore
      payerist = JSON.parse(obtlist);
    }
  }

  get_payer_list()
  //@ts-ignore
  const routeComponents = payerist.map((val) => <Route path={"/" + val.slug} element={<LoginPage clientName={val.name} clientLogo={""} linkTo={"/" + val.slug} />} key={val.id} />);
  let resources = sessionStorage.getItem('ListResource')
  //@ts-ignore
  var listResources = JSON.parse(resources)
  const route_resource = listResources?.map((val: ResourceInfo, id: number) => <Route path={`/dashboard/${val.resource}`} element={<PrivateRoute><ResourcePage resource={val.resource} count={val.count} /></PrivateRoute>} key={id} />)

  return (
    <div className='App'>
      <RouteContext.Provider value={providerValue16}>
        <PayerListContext.Provider value={providerValue1}>
          <Routes>
            <Route path='/' element={<HomeMember />} />
            <Route path='/payor' element={<HomePayor />} />
            <Route path='/landing' element={<LandingPage />} />
            <Route path='/dashboard' element={<PrivateRoute><MyHealthData /></PrivateRoute>} />
            <Route path='/profile' element={<PrivateRoute><ProfilePage /></PrivateRoute>} />
            <Route path='/terms-of-service' element={<TermsAndCondition />} />
            <Route path='/privacy-policy' element={<PrivacyAndPolicy />} />
            <Route path="/timeline" element={<PrivateRoute><Timeline_Page /></PrivateRoute>} />
            <Route path='/digital-insurance-card' element={<PrivateRoute><DICPage /></PrivateRoute>} />

            {routeComponents}
            {route_resource}
          </Routes>
        </PayerListContext.Provider>
      </RouteContext.Provider>
    </div>
  );
}

export default App;
