import React from 'react';
import './header.css'
import Logo from '../Logo/Logo';
import { Box, Grid, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface headerProps {
    LogoImg?: string,
    subHeading?: string
}
const AppHeader = ({ LogoImg, subHeading, ...props }: headerProps): JSX.Element => {
    const storedTheme = sessionStorage.getItem("theme");
    return (
        <Grid container
            sx={{
                justifyContent: 'space-between',
                padding: { xs: '1rem 0 0 4rem', sm: '1rem 1rem 0 4rem', md: '1rem 1rem 0 2rem', lg: '1rem' }
            }} >
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: 'fit-content'
                    }}
                >
                    <ArrowBackIcon
                        sx={{
                            fontSize: '2rem',
                            display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' },
                            color: storedTheme !== 'dark' ? 'black' : 'white'
                        }} />&nbsp;
                    <Typography component="h1"
                        sx={{
                            fontWeight: 500,
                            fontSize: { xs: '1.2rem', sm: '1.2rem', lg: '1.5rem' },
                            fontFamily: 'inter',
                            color: storedTheme !== 'dark' ? 'black' : 'white'
                        }} >
                        HealthDataClarity
                    </Typography>
                </Box>
                <Box
                    sx={{
                        alignItems: 'center',
                        width: 'fit-content',
                        marginLeft: { xs: '2%', md: '18%', lg: '15%' }
                    }}
                >
                    <Typography component="p"
                        sx={{
                            fontWeight: 500,
                            fontSize: { xs: '1rem', sm: '1rem', lg: '1.2rem' },
                            fontFamily: 'inter',
                            color: storedTheme !== 'dark' ? 'black' : 'white'
                        }} >
                        {subHeading}
                    </Typography>
                </Box>
            </Box>
            <Logo />
        </Grid >
    );
};

export default AppHeader;
