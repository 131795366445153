import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "./TimelineList.scss";
import { getItemDisplay } from "../../functions/TimelineList/timelineList";
import { getItemContent } from "../../functions/Timeline/timeline";
import { Grid, Typography } from "@mui/material";


const TimelineList = ({ data, itemClick = (item: any) => { } }: any) => {
  const storedTheme = sessionStorage.getItem("theme");

  const TimelineItem = ({ item, onClick = () => { }, }: any) => {
    return (
      <div className={storedTheme !== 'dark' ? 'timeline-item' : 'timeline-item-dark'} onClick={onClick}>
        <Grid container xs={11} sx={{ alignItems: 'center' }} >
          <Grid item xs={2}>
            <p className={storedTheme !== 'dark' ? 'timeline-date' : 'timeline-date-dark'}>{getItemDisplay(item)}</p>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="body1" sx={{ color: storedTheme !== 'dark' ? 'black' : 'white', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {getItemContent(item.resource)}
            </Typography>
          </Grid>
        </Grid>
        <div
          className="timeline-status-container"
          style={{
            color: storedTheme !== 'dark' ? "#7000FF" : "#CCCBCD",
          }}
        >
          View &nbsp;
          <FontAwesomeIcon icon={faChevronRight} className="timeline-icon" />
        </div>
      </div >
    );
  };

  return (
    <div id="timeline-list">
      {data?.map((item: any, index: any) => (
        <TimelineItem
          key={index}
          item={item}
          onClick={() => {
            itemClick(item);
          }}
        />
      ))}
    </div>
  );
};

export default TimelineList;
