import { useEffect, useState } from "react";
import "./DicClientLogo.scss";

//@ts-ignore
const DIC_ClientLogo = () => {
    const [CliLogo, setCliLogo] = useState('');
    const client_logo = localStorage.getItem("client")
    //@ts-ignore
    var payerist: any;
    var obtlist;

    const get_payer_list = () => {
        obtlist = (localStorage.getItem('payers_list'))
        console.log(obtlist);
        if (obtlist === null) {
            obtlist = localStorage.getItem('payers_list');
            payerist = []
        }
        else {
            obtlist = localStorage.getItem('payers_list');
            //@ts-ignore
            payerist = JSON.parse(obtlist);
        }
    }
    get_payer_list()

    useEffect(() => {
        payerist.forEach((val: any) => {
            if (val.name == client_logo) {
                setCliLogo(val.clientlogo)
            }
        })
    }, []);


    return (
        <div className="DicClientLogo_wrapper">
            <img
                src={`${process.env.REACT_APP_LOGO}/${CliLogo}`}
                alt="logo"
                className="DicClientLogo"
                data-testid='client_logo'
            />
        </div>
    );
};

export default DIC_ClientLogo;
