import { useEffect, useState } from "react";
import "./Logos.scss";
import { Box } from "@mui/material";

const Logo = (): JSX.Element => {
  const [CliLogo, setCliLogo] = useState('');
  const client_logo = localStorage.getItem("client")
  //@ts-ignore
  var payerist: any;
  var obtlist;

  const get_payer_list = () => {
    obtlist = (localStorage.getItem('payers_list'))

    if (obtlist === null) {
      obtlist = localStorage.getItem('payers_list');
      payerist = []
    }
    else {
      obtlist = localStorage.getItem('payers_list');
      //@ts-ignore
      payerist = JSON.parse(obtlist);
    }
  }
  get_payer_list()

  useEffect(() => {
    payerist.forEach((val: any) => {
      if (val.name == client_logo) {
        setCliLogo(val.clientlogo)
      }
    })
  }, []);

  return (
    <Box>
      <img
        src={`${process.env.REACT_APP_LOGO}/${CliLogo}`}
        alt="logo"
        className="clientLogo"
        data-testid='client_logo'
      />
    </Box>
  );
};

export default Logo;
