import clarityLogo from "../../images/app-logo.svg";
import './HDCLogo.scss'
const HealthDataClarityLogo = () => {
    return (
        <div>
            <div className="portal-login-HDC">
                <img
                    src={clarityLogo}
                    alt="clarityLogo"
                    data-testid="HDC_Logo"
                    className='hdc_logo_landing'
                />

                <p className="logo-login-HDC">HealthDataClarity</p>
            </div>
        </div>
    )
}

export default HealthDataClarityLogo;
