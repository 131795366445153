import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface Resource {
    selectedResource?: string
}

const initialState: Resource = {
    selectedResource: '',
  
}

export const resourceSlice = createSlice({
    name: 'resource',
    initialState: initialState,
    reducers: {
        updateSelectedResource: (state, action: PayloadAction<string>) => {
            state.selectedResource = action.payload
        },
        resetResource: () => initialState
    },
})

export const resourceActions = resourceSlice.actions
export default resourceSlice.reducer
