import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface Auth {
    authToken?: string
    // refreshToken?: string
}

const initialState: Auth = {
    authToken: '',
    // refreshToken: '',
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setAuthToken: (state, action: PayloadAction<string>) => {
            state.authToken = action.payload
        },
        // setRefreshToken: (state, action: PayloadAction<string>) => {
        //     state.refreshToken = action.payload
        // },
        resetAuth: () => initialState
    },
})

export const authActions = authSlice.actions
export default authSlice.reducer
