import { ReactNode, useState } from "react";
import { Route, Routes } from "react-router-dom";
import DashboardSideBar from '../SideBar/DashboardSideBar/DashboardSideBar'
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import AppHeader from "../Header/header";
import { Col, Container, Row } from "react-bootstrap";
import './AppLayout.css'
import background_vetor from '../../images/background_vetor.svg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import Logo from "../Logo/Logo";

interface LayoutProps {
  subHeading?: string
  children?: ReactNode
  sideBarChildren?: ReactNode
}
const AppLayout = ({ subHeading, children,
  ...props }: LayoutProps): JSX.Element => {
  const [sideBarCollapsed, setSideBarCollapsed] = useState(isMobile);
  const storedTheme = sessionStorage.getItem("theme");

  const sideBarClass = classNames({
    sideBarCollapsed: sideBarCollapsed,
    sideBarOpen: !sideBarCollapsed,
  });

  return (
    <Container fluid id={storedTheme != 'dark' ? 'app-layout' : 'app-layout-dark'} >
      <div className={storedTheme != 'dark' ? 'background_container' : 'background_container_dark'} style={{ 'backgroundImage': `url(${background_vetor})` }}>
        <AppHeader subHeading={subHeading} />
        <DashboardSideBar
          onSidebartoggle={(state: any) => {
            setSideBarCollapsed(state);
          }}
        />
        {/* <div id="app-main" className={`${sideBarClass}`}> */}
        <Container fluid id="app-main" className={`${sideBarClass}`} >
          {/* <Container id={storedTheme != 'dark' ? 'conatiner-light' : 'container-dark'}> */}
          <Row style={{ marginLeft: '0', marginRight: '0', height: '100% ' }}>
            {children}
          </Row>
        </Container>
        {/* </div > */}
        <div className="bottom_style" />
      </div>
    </Container>
  );
};

export default AppLayout;
