import React, { ReactNode } from 'react'

// Material Components
import { makeStyles, Theme, Typography } from '@material-ui/core'

// Custom Components
import BaseModal, { BaseModalProps } from '../BaseModal'
import IconSpinner from '../../IconSpinner/IconSpinner'

export interface LoadingModalProps extends Omit<BaseModalProps, 'children'> {
    icon?: ReactNode
    heading?: string
    body?: string
}

const useStyles = makeStyles((theme: Theme) => ({
    modal: {
        backgroundColor: "white",
        boxShadow: '10px 0px 25px rgba(21, 21, 21, 0.05)'
    },
    modalDark: {
        backgroundColor: '#424949',
        boxShadow: ' 0px 4px 4px rgb(0 0 0 / 25%)'
    },
    modalContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },

    modalHeading: {
        color: '#1BA098',
        fontSize: '2rem',
        marginTop: '0.625rem',

    },
    modalBody: {
        color: 'black',
        fontWeight: 500,
        marginTop: '1.875rem',
        textAlign: 'center',
        width: '33ch',
    },
    modalBodyDark: {
        color: 'white',
        fontWeight: 500,
        marginTop: '1.875rem',
        textAlign: 'center',
        width: '33ch',
    },
}))

const BaseLoadingModal: React.FC<LoadingModalProps> = ({
    open,
    onClose,
    icon,
    heading,
    body,
    hideBackdrop,
    ...props
}) => {
    const classes = useStyles()
    const storedTheme = sessionStorage.getItem("theme");
    return (
        <BaseModal
            open={open}
            onClose={onClose}
            hideBackdrop={hideBackdrop}
            modalBodyClasses={storedTheme != 'dark' ? classes.modal : classes.modalDark}
            {...props}
        >
            <div className={classes.modalContainer}>
                <IconSpinner icon={icon} />
                <Typography
                    component="h2"
                    variant="h2"
                    className={classes.modalHeading}
                >
                    {heading}
                </Typography>

                <Typography
                    component="p"
                    variant="body1"
                    className={storedTheme != 'dark' ? classes.modalBody : classes.modalBodyDark}
                >
                    {body}
                </Typography>
            </div>
        </BaseModal>
    )
}
export default BaseLoadingModal
