import { useContext } from "react";
import {
  AccordionContext,
  Accordion,
  useAccordionButton,
} from "react-bootstrap";

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretDown } from "@fortawesome/free-solid-svg-icons";

import "./TimelineDetailsCardDropdown.css";

function ContextAwareToggle({ children, eventKey, callback }: any) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div className="toggle-header" onClick={decoratedOnClick}>
      {isCurrentEventKey ? (
        <KeyboardArrowDownIcon className="toggle-icon" />
      ) : (
        <ChevronRightIcon className="toggle-icon" />
      )}

      {children}
    </div>
  );
}

const TimelineDetailsCardDropdown = ({ items, header }: any) => {
  return (
    <Accordion defaultActiveKey="1" id="timeline-details-dropdown-item" alwaysOpen={true}>
      <ContextAwareToggle eventKey="1">
        <span className="header-title">{header}</span>
      </ContextAwareToggle>

      <Accordion.Collapse eventKey="1">
        <div className="dropdown-content">
          {items?.map((x: any, index: any) => (
            <div key={index}>{x}</div>
          ))}
        </div>
      </Accordion.Collapse>
    </Accordion >
  );
};

export default TimelineDetailsCardDropdown;

