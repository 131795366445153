import { formatDatePeriod } from "../../components/util/index";
import TimelineDetailsCardDropdown from "../../components/TimelineDetailsCardDropdown/TimelineDetailsCardDropdown";
import TimelineDetailsCardItem from "../../components/TimelineDetailsCardItem/TimelineDetailsCardItem";
import TimelineDetailsStatus from "../TimelineDetailsCardItem/TimelineStatus";

const EncounterTimelineCard = ({ resource }: any) => {
  const storedTheme = sessionStorage.getItem("theme");
  return (
    <div id="encounter-timeline-card">
      <div className="status-div">
        {resource.clinicalStatus &&
          resource.clinicalStatus.coding &&
          resource.clinicalStatus.coding.length &&
          (resource.clinicalStatus.coding[0].display ||
            resource.clinicalStatus.coding[0].code) && (
            <TimelineDetailsStatus
              label="Clinical Status :"
              value={
                resource.clinicalStatus.coding[0].display ||
                resource.clinicalStatus.coding[0].code
              }
            />
          )}

        {resource.status && resource.status.length > 0 && (
          <TimelineDetailsStatus label="Status :" value={`${resource.status}`} />
        )}
      </div>
      <div className={storedTheme != 'dark' ? 'display-details' : 'display-details-dark'}>
        {resource.class && (
          <TimelineDetailsCardItem
            label="CODE"
            value={`${resource.class.display}`}
          />
        )}
        {resource.type?.coding &&
          resource.type?.coding.length &&
          resource.type?.coding[0].display && (
            <TimelineDetailsCardItem
              label="CODE"
              value={`${resource.type[0]?.coding[0].display}`}
            />
          )}

        {resource.criticality && (
          <TimelineDetailsCardItem
            label="CRITICALITY"
            value={`${resource.criticality}`}
          />
        )}
        {resource.statusReason &&
          resource.statusReason?.coding &&
          resource.statusReason?.coding.length &&
          resource.statusReason?.coding[0].display && (
            <TimelineDetailsCardItem
              label="STATUS REASONING"
              value={`${resource.statusReason?.coding[0].display}`}
            />
          )}
        {resource.code?.coding &&
          resource.code?.coding.length &&
          resource.code?.coding[0].display && (
            <TimelineDetailsCardItem
              label="CODE"
              value={`${resource.code?.coding[0].display}`}
            />
          )}
        {resource.performedDateTime && (
          <TimelineDetailsCardItem
            label="PERFORMED"
            value={formatDatePeriod(resource.performedDateTime)}
          />
        )}
        {resource.search && (
          <TimelineDetailsCardItem label="SEARCH" value={resource.search} />
        )}
      </div>
    </div>
  );
};

export default EncounterTimelineCard;
