import React from 'react'

// React Router components
import { Navigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

// Custom Components
import { authActions } from '../../redux/feature/AuthSlice'
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";

interface PrivateRouteProps {
    children: JSX.Element
}

export default function PrivateRoute({
    children,
}: PrivateRouteProps): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams()
    const dispatch = useAppDispatch()

    const auth = useAppSelector((state) => state.auth)

    if (sessionStorage.getItem('auth_token')) {
        dispatch(
            authActions.setAuthToken(
                sessionStorage.getItem('auth_token') as string
            )
        )
    } else {
        const authToken = searchParams.get('access_token')
        // const refreshToken = searchParams.get('refresh_token')

        if (authToken) {
            sessionStorage.setItem('auth_token', authToken)
            dispatch(authActions.setAuthToken(authToken))
        }
        // if (refreshToken) {
        //     sessionStorage.setItem('refresh_token', refreshToken)
        //     dispatch(authActions.setRefreshToken(refreshToken))
        // }
    }

    return auth.authToken ? children : <Navigate to="/" />
}
