import appLogo from "../../images/app-logo.svg";
import "./Loading.css";

const LodaingResourceView = () => {
    const storedTheme = sessionStorage.getItem("theme");
    return (
        <div className="loading-wrapper">
            <div className="loading-container">
                <img src={appLogo} alt="logo" className="resource-animated-logo" />
                <p style={{ color: storedTheme !== 'dark' ? 'gray' : 'white' }}><b>Please wait while we fetch your data..!</b></p>
            </div>
        </div>

    );
};

export default LodaingResourceView;