import { appInsights } from "../../AppInsights/Appinsights";
import { GetResourceList, confirmPatientDetails } from "../../apis/apis";
import _ from "lodash";
import { patientActions } from "../../redux/feature/patientSlice";
import { AxiosError } from "axios";
import { TimelineResources } from "../../constants/constants";
import { BundleListActions } from "../../redux/feature/BundleListSlice";
import { HealthDataActions } from "../../redux/feature/HealthDataSlice";
import { ProfileActions } from "../../redux/feature/ProfileSlice";
//@ts-ignore
export const Fetch_patient_details = async ( dispatch,client_local,auth) => {
    appInsights.trackEvent({ name: `Fetch Patient Details API called for ${client_local} ’s IDP.` })
    appInsights.trackEvent({ name: "Profile Details " });
    const FILL_DETAILS_URL = `${process.env.REACT_APP_API_CLARITY}/filldetails/`;
    try {
      const res = await confirmPatientDetails(client_local, auth.authToken as string)
      const data = await res.data
      appInsights.trackEvent({
        name: `Profile data URL: ${FILL_DETAILS_URL}`,
      });
      dispatch(ProfileActions.updateResourceData(res.data))
      let [id, firstName, LastName, fullName, date, finalDate, gender, line, city] = Array(8).fill("");
      if (res.status === 204) {
        console.log("No patient Data Found")
        responsestatus(res.status,dispatch);
        appInsights.trackEvent({ name: "No patient Data found while fetching data" })
        throw 'no patient data'
      }

      if (data["entry"][0]["resource"]["resourceType"] === "Patient") {
        if (_.has(data, "entry[0].resource")) {
          if (_.has(data, "entry[0].resource.name")) {
            firstName = _.has(data, "entry[0].resource.name[0].given")
              ? data["entry"][0]["resource"]["name"][0]["given"]
              : "";

            LastName = _.has(data, "entry[0].resource.name[0].family")
              ? data["entry"][0]["resource"]["name"][0]["family"]
              : "";

            fullName = firstName + " " + LastName;
          }
          id = _.has(data, "entry[0].resource.id") ? data["entry"][0]["resource"]["id"] : "";
          date = _.has(data, "entry[0].resource.birthDate")
            ? data["entry"][0]["resource"]["birthDate"]
            : "";
          if (date !== "") {
            const day = date.substr(8, 2);
            const month = date.substr(5, 2);
            const year = date.substr(0, 4);
            finalDate = day + "/" + month + "/" + year;
          }
          gender = _.has(data, "entry[0].resource.gender")
            ? data["entry"][0]["resource"]["gender"]
            : "";

          if (_.has(data, "entry[0].resource.address")) {
            const line_1 = data.entry[0].resource.address[0].line ?? ""
            const city = data["entry"][0]["resource"]["address"][0]["city"] ?? ""
            const district = data["entry"][0]["resource"]["address"][0]["district"] ?? ""
            const state = data["entry"][0]["resource"]["address"][0]["state"] ?? ""
            const postalCode = data["entry"][0]["resource"]["address"][0]["postalCode"] ?? ""
            const country = data["entry"][0]["resource"]["address"][0]["country"] ?? ""
            const address = [line_1, city, district, state, postalCode, country].filter(item => Boolean(item)).join(",")
            line = data["entry"][0]["resource"]["address"][0]["text"] ?? address
            dispatch(patientActions.updateAddress(line))
          }
          else {
            dispatch(patientActions.updateAddress("Address not found"))
          }
          if (_.has(data, "entry[0].resource.telecom")) {
            for (const telecom of data["entry"][0]["resource"]["telecom"]) {
              if (telecom?.system === 'email') {
                const email = telecom?.value ?? "Email not found"
                dispatch(patientActions.updateEmail(email))
              } else if (telecom?.system === 'phone') {
                const phone = telecom?.value ?? " Phone Number not found"
                dispatch(
                  patientActions.updatePhoneNumber(phone)
                )
              }
            }
          }
          else {
            dispatch(patientActions.updatePhoneNumber("Phone Number not found "))
            dispatch(patientActions.updateEmail("Email not found"))
          }
          if (_.has(data, "entry[0].resource.id")) {
            try {
              const fhirId = _.has(data, "entry[0].resource.id")
                ? data["entry"][0]["resource"]["id"]
                : "";

              localStorage.setItem("newpayerfhirId", fhirId);
            } catch (error) {
              console.log(error);
              console.log("newpayerfhirId not present");
            }
          }

          dispatch(patientActions.updateFirstName(firstName))
          dispatch(patientActions.updateLastName(LastName))
          dispatch(patientActions.updateDob(finalDate))
          dispatch(patientActions.updateGender(gender))
          dispatch(patientActions.updateId(id))
          responsestatus(res.status,dispatch)
        }
      }
      else {
        console.log("No patient Data Found")
        responsestatus(res.status,dispatch);
        appInsights.trackEvent({ name: "No patient Data found while fetching data" })
      }
    }
    catch (error) {
      console.log(error)
      responsestatus(500,dispatch)
      appInsights.trackException({
        exception: Error(
          `Failed to fetch patient data URL: ${FILL_DETAILS_URL} `
        ),
      });
    }
  }

//@ts-ignore
  export const responsestatus = (res: number,dispatch) => {
    if (res === 200) {
        dispatch(ProfileActions.setShowBundle(true));
        dispatch(ProfileActions.setprofileLoading(false));

    }
    else {
      dispatch(ProfileActions.setShowBundle(false));
      dispatch(ProfileActions.setprofileLoading(false)); 
      dispatch(ProfileActions.setprofileError(true));
    }
}

//@ts-ignore
export const fetchData = async (dispatch, client_local, auth) => {
  appInsights.trackEvent({ name: `Fetch the patient resources list` });
  appInsights.trackEvent({ name: "Fetching... " });
  const FetchBundle_URL = `${process.env.REACT_APP_API_CLARITY}/api/v1/listresources/`;
  try {
    const resourcelist = await GetResourceList(
      client_local,
      auth.authToken as string
    );
    console.log("Status:", resourcelist.status,dispatch)
    responseStatus(resourcelist.status,dispatch);
    const results = resourcelist.data;
    const processedResults = results.resources.map(
      (resource: any) => resource.type
    );

    // sessionStorage.setItem("ListResource", JSON.stringify(processedResults))
    dispatch(
      // BundleListActions.updateselectedBundleList(
      //   TimelineResources.filter((resource) =>
      //     processedResults.includes(resource.resource)
      //   )
      // )
    );
    appInsights.trackEvent({
      name: `Fetch patient resources bundle URL: ${FetchBundle_URL}`,
    });
  } catch (err) {
    console.log("fetch failed");
    responseStatus(500,dispatch);
    const error = err as AxiosError;
    console.log(error);
    appInsights.trackException({
      exception: Error(
        `Failed to fetch patient resources bundle URL: ${FetchBundle_URL} and error is : ${error}`
      ),
    });
  }
};

//@ts-ignore
export const responseStatus = (res: number,dispatch) => {
  if (res === 200) {
    dispatch(HealthDataActions.setIsLoading(false))

  } else {
  dispatch(HealthDataActions.setIsLoading(false))
  dispatch(HealthDataActions.setIsError(true))
  }
};