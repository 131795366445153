import { Modal, Nav } from "react-bootstrap";
import './RawData.css'
import { useState } from "react";
import renderDetailsContent from "../../functions/HealthDataPage/HealthData";
import GetResourceImage from '../util/Timeline.util';

//@ts-ignore
const RawDataView = ({
  content = '',
  name = '',
  show = false,
  handleClose = () => { },
}) => {
  const storedTheme = sessionStorage.getItem("theme");
  const data = JSON.stringify(content, null, 2)
  const [showRawData, setshowRawData] = useState(false)

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      dialogClassName="modal-90w"
      size="lg"
      scrollable
      className={storedTheme !== 'dark' ? 'view-data-modal' : 'view-data-modal-dark'}
    >
      <Modal.Header className="Header" style={{ 'color': storedTheme !== 'dark' ? 'black' : 'white', 'background': storedTheme !== 'dark' ? '#96B1F6' : 'rgb(67 95 167)' }}>
        <Modal.Title className="headerTitle">
          {/* <img src={getResourceImage(name)} alt="resourceLogo" style={{ 'height': '2.5rem' }} /> */}
          <GetResourceImage resourceName={name} className='modal_icon' />
          &nbsp;{name}</Modal.Title>
        <Nav variant="pills" className="justify-content-end navigation_bar" defaultActiveKey={'info'}>
          <Nav.Item className={storedTheme !== 'dark' ? 'nav_item' : 'nav_item nav_item_dark'}>
            <Nav.Link onClick={() => setshowRawData(false)} eventKey={'info'} className='navText'>Info</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link onClick={() => setshowRawData(true)} eventKey={'raw'} className='navText'>Raw Data</Nav.Link>
          </Nav.Item>
        </Nav>
      </Modal.Header>
      <Modal.Body className="modalBody" style={{ 'height': '500px' }}>
        {showRawData ?
          <div className="rawData" style={{ 'color': storedTheme !== 'dark' ? 'black' : 'white' }}>
            <pre pams-content-load id="json-data">{data}</pre>
          </div> :
          <div className="details-content">{renderDetailsContent(content)}</div>
        }
      </Modal.Body>
    </Modal>
  );
};
export default RawDataView;