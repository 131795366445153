import React from "react"
import { fetchActivePayerList, fetchHealthPlanList } from "../../apis/apis"
import { Initial_LandindPageChanges } from "../../constants/PageChanges"
import { UIActions } from "../../redux/feature/UISlice"
import { getPayer } from "../SearchFuncs/SearchFuncs"
//@ts-ignore
export const fetchNewPayers = async (dispatch,setLandingPageVar,LandingPageVar) => {
    try {
        const res = await fetchActivePayerList()
        const AllPlansList = res.data
        LandingPageVar.obtained_Payer_List = res.data
        dispatch(UIActions.updatePayers(AllPlansList))
        handleNewPayersResponse(res.status,setLandingPageVar,LandingPageVar)
        localStorage.setItem("payers_list", JSON.stringify(AllPlansList))
    }
    catch (err) {
        console.log(err)
        handleNewPayersResponse(500,setLandingPageVar,LandingPageVar)
    }
}

//@ts-ignore
export const handleNewPayersResponse = (res: number,setLandingPageVar,LandingPageVar) => {
    if (res === 200) {
        setLandingPageVar({
            ...LandingPageVar,
            show_new_Payers : true,
            show_dashboard_content : ""
        })
    }
    else {
        setLandingPageVar({
            ...LandingPageVar,
            show_new_Payers : true,
            show_dashboard_content : "Error while loading New Payers List"
        })
    }
}

//@ts-ignore
export const getPayerName = (payer_slug) => {
    let payers_list = getPayer()
    let payer_name = ""
    payers_list.map((val: any, i: any) => {

        if (val.slug == payer_slug) {
            payer_name = val.name
        }
    })
    return payer_name
}
//@ts-ignore
export const getPayerLogo = (payer_slug) => {
    let payers_list = getPayer()
    let payerlogo = ""
    payers_list.map((val: any, i: any) => {
        if (val.slug == payer_slug) {
            payerlogo = val.clientlogo
        }
    })
    return payerlogo
}

//@ts-ignore
export const fetchAllPLansList = async (dispatch, setLandingPageVar,LandingPageVar) => {
    try{
        const res = await fetchHealthPlanList()
        const sortedPlansList = res.data.sort((a: { plans: string | any[] }, b: { plans: string | any[] }) => {
            if (a.plans.length < b.plans.length) {
                return 1
            }
            else if (a.plans.length > b.plans.length) {
                return -1
            }
            else {
                return 0
            }
        })
        LandingPageVar.obtained_Plan_List=sortedPlansList
        sessionStorage.setItem("plans_list", JSON.stringify(sortedPlansList))
        console.log(sortedPlansList,"**");
        
        dispatch(UIActions.updatePlansList(sortedPlansList));
}
catch (err) {
    console.log(err)
    handleNewPayersResponse(500,setLandingPageVar,LandingPageVar)
}
}


