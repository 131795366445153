import { Button, CardContent, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import axios from 'axios';
import moment from 'moment';
import './HomePage.scss'

function ContactForm() {
    const [name, setName] = useState("");
    const [organization, setOrganization] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [ip, setIp] = useState("");
    const pageUrl = window.location.href;

    console.log(pageUrl.split("://").pop())
    console.log()



    const getIp = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIp(res.data.IPv4)
    }

    const formSubmitHandler = (e: any) => {
        e.preventDefault();

        setName('');
        setOrganization('');
        setEmail('');
        setPhone('');
        setMessage('');
    };

    useEffect(() => {
        getIp()
        console.log(ip)
    }, [])

    const sendMail = () => {


        const data = {
            "email_body": "<p><b>{{name}}</b> has requested a Demo/Sales/Enquiry through <b>HealthDataClarity</b> website.<br>The senders details are given below.<br><br>Name: {{name}}<br>Organization: {{organization}}<br>Business Email: {{email}}<br>Phone: {{phone}}<br>Message: {{message}}<br><br>Regards,HDC<br><br><br>DISCLAIMER: This mail originated from <a href={{url}}>{{page}}</a> at {{time}} with user IP {{ip}}",

            "vars_dict": {
                "name": name,
                "organization": organization,
                "email": email,
                "phone": phone,
                "message": message,
                "url": pageUrl,
                "page": pageUrl.split("://").pop(),
                "ip": ip,
                "time": moment().format('hh:mm:ss a')
            }
        };

        let options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'from': 'support@safhir.io',
                'to': `${process.env.REACT_APP_CONTACT_TO_MAIL}`,
                'subject': `${process.env.REACT_APP_CONTACT_MAIL_SUBJECT}`,
                'apikey': 'mmhd'
            },
            body: JSON.stringify(data)
        }

        let fetchRes = fetch("https://p2communicationapi.azurewebsites.net/mail/", options);
        fetchRes.then(res => res.json())
            .then(d => {
                console.log(d)
            })
    }

    return (
        <div className="contact-form-container">

            <CardContent>
                <Form method="POST" onSubmit={formSubmitHandler}>
                    <Grid container spacing={1}>
                        <Grid xs={12} item>
                            <TextField className="contact-text-field" label="Name" placeholder="Enter Name" variant="outlined" fullWidth required value={name} onChange={(e) => setName(e.target.value)} />
                        </Grid>

                        <Grid xs={12} item>
                            <TextField className="contact-text-field" label="Organization" placeholder="Enter Organization Name" variant="outlined" fullWidth required value={organization} onChange={(e) => setOrganization(e.target.value)} />
                        </Grid>

                        <Grid xs={12} item>
                            <TextField className="contact-text-field" type="email" label=" Business Email" placeholder="Enter Business Email" variant="outlined" fullWidth required value={email} onChange={(e) => setEmail(e.target.value)} />
                        </Grid>

                        <Grid xs={12} item>
                            <TextField className="contact-text-field" type="numeric" label="Phone" placeholder="Enter Phone Name" variant="outlined" fullWidth value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </Grid>

                        <Grid xs={12} item>
                            <TextField className="contact-text-field" label="Message" placeholder="Enter Message" variant="outlined" multiline rows={3} fullWidth required value={message} onChange={(e) => setMessage(e.target.value)} />
                        </Grid>

                        <Grid xs={12} item>
                            <Button type="submit" variant="contained" color="primary" fullWidth onClick={() => {
                                if (name != "" && organization != "" && email != "" && message != "") {
                                    sendMail();
                                }
                            }}>Submit</Button>
                        </Grid>
                    </Grid>
                </Form>
            </CardContent>

        </div>
    )
}

export default ContactForm;