import { formatDatePeriod } from "../util/index";
import TimelineDetailsCardDropdown from "../TimelineDetailsCardDropdown/TimelineDetailsCardDropdown";
import TimelineDetailsCardItem from "../TimelineDetailsCardItem/TimelineDetailsCardItem";
import './TimelineCard.css'
import TimelineDetailsStatus from "../TimelineDetailsCardItem/TimelineStatus";

const BasicTimelineCard = ({ resource }: any) => {
    const storedTheme = sessionStorage.getItem("theme");
    return (
        <div id="basic-timeline-card">
            <div className="status-div">
                {resource.clinicalStatus &&
                    resource.clinicalStatus.coding &&
                    resource.clinicalStatus.coding.length &&
                    (resource.clinicalStatus.coding[0].display ||
                        resource.clinicalStatus.coding[0].code) && (
                        <TimelineDetailsStatus
                            label="Clinical Status :"
                            value={
                                resource.clinicalStatus.coding[0].display ||
                                resource.clinicalStatus.coding[0].code
                            }
                        />
                    )}

                {resource.status && resource.status.length > 0 && (
                    <TimelineDetailsStatus label="Status :" value={`${resource.status}`} />
                )}
            </div>
            <div className={storedTheme != 'dark' ? 'display-details' : 'display-details-dark'}>

                {resource.code?.coding &&
                    resource.code?.coding.length &&
                    (
                        <TimelineDetailsCardDropdown
                            header="Code"
                            items={resource.code?.coding.map((item: any) => (
                                <TimelineDetailsCardItem label="" value={item.code} />
                            ))}
                        />
                    )}

                {resource?.subject &&
                    (
                        <TimelineDetailsCardItem label="Subject" value={resource.subject.reference} />
                    )}
            </div>
        </div>
    );
};

export default BasicTimelineCard;
