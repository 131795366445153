import { formatDatePeriod } from "../util/index";
import TimelineDetailsCardDropdown from "../../components/TimelineDetailsCardDropdown/TimelineDetailsCardDropdown";
import TimelineDetailsCardItem from "../../components/TimelineDetailsCardItem/TimelineDetailsCardItem";
import './TimelineCard.css'
import TimelineDetailsStatus from "../TimelineDetailsCardItem/TimelineStatus";

const CoverageTimelineCardItem = ({ resource }: any) => {
    const storedTheme = sessionStorage.getItem("theme");
    return (
        <div id="CoverageTimelineCardItem">
            <div className="status-div">
                {resource.status && resource.status.length > 0 && (
                    <TimelineDetailsStatus label="Status : " value={`${resource.status}`} />
                )}
            </div>
            <div className={storedTheme != 'dark' ? 'display-details' : 'display-details-dark'}>
                {/* {resource.class && resource.class[0].name && (
                    <TimelineDetailsCardItem
                        label="GROUP"
                        value={resource.class[0].name}
                    />
                )} */}
                {resource.class && (
                    <TimelineDetailsCardDropdown
                        header="Group"
                        items={resource.class.map((item: any) => (
                            <TimelineDetailsCardItem label="" value={item.name} />
                        ))}
                    />
                )}
                {resource.grouping && (
                    <TimelineDetailsCardItem
                        label="SUB GROUP"
                        value={resource.grouping.subGroup}
                    />
                )}
                {resource.grouping && (
                    <TimelineDetailsCardItem
                        label="SUB PLAN"
                        value={resource.grouping.subPlan}
                    />
                )}
                {resource.period && resource.period.start && (
                    <TimelineDetailsCardItem
                        label=" START PERIOD"
                        value={formatDatePeriod(resource.period.start)}
                    />
                )}
                {resource.period && resource.period.end && (
                    <TimelineDetailsCardItem
                        label="END PERIOD"
                        value={formatDatePeriod(resource.period.end)}
                    />
                )}
                {resource.beneficiary && (
                    <TimelineDetailsCardItem
                        label="Beneficiary"
                        value={resource.beneficiary.reference}
                    />
                )}
            </div>
        </div>
    );
};

export default CoverageTimelineCardItem;
