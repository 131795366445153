import React from 'react';
import './LandingPageFooter.scss'
import { Link } from 'react-router-dom';

const LandingPageFooter = () => {
    const storedTheme = sessionStorage.getItem("theme");
    return <div className={storedTheme != 'dark' ? 'footer_wrapper' : 'footer_wrapper footer_wrapper_dark'}>
        <footer data-testid='footer_landing'>
            <div className='left_end_footer'>
                <p className='left_end_text'>© {process.env.REACT_APP_NAME}</p>
            </div>
            <div className='right_end_footer'>
                <p className='TPLinks_para'>| <Link to='/terms-of-service' className='TPLinks'>Terms Of Service</Link> | <Link to='/privacy-policy' className='TPLinks'>Privacy Policy</Link> |  </p>
            </div>
        </footer>
    </div>;
};

export default LandingPageFooter;
