import { createSlice, PayloadAction } from '@reduxjs/toolkit'
export interface FilteredBundles {
    isFiltered?: boolean
}

const initialState: FilteredBundles = {
    isFiltered:false
}

export const FilteredBundleslice = createSlice({
    name: 'filteredTimeline',
    initialState: initialState,
    reducers: {
        updateFilteredTimeline: (state, action: PayloadAction<boolean>) => {
            state.isFiltered= action.payload
        },
        resetTimeline: () => initialState
    },
})

export const filteredBundlesActions = FilteredBundleslice.actions
export default FilteredBundleslice.reducer
