import { formatDatePeriod } from "../../components/util/index";
import TimelineDetailsCardDropdown from "../../components/TimelineDetailsCardDropdown/TimelineDetailsCardDropdown";
import TimelineDetailsCardItem from "../../components/TimelineDetailsCardItem/TimelineDetailsCardItem";
import TimelineDetailsStatus from "../TimelineDetailsCardItem/TimelineStatus";
import './TimelineCard.css'


const DiagnosticReportTimelineCard = ({ resource }: any) => {
  const storedTheme = sessionStorage.getItem("theme");
  return (
    <div id="diagnostic-report-timeline-card">
      <div className="status-div">
        {resource.clinicalStatus &&
          resource.clinicalStatus.coding &&
          resource.clinicalStatus.coding.length &&
          (resource.clinicalStatus.coding[0].display ||
            resource.clinicalStatus.coding[0].code) && (
            <TimelineDetailsStatus
              label="Clinical Status :"
              value={
                resource.clinicalStatus.coding[0].display ||
                resource.clinicalStatus.coding[0].code
              }
            />
          )}

        {resource.status && resource.status.length > 0 && (
          <TimelineDetailsStatus label="Status :" value={`${resource.status}`} />
        )}
      </div>
      <div className={storedTheme != 'dark' ? 'display-details' : 'display-details-dark'}>
        {resource.code &&
          resource.code.coding &&
          resource.code.coding.length &&
          (resource.code.coding[0].display || resource.code.coding[0].code) && (
            <TimelineDetailsCardItem
              label="CODE"
              value={
                resource.code.coding[0].display || resource.code.coding[0].code
              }
            />
          )}

        {resource.type && (
          <TimelineDetailsCardItem label="Type" value={resource.type} />
        )}

        {!!resource.category?.length &&
          (resource.category
            .filter(
              (item: any) =>
                item.coding && item.coding.length && item.coding[0].display
            )
            ?.map((x: any) => (
              <TimelineDetailsCardItem
                label="CATEGORY"
                value={`${x.coding[0].display}`}
              />

            ))
          )}

        {resource.criticality && (
          <TimelineDetailsCardItem
            label="CRITICALITY"
            value={`${resource.criticality}`}
          />
        )}

        {!!resource.encounter?.length && (
          <TimelineDetailsCardDropdown
            header="Encounter"
            items={resource.encounter.map((item: any) => (
              <TimelineDetailsCardItem label="" value={item.reference} />
            ))}
          />
        )}

        {resource.effectiveDateTime && (
          <TimelineDetailsCardItem
            label="EFFECTIVE DATE"
            value={formatDatePeriod(resource.effectiveDateTime)}
          />
        )}
        {resource.issued && (
          <TimelineDetailsCardItem
            label="ISSUED"
            value={formatDatePeriod(resource.issued)}
          />
        )}
        {resource.recorder &&
          resource.recorder.practitioner &&
          resource.recorder.practitioner.name && (
            <TimelineDetailsCardItem
              label="RECORDER"
              value={resource.recorder.practitioner.name}
            />
          )}

        {!!resource.performer?.length && (
          <TimelineDetailsCardDropdown
            header="PERFORMER"
            items={resource.performer.map((item: any) => (
              <TimelineDetailsCardItem label="Text" value={item?.display || ""} />
            ))}
          />
        )}
      </div>
    </div>
  );
};

export default DiagnosticReportTimelineCard;
