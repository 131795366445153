import * as React from 'react';
import './MenuBar.scss'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { handlePayer } from '../../functions/Cards/CardsFuncs';
import { useNavigate } from 'react-router';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//@ts-ignore
function MenuBar({ obtlist, name }) {
    let navigate = useNavigate()
    const storedTheme = sessionStorage.getItem("theme");
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleRedirectToLogin = (link: string) => {
        navigate(link)
    }
    const handleLogo = (logo: any) => {
        localStorage.setItem('logo', `${process.env.REACT_APP_LOGO}/${logo}`)
    }
    return (
        <div>
            <Button
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className="menuBtn"
            >
                {/* <MenuIcon className='menu_icon' /><span className='login_menu_txt'> Login Pages</span> */}
                <span className='nav_txt'>{name}<ArrowDropDownIcon className='arrow_icon' /></span>
            </Button>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >

                {obtlist.length > 0 ?
                    obtlist.map((val: any, i: any) => (
                        <MenuItem onClick={() => { handlePayer(val.name); handleRedirectToLogin('/' + val.slug); handleLogo(val.clientlogo) }} key={i} className={storedTheme != 'dark' ? "menuBtn_list" : "menuBtn_list menuBtn_list_dark"}>{val.name}</MenuItem>
                    ))
                    : null}
            </Menu>
        </div>
    );
}
export default MenuBar;