import { formatDatePeriod } from "../util/index";
import TimelineDetailsCardDropdown from "../../components/TimelineDetailsCardDropdown/TimelineDetailsCardDropdown";
import TimelineDetailsCardItem from "../../components/TimelineDetailsCardItem/TimelineDetailsCardItem";
import './TimelineCard.css'
import TimelineDetailsStatus from "../TimelineDetailsCardItem/TimelineStatus";

const InsurancePlanCard = ({ resource }: any) => {
    const storedTheme = sessionStorage.getItem("theme");
    return (
        <div id="plan-timeline-card">
            <div className="status-div">
                {resource.clinicalStatus &&
                    resource.clinicalStatus.coding &&
                    resource.clinicalStatus.coding.length &&
                    (resource.clinicalStatus.coding[0].display ||
                        resource.clinicalStatus.coding[0].code) && (
                        <TimelineDetailsStatus
                            label="Clinical Status :"
                            value={
                                resource.clinicalStatus.coding[0].display ||
                                resource.clinicalStatus.coding[0].code
                            }
                        />
                    )}

                {resource.status && resource.status.length > 0 && (
                    <TimelineDetailsStatus label="Status :" value={`${resource.status}`} />
                )}
            </div>
            <div className={storedTheme != 'dark' ? 'display-details' : 'display-details-dark'}>
                {resource.name && (
                    <TimelineDetailsCardItem
                        label="Name"
                        value={resource.name}
                    />
                )}

                {resource.coverage &&
                    resource.coverage?.length &&
                    resource.coverage[0].type &&
                    (
                        <TimelineDetailsCardDropdown
                            header="Coverage Type"
                            items={resource.coverage.map((item: any) => (
                                <TimelineDetailsCardItem label="" value={item?.type.coding[0].code} />
                            ))}
                        />
                    )}

                {resource.plan &&
                    resource.plan?.length &&
                    resource.plan[0].type &&
                    (
                        <TimelineDetailsCardDropdown
                            header="Plan Type"
                            items={resource.plan.map((item: any) => (
                                <TimelineDetailsCardItem label="" value={item?.type.coding[0]?.display} />
                            ))}
                        />
                    )}
            </div>
        </div>
    );
};

export default InsurancePlanCard;
