import { formatDatePeriod } from "../util/index";
import TimelineDetailsCardDropdown from "../TimelineDetailsCardDropdown/TimelineDetailsCardDropdown";
import TimelineDetailsCardItem from "../TimelineDetailsCardItem/TimelineDetailsCardItem";
import TimelineDetailsStatus from "../TimelineDetailsCardItem/TimelineStatus";
import './TimelineCard.css'
const GoalTimelineCard = ({ resource }: any) => {
  const storedTheme = sessionStorage.getItem("theme");
  return (
    <div id="goal-timeline-card">
      <div className="status-div">
        {resource.clinicalStatus &&
          resource.clinicalStatus.coding &&
          resource.clinicalStatus.coding.length &&
          (resource.clinicalStatus.coding[0].display ||
            resource.clinicalStatus.coding[0].code) && (
            <TimelineDetailsStatus
              label="Clinical Status :"
              value={
                resource.clinicalStatus.coding[0].display ||
                resource.clinicalStatus.coding[0].code
              }
            />
          )}

        {resource.lifecycleStatus && resource.lifecycleStatus.length > 0 && (
          <TimelineDetailsStatus label="Status :" value={`${resource.lifecycleStatus}`} />
        )}
      </div>
      <div className={storedTheme != 'dark' ? 'display-details' : 'display-details-dark'}>

        {!!resource.target && (
          <TimelineDetailsCardItem
            label="CODE"
            value={`${resource.target[0].measure.coding[0].display}`}
          />
        )}
        {!!resource.target && (
          <TimelineDetailsCardItem
            label="DUE DATE"
            value={formatDatePeriod(resource.target[0].dueDate)}
          />
        )}


      </div>
    </div>
  );
};

export default GoalTimelineCard;
