import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface DICState { 
    givenName?: string
    lastName?: string
    dob?: string
    gender?: string
    patientAddress?: string
    patientPhone?: string
}
const initialState: DICState = {
    givenName: '',
    lastName: '',
    dob: '',
    gender: '',
    patientAddress: '',
    patientPhone: '',
}

export const DICSlice = createSlice({
    name: 'DIC',
    initialState: initialState,
    reducers: {
        updateGivenName: (state, action: PayloadAction<string>) => {
            state.givenName = action.payload
        },
        updateLastName: (state, action: PayloadAction<string>) => {
            state.lastName = action.payload
        },
        updateDob: (state, action: PayloadAction<string>) => {
            state.dob = action.payload
        },
        updateGender: (state, action: PayloadAction<string>) => {
            state.gender = action.payload
        },
        updatePatientAddress: (state, action: PayloadAction<string>) => {
            state.patientAddress = action.payload
        },

        updatePatientPhone: (state, action: PayloadAction<string>) => {
            state.patientPhone = action.payload
        },
    }
})

export const DICActions = DICSlice.actions
export default DICSlice.reducer