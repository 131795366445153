import React from 'react';
import './Cards.scss'
import { Card, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux/app/hooks';
import { resourceActions } from '../../redux/feature/ResourceSlice';
import GetResourceImage from '../util/Timeline.util';

//@ts-ignore
const Cards = ({ resName, resource, countText, isClickable }) => {
    let history = useNavigate()
    const dispatch = useAppDispatch()
    const storedTheme = sessionStorage.getItem("theme");
    const handleRedirect = (link: string) => {
        history(link)
    }

    return (
        <Container fluid>
            <Card className={storedTheme !== 'dark' ? 'card_wrapper' : 'card_wrapper-dark'} data-testid='card'
                onClick={() => {
                    if (isClickable) {
                        dispatch(resourceActions.updateSelectedResource(resource));
                        handleRedirect("/dashboard/" + resource)
                    }
                }}
                style={isClickable ? {} : { pointerEvents: 'none', opacity: 0.5 }}
            >
                {/* <Card.Img variant="top" src={GetResourceImage(resource)} alt='clientLogoCard' className='client_logo_card' data-testid='card_img' /> */}
                <div className='client_logo_card' data-testid='card_img' >
                    <GetResourceImage resourceName={resource} />
                </div>
                <Card.Title className={storedTheme !== 'dark' ? 'card_title' : 'card_title-dark'} data-testid='card_title'>{resName} </Card.Title>
                <Card.Text className={storedTheme !== 'dark' ? 'card_text' : 'card_text-dark'}>
                    {countText}
                </Card.Text>
            </Card>
        </Container >
    );
};

export default Cards;
