import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface Filter {
    isFilter?: boolean
}

const initialState: Filter = {
   isFilter :false
}

export const FilterSlice = createSlice({
    name: 'filter',
    initialState: initialState,
    reducers: {
        setIsFilter: (state, action: PayloadAction<boolean>) => {
            state.isFilter = action.payload
        },
        resetFilter: () => initialState
    },
})

export const FilterActions = FilterSlice.actions
export default FilterSlice.reducer
