import SideNav, {
  NavItem,
  NavIcon,
  NavText,
} from "@trendmicro/react-sidenav";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import ClickOutside from "../../ClickOutside/index";
import HealthDataIcon from "../../../images/MyHealthData";
import SidebarProfileIcon from "../../../images/ProfileIcon";
import Onyx from '../../../images/onyxhealth.svg'
import LogoutIcon from "../../../images/logout-icon";
import TimelineIcon from "../../../images/timeline";
import sideBarIndicatorActive from "../../../images/sidebar-indicator-active.svg";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import "./DashboardSideBar.css";
import { useNavigate, useLocation } from "react-router";
import classNames from "classnames";
import { useAppDispatch } from "../../../redux/app/hooks";
import { resourceActions } from "../../../redux/feature/ResourceSlice";
import DigitalInsuranceCard from "../../../images/DigitalInsuranceCard"
import background_vetor from '../../../images/background_vetor.svg'
import { Col, Container, Row } from "react-bootstrap";
import { authActions } from '../../../redux/feature/AuthSlice'
import { deleteAllCookies } from '../../../utils/utils'
import { patientActions } from '../../../redux/feature/patientSlice'
import { bundleActions } from '../../../redux/feature/BundleSlice'
import { timelineActions } from '../../../redux/feature/TimelineSlice'
import { BundleListActions } from '../../../redux/feature/BundleListSlice'
import { timelineBundleActions } from '../../../redux/feature/timelineBundleSlice'
import { HealthDataActions } from "../../../redux/feature/HealthDataSlice";
import { ProfileActions } from "../../../redux/feature/ProfileSlice";

const DashboardSideBar = ({ onSidebartoggle }: any) => {
  const storedTheme = sessionStorage.getItem("theme");
  const [sideBarCollapsed, setSideBarCollapsed] = useState(isMobile);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch()

  const isActiveRouteClass = (key: string) => {
    return classNames({
      activeRoute: location.pathname === key,
    });
  };
  const logout = () => {
    deleteAllCookies()
    sessionStorage.clear()
    localStorage.clear()
    dispatch(resourceActions.resetResource())
    dispatch(authActions.resetAuth())
    dispatch(patientActions.resetPatientData())
    dispatch(bundleActions.resetBundle())
    dispatch(timelineActions.resetTimeline())
    dispatch(BundleListActions.resetBundleList())
    dispatch(timelineBundleActions.resetTimeline())
    dispatch(HealthDataActions.resetHealthData())
    dispatch(ProfileActions.resetProfile())
  }

  const handleClickLogout = () => {
    logout()
    navigate('/')
  }
  const ROUTES = {
    healthData: "",
    dashboard: '/dashboard',
    timeLine: "/timeline",
    timeLineDetails: "/",
    profile: "/profile",
    dic: "/digital-insurance-card",
    logout: "/"
  };


  return (
    <ClickOutside
      onClickOutside={() => {
        if (!isMobile) {
          return;
        }

        setSideBarCollapsed(true);

        if (onSidebartoggle) {
          onSidebartoggle(true);
        }
      }}
      id={storedTheme !== 'dark' ? 'app-side-bar' : 'app-side-bar-dark'}
    >
      <SideNav
        expanded={!sideBarCollapsed}
        onToggle={(expanded: boolean) => {
          setSideBarCollapsed(expanded);
          console.log(expanded);

          if (onSidebartoggle) {
            onSidebartoggle(expanded);
          }
        }}
        onSelect={(selected: any) => {
          if (location.pathname !== selected) {
            navigate(selected);
          }
        }}
        className="side-nav"
      >
        <div className={storedTheme !== 'dark' ? 'background' : 'background_dark'} style={{ 'backgroundImage': !sideBarCollapsed ? `url(${background_vetor})` : '', 'height': !sideBarCollapsed ? '9rem' : '4rem' }}>
          <Container fluid className="logo-container" style={{ 'padding': !sideBarCollapsed ? '18px 0px 0px 10px' : 'unset' }}>
            {!sideBarCollapsed && (
              <>
                <h1 className="title">HealthDataClarity</h1>
              </>
            )}
            <SideNav.Toggle
              onClick={() => {
                setSideBarCollapsed(!sideBarCollapsed);
                if (onSidebartoggle) {
                  onSidebartoggle(!sideBarCollapsed);
                }
              }}
              className={!sideBarCollapsed ? "toggle-button" : "toggle-button hamburger-out"}
            />
          </Container>
        </div>
        <SideNav.Nav >
          <NavItem
            navitemClassName={`nav-item ${isActiveRouteClass(ROUTES.dashboard)}`}
            eventKey={ROUTES.dashboard}
            onClick={() => navigate('/dashboard')}
          >
            <NavIcon style={{ 'opacity': 'unset' }}>
              <HealthDataIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />
            </NavIcon>
            <NavText>
              <span className="nav-text">My Health Data</span>
            </NavText>
            <img src={sideBarIndicatorActive} alt="indicator" className="active-indicator" />
          </NavItem>
          <NavItem
            navitemClassName={`nav-item ${isActiveRouteClass(ROUTES.profile)}`}
            eventKey={`${ROUTES.profile}`}
          >
            <NavIcon style={{ 'opacity': 'unset' }}>
              <SidebarProfileIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />
            </NavIcon>
            <NavText>
              <span className="nav-text">Profile</span>
            </NavText>
            <img src={sideBarIndicatorActive} alt="indicator" className="active-indicator" />
          </NavItem>
          <NavItem
            navitemClassName={`nav-item ${isActiveRouteClass(ROUTES.dic)}`}
            eventKey={`${ROUTES.dic}`}
          >
            <NavIcon style={{ 'opacity': 'unset' }}>
              <DigitalInsuranceCard className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />
            </NavIcon>
            <NavText>
              <span className="nav-text">Digital Insurance Card</span>
            </NavText>
            <img src={sideBarIndicatorActive} alt="" className="active-indicator" />
          </NavItem>
          <NavItem
            navitemClassName={`nav-item ${isActiveRouteClass(ROUTES.timeLine)}`}
            eventKey={`${ROUTES.timeLine}`}
            onClick={() => {
              navigate('/timeline');
            }}
          >
            <NavIcon style={{ 'opacity': 'unset' }}>
              <TimelineIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />
            </NavIcon>
            <NavText>
              <span className="nav-text">Timeline</span>
            </NavText>
            <img src={sideBarIndicatorActive} alt="" className="active-indicator" />
          </NavItem>
          <hr style={{ 'color': storedTheme !== 'dark' ? 'black' : 'white', 'height': '2px' }} />
          <NavItem
            eventKey={`${ROUTES.logout}`}
            onClick={() => { handleClickLogout() }}
          >
            <NavIcon style={{ 'opacity': 'unset' }}>
              <LogoutIcon className={storedTheme !== 'dark' ? 'light-icon' : 'dark-icon'} />
            </NavIcon>
            <NavText>
              <span className="nav-text">Logout</span>
            </NavText>
            <img src={sideBarIndicatorActive} alt="indicator" className="active-indicator" />
          </NavItem>
          < Container fluid className="bottom-container" >
            {!sideBarCollapsed && (
              <div className="nav-item-power">
                <img src={Onyx} alt="Onyx logo" />
              </div>
            )}
          </Container>
          <div className="bottom_style" />
        </SideNav.Nav>

      </SideNav >
    </ClickOutside >
  );
};

export default DashboardSideBar;
