import React, { useState } from 'react'
import './ErrorToast.scss'
import { Button, CloseButton, Toast, ToastContainer } from 'react-bootstrap'

//@ts-ignore
const ErrorToast = ({ show, setShowToast }) => {
    return (
        <div style={{ 'zIndex': '1' }}>
            <ToastContainer position="middle-center">
                <Toast bg='danger' className='toast_wrapper' show={show} delay={4000} autohide onClose={() => setShowToast(false)}>
                    <div className='close_btn_toast'>
                        <CloseButton variant="white" onClick={() => setShowToast(false)} />
                    </div>
                    <Toast.Body>
                        <p className='toast_text'>Cannot fetch Payer data. Please try again after sometime</p>
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    )
}

export default ErrorToast