function MedicationsIcon({ className }: { className?: string }) {

    return (
        <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={className} d="M21.7 45.9999H3.23665C2.00869 45.9999 1 44.7736 1 43.2807V13.263C1 11.5035 2.18411 10.064 3.63135 10.064H21.3053C22.7525 10.064 23.9366 11.5035 23.9366 13.263V43.2807C23.9366 44.7736 22.9279 45.9999 21.7 45.9999Z" fill="black" fillOpacity="0.5" stroke="black" strokeWidth="1.133" strokeMiterlimit="10" />
            <path className={className} d="M23.6297 22.3804H1.26318V33.2038H23.6297V22.3804Z" fill="white" />
            <path className={className} d="M18.1915 7.50488H6.70129V10.2241H18.1915V7.50488Z" fill="black" />
            <path className={className} d="M20.8666 9.79745C20.8666 9.79745 19.0685 9.42423 18.1914 7.82471V9.85077L20.8666 9.79745Z" fill="black" />
            <path className={className} d="M4.06982 9.79745C4.06982 9.79745 5.86792 9.42423 6.74503 7.82471V9.85077L4.06982 9.79745Z" fill="black" />
            <path className={className} d="M19.2002 7.50473H5.73647C5.56104 7.50473 5.38562 7.29146 5.38562 7.07819V1.42654C5.38562 1.21327 5.56104 1 5.73647 1H19.2002C19.3756 1 19.5511 1.21327 19.5511 1.42654V7.02487C19.5511 7.29146 19.3756 7.50473 19.2002 7.50473Z" fill="white" stroke="black" strokeWidth="1.133" strokeMiterlimit="10" />
            <path className={className} d="M17.8407 1V2.65284" stroke="black" strokeWidth="1.133" strokeMiterlimit="10" strokeLinecap="round" />
            <path className={className} d="M15.6917 1V2.65284" stroke="black" strokeWidth="1.133" strokeMiterlimit="10" strokeLinecap="round" />
            <path className={className} d="M13.5427 1V2.65284" stroke="black" strokeWidth="1.133" strokeMiterlimit="10" strokeLinecap="round" />
            <path className={className} d="M11.3938 1V2.65284" stroke="black" strokeWidth="1.133" strokeMiterlimit="10" strokeLinecap="round" />
            <path className={className} d="M9.24487 1V2.65284" stroke="black" strokeWidth="1.133" strokeMiterlimit="10" strokeLinecap="round" />
            <path className={className} d="M7.09595 1V2.65284" stroke="black" strokeWidth="1.133" strokeMiterlimit="10" strokeLinecap="round" />
            <path className={className} d="M32.0938 35.3364L36.611 34.5366L37.8389 44.7736L33.3218 45.5733C30.9974 45.9999 28.8485 44.0271 28.4976 41.2013C28.3222 39.7617 28.6292 38.4288 29.287 37.3624C29.9449 36.2961 30.9097 35.5497 32.0938 35.3364Z" fill="black" fillOpacity="0.5" stroke="black" strokeWidth="1.133" strokeMiterlimit="10" />
            <path className={className} d="M36.611 34.5367L41.1281 33.737C43.4525 33.3104 45.6014 35.2832 45.9523 38.109C46.3031 40.9348 44.6804 43.5474 42.3561 43.9739L37.8389 44.7737L36.611 34.5367Z" fill="white" />
            <path className={className} d="M36.611 34.5367L41.1281 33.737C43.4525 33.3104 45.6014 35.2832 45.9523 38.109C46.3031 40.9348 44.6804 43.5474 42.3561 43.9739L37.8389 44.7737L36.611 34.5367Z" stroke="black" strokeWidth="1.133" strokeMiterlimit="10" />
            <path className={className} d="M34.2866 29.3649V34.9099H25.7786V29.3649C25.7786 26.4858 27.6644 24.1931 30.0326 24.1931C31.2167 24.1931 32.2692 24.7796 33.0586 25.686C33.8481 26.6457 34.2866 27.9253 34.2866 29.3649Z" fill="black" fillOpacity="0.5" stroke="black" strokeWidth="1.133" strokeMiterlimit="10" />
            <path className={className} d="M34.2866 34.9099V40.4549C34.2866 43.3341 32.4008 45.6267 30.0326 45.6267C27.6644 45.6267 25.7786 43.2808 25.7786 40.4549V34.9099H34.2866Z" fill="white" />
            <path className={className} d="M34.2866 34.9099V40.4549C34.2866 43.3341 32.4008 45.6267 30.0326 45.6267C27.6644 45.6267 25.7786 43.2808 25.7786 40.4549V34.9099H34.2866Z" stroke="black" strokeWidth="1.133" strokeMiterlimit="10" />
        </svg>
    )
}
export default MedicationsIcon;
