import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface Bundle {
    selectedBundle:any[] 
}

const initialState: Bundle = {
    selectedBundle:[]
}

export const bundleSlice = createSlice({
    name: 'bundle',
    initialState: initialState,
    reducers: {
        updateSelectedBundle: (state, action: PayloadAction<any>) => {
            state.selectedBundle.push(action.payload)
        },
        resetBundle: () => initialState
    },
})

export const bundleActions = bundleSlice.actions
export default bundleSlice.reducer
