import React, { useCallback, useEffect, useState } from 'react'
import './LandingPage.scss'
import { Container, Spinner } from 'react-bootstrap'
import LandingPageFooter from '../../components/Footer/LandingPageFooter/LandingPageFooter'
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks'
import { fetchAllPLansList, fetchNewPayers } from '../../functions/LandingPage/LandingPage_Funcs'
import PlanList2 from '../../components/PlansList/PlanList2'
import { useLocation } from 'react-router-dom'
import Switch from '@mui/material/Switch'
import ThreeStepModal from '../../components/Modals/ThreeStepModal/ThreeStepModal'
import CustomizedTreeView from '../../components/TreeView/TreeView'
import { UIActions } from '../../redux/feature/UISlice'
import '../../main.scss'
import './Landing_darktheme.scss'
import LandingNav from '../../components/LandingNavBar/LandingNav'
import { handleOauth, handlePostPayer } from '../../functions/LoginPage/LoginPageFuncs'
import { Initial_LandindPageChanges, initial_search_change } from '../../constants/PageChanges'


const LandingPage = () => {
    const dispatch = useAppDispatch()
    const UI = useAppSelector((state) => state.UI)
    //useState Variables
    const [searchInput, setSearchInput] = useState('')
    const [showToast, setShowToast] = useState(false)
    const [checked, setChecked] = useState(true)
    const [show, setShow] = useState(UI.modal_view ? false : true)

    const [spinner, setSpinner] = useState(true)
    const showSpinnerTrue = () => setSpinner(true)

    const [LandingPageVar, setLandingPageVar] = useState(Initial_LandindPageChanges)
    const [searchVar, setSearchVar] = useState(initial_search_change)

    useEffect(() => {
        console.log(LandingPageVar, "++")
    }, [LandingPageVar])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        setChecked(event.target.checked)
        event.target.checked === true ?
            setLandingPageVar({
                ...LandingPageVar,
                card_view: false,
                card_view_text: "Payer View"
            })
            :
            setLandingPageVar({
                ...LandingPageVar,
                card_view: true,
                card_view_text: "Plan View"
            })
    }

    const handleClose = () => {
        setShow(false)
        dispatch(UIActions.updateModalView(true))
    }
    const handleShow = () => {
        dispatch(UIActions.updateModalView(false))
        setShow(true)
    }

    const storedTheme = sessionStorage.getItem("theme")

    const location = useLocation()


    useEffect(() => {
        // localStorage.clear();

        if (UI.payers.length === 0) {
            fetchNewPayers(dispatch, setLandingPageVar, LandingPageVar)
        }
        if (UI.plans_list.length === 0) {
            fetchAllPLansList(dispatch, setLandingPageVar, LandingPageVar)
        }
        setLandingPageVar({
            ...LandingPageVar,
            show_new_Payers: true
        })
    }, [])

    return (
        <div className={storedTheme !== 'dark' ? 'landing_wrapper' : 'theme_wrapper'}>
            <LandingNav setLandingPageVar={setLandingPageVar} LandingPageVar={LandingPageVar} handleShow={handleShow} setSearchInput={setSearchInput} setSearchVar={setSearchVar} searchVar={searchVar} />
            <div>
                <Container fluid>
                    {LandingPageVar.show_new_Payers ?

                        <div className={'mid_content_wrapper'} data-testid='landing_page_cards'>
                            <div style={{ 'float': 'right' }}>
                                <Switch
                                    checked={checked}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />{LandingPageVar.card_view_text}
                            </div>
                            {LandingPageVar.card_view ? <>
                                {searchInput.length > 0 ? (
                                    <>
                                        <PlanList2 filteredItems={searchVar.filteredResults} />
                                    </>
                                ) : (
                                    <>
                                        <PlanList2 filteredItems={UI.plans_list} />

                                    </>
                                )}
                            </>
                                :
                                <>
                                    {searchInput.length > 0 ? (
                                        <>
                                            <CustomizedTreeView filteredItems={searchVar.filteredResults} />
                                        </>
                                    ) : (
                                        <>
                                            <CustomizedTreeView filteredItems={UI.plans_list} />

                                        </>
                                    )}
                                </>
                            }
                        </div>
                        :
                        <div className='spinner_wrapper'>
                            <Spinner variant='primary' animation='border' />
                            <p>{LandingPageVar.show_dashboard_content}</p>
                        </div>
                    }
                </Container>

            </div>
            <LandingPageFooter />
            {/* <HomeFooter /> */}
            <ThreeStepModal
                show={show}
                handleClose={handleClose}
                setShow={setShow} />

        </div>
    )
}

export default LandingPage