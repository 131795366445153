import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface Profile {
    showRawData?:boolean
    showBundle?:boolean
    profileLoading?: boolean
    profileError?: boolean
    resourceData?:{entry: Array<any>;} | null
}

const initialState: Profile = {
    showBundle : false,
   profileLoading :true,
   profileError:false,
   resourceData: null
}

export const ProfileSlice = createSlice({
    name: 'HeathData',
    initialState: initialState,
    reducers: {
        setShowBundle: (state, action: PayloadAction<boolean>) => {
            state.showBundle = action.payload
        },
        setprofileLoading: (state, action: PayloadAction<boolean>) => {
            state.profileLoading = action.payload
        },
        setprofileError: (state, action: PayloadAction<boolean>) => {
            state.profileError = action.payload
        },
        updateResourceData: (state, action: PayloadAction<{ entry: any[]; }>) => {
            state.resourceData = action.payload
        },
        resetProfile: () => initialState
    },
})

export const ProfileActions = ProfileSlice.actions
export default ProfileSlice.reducer
