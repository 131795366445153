import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'

// const browserHistory = createBrowserHistory({ basename: '' })
const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey:
            process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEYS,
        extensions: [reactPlugin],
    },
})
appInsights.loadAppInsights()
export { reactPlugin, appInsights }
