export function getResourceDate(entry: any) {
    return (
      entry?.resource?.created ??
      entry?.resource?.servicedPeriod?.start ??
      entry?.resource?.billablePeriod?.start ??
      entry?.resource?.servicedDate ??
      entry?.resource?.onsetDateTime ??
      entry?.resource?.performedDateTime ??
      entry?.resource?.occurrenceDateTime ??
      entry?.resource?.effectiveDateTime ??
      entry?.resource?.period?.start ??
      entry?.resource?.date ??
      entry.resource?.meta?.lastUpdated
    );
  }