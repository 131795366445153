import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './HomePage.scss'
import PersonIcon from '@mui/icons-material/Person';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
//@ts-ignore
import introvideo from "./intro.mp4"
import hero_img from './img/hero-img.png'
import payorLogo from './img/payor.png'
import mmhdLogo from './img/mmhd-logo.png'
import mmhdLogoDark from './img/mmhd-logo-dark.png'
import SwipeableTextMobileStepperHome from '../../components/Modals/ThreeStepModal/MobileStepperHome';
import LogosCarosel from './LogosCarosel';
import ContactForm from './ContactForm';

import { ChangeEventHandler } from "react";
import { ScrollOnClick } from '../../components/ScrollOnClick/ScrollOnClick';


const HomeMember = () => {
    // const [logo, setLogo] = useState(mmhdLogo)
    var logo = mmhdLogo;

    const setDark = () => {
        logo = mmhdLogoDark;
        sessionStorage.setItem("theme", "dark");
        document.documentElement.setAttribute("data-theme", "dark");
    };

    const setLight = () => {
        logo = mmhdLogo;
        sessionStorage.setItem("theme", "light");
        document.documentElement.setAttribute("data-theme", "light");
    };

    const storedTheme = sessionStorage.getItem("theme");

    const prefersDark =
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: light)").matches;

    const defaultDark =
        storedTheme === "dark" || (storedTheme === null && prefersDark);

    if (defaultDark) {
        setDark();
    }

    const toggleTheme: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (e.target.checked) {
            setDark();
        } else {
            setLight();
        }
        window.location.reload()
    };

    return (
        <div>
            <header id="header" className="header fixed-top">
                <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
                    <Link to={'/payor'}>
                        <a className="logo d-flex align-items-center" style={{ 'color': storedTheme != 'dark' ? '' : '#33bcbf' }}>
                            {/* <img src={logo} alt="" /> */}
                            <h3>HealthDataClarity</h3>
                        </a>
                    </Link>

                    <nav id="navbar" className="navbar">
                        <ul>
                            <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
                            <li><a className="nav-link scrollto" href="https://www.onyxhealth.io/mmhd/" target="_blank">About</a></li>
                            <li><a className="nav-link scrollto" href="https://portal.safhir.io/" target="_blank">Developer Portal</a></li>
                            <li><a className="nav-link scrollto" href="#contact">Contact Us</a></li>

                            <div className="toggle-theme-wrapper">
                                <span className='theme-span'>☀️</span>
                                <label className="toggle-theme" htmlFor="checkbox">
                                    <input className='theme-input'
                                        type="checkbox"
                                        id="checkbox"
                                        onChange={toggleTheme}
                                        defaultChecked={defaultDark}
                                    />
                                    <div className="slider round"></div>
                                </label>
                                <span className='theme-span'>🌒</span>
                            </div>
                        </ul>
                    </nav>

                </div>
            </header>

            <section id="hero" className="hero d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex flex-column justify-content-center">
                            <h2 >Welcome To</h2>
                            {/* <img src={logo} alt="" /> */}
                            <h1>HealthDataClarity</h1>
                            <h2>Move Your Health Data From Your Earlier Health Plan In 3 Easy Steps</h2>
                            <h2>First Platform to Connect 300+ Health Plans</h2>
                            <div>
                                <div className="text-center text-lg-start">
                                    <Link to={'/landing'}>
                                        <a className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                                            <span>HealthDataClarity</span>
                                            <ArrowRightAltIcon className='icons' />
                                        </a>
                                    </Link><br /><br />
                                    <Link to={'/'}>
                                        <a style={{ 'color': storedTheme != 'dark' ? '' : '#579f9d' }}><span>Are you a Member?</span></a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 hero-img">
                            <img src={hero_img} className="img-fluid" alt="" />
                        </div>
                    </div>
                    <ScrollOnClick />
                    <div className="scroll-arrow-container">
                        <div className="chevron"></div>
                        <div className="chevron"></div>
                        <div className="chevron"></div>
                    </div>

                </div>
            </section >

            <LogosCarosel />

            <main id="main">

                <section id="about" className="about">

                    <div className="container" data-aos="fade-up">
                        <div className="row gx-0">

                            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                                <div className="content">
                                    <SwipeableTextMobileStepperHome />
                                </div>
                            </div>

                            <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">

                                <video autoPlay loop muted controls plays-inline className="back-video">
                                    <source src={introvideo} type="video/mp4" />
                                </video>
                            </div>

                        </div>
                    </div>

                </section>

                <section id="values" className="values">

                    <div className="container" data-aos="fade-up">

                        <header className="section-header">
                            <h2>Benefits Of HDC</h2>

                        </header>

                        <div className="row">

                            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
                                <div className="box">
                                    <PersonIcon color='primary' />
                                    <h3>Patients</h3>
                                    <p className='sub-text'>Finally, healthcare data is available to the person who needs it most: You.</p>
                                    <p className="sub-pra">PATIENT DATA
                                        <li className="card-list">Bring all your medical data to one place.</li>
                                        <li className="card-list">Simple, secure, and easy access.</li>
                                        <li className="card-list">More knowledge equals better outcomes at a lower cost.</li>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
                                <div className="box">
                                    {/* <GroupsIcon color='primary' /> */}
                                    <img src={payorLogo} className="benefits-logo" alt='' />
                                    <h3>Payers</h3>
                                    <p className='sub-text'>With HealthDataClarity, your customers are happier, and so are your margins.</p>
                                    <p className="sub-pra">PATIENT DATA
                                        <li className="card-list">Gain early insight into the health of new members .</li>
                                        <li className="card-list">Proactively align new members with the health maintenance programs they need.</li>
                                        <li className="card-list">Increased efficiency thanks to a better understanding of customer needs.</li>
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
                                <div className="box">
                                    {/* <i className="fas fa-user-md"></i> 
                                    */}
                                    <LocalHospitalIcon color='primary' />
                                    <h3>Providers</h3>
                                    <p className='sub-text'>Operate more effectively with Health Plans.</p>
                                    <p className="sub-pra">PATIENT DATA
                                        <li className="card-list">Empower Patients to share their clinical information with their Health Plan</li>
                                        <li className="card-list">Enable Health Plans to use clinical insights to alert you to Care Gaps</li>
                                        <li className="card-list">Enable Health Plans to use clinical insights to alert you to Care Gaps</li>
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>

                </section>

                <section id="contact" className="contact">

                    <div className="container" data-aos="fade-up">

                        <header className="section-header">
                            <h2>Contact</h2>
                        </header>

                        <div className="row gy-4">

                            <div className="col-lg-6">

                                <div className="contact-text">
                                    <h3><DateRangeIcon className='contact-icons' /> Schedule a demo</h3>
                                    <h3><PhoneIcon className='contact-icons' /> Connect with sales</h3>
                                    <h3><SupportAgentIcon className='contact-icons' /> Get support</h3>
                                </div>

                            </div>

                            <div className="col-lg-6">
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </section>

            </main>

            <footer id="footer" className="footer">

                <div className="footer-top">
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-5 col-md-12 footer-info">
                                <a className="logo d-flex align-items-center" style={{ 'color': storedTheme != 'dark' ? '' : '#33bcbf' }}>
                                    {/* <img src={logo} alt="" /> */}
                                    <h3>HealthDataClarity</h3>
                                </a>
                                <p>A Product By Onyx</p><br />
                                <p>Onyx Technology LLC is a healthcare interoperability solutions provider.</p>
                                <div className="social-links mt-3">
                                    <a href="https://twitter.com/OnyxHealth_Tech" target="_blank" className="twitter"><TwitterIcon className='icons-footer' /></a>
                                    <a href="https://www.linkedin.com/uas/login?session_redirect=%2Fcompany%2F43215528%2F" target="_blank" className="linkedin"><LinkedInIcon className='icons-footer' /></a>
                                    <a href="https://www.youtube.com/channel/UCOTcNXnOD0MfjvqqSll9XiA/featured" target="_blank" className="facebook"><YouTubeIcon className='icons-footer' /></a>
                                </div>
                            </div>

                            <div className="col-lg-2 col-6 footer-links">
                                <h4>Legal</h4>
                                <ul>
                                    <li><ChevronRightIcon className='icons-footer' /><Link to={'/terms-of-service'}> <a target="_blank">Terms Of Service</a></Link></li>
                                    <li><ChevronRightIcon className='icons-footer' /><Link to={'/privacy-policy'}> <a target="_blank">Privacy Policy</a></Link></li>
                                </ul>
                            </div>

                            <div className="col-lg-2 col-6 footer-links">
                                <h4>Also Visit</h4>
                                <ul>
                                    <li><ChevronRightIcon className='icons-footer' /> <a href="https://portal.safhir.io/" target="_blank">SAFHIR</a></li>
                                    <li><ChevronRightIcon className='icons-footer' /> <a href="https://www.onyxhealth.io/" target="_blank">Onyx</a></li>
                                </ul>
                            </div>

                            <div className="col-lg-2 col-6 footer-links">
                                <h4>Contact Us</h4>
                                <ul>
                                    <li><LocationOnIcon className='icons-footer' />2270 Rolling Run Drive, Suite 400<br /> Windsor Mill, MD 21244</li>
                                    <li><PhoneIcon className='icons-footer' />410-782-0476</li>
                                    <li><MailOutlineIcon className='icons-footer' />info@onyxhealth.io</li>
                                    <li><AccessTimeIcon className='icons-footer' />Monday - Friday<br />9:00AM - 05:00PM</li>
                                </ul>
                            </div>


                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="copyright">
                        &copy; Copyright <strong><span>Onyx</span></strong>. All Rights Reserved
                    </div>
                </div>
            </footer>
        </div >
    )
}

export default HomeMember
