export const CLAIMS_AND_CLINICAL_RESOURCE = {
	claims: 'ExplanationOfBenefit',
	allergy: 'AllergyIntolerance',
	condition: 'Condition',
	medication: 'Medication',
	medicationRequest:'MedicationRequest',
	medicationDispense:'MedicationDispense',
	prescription: 'Prescription',
	immunization: 'Immunization',
	procedure: 'Procedure',
	carePlan: 'CarePlan',
	careTeam:'CareTeam',
	coverage:'Coverage',
	goal:'Goal',
	diagnosticReport: 'DiagnosticReport',
	documentReference: 'DocumentReference',
	device:'Device',
	observation: 'Observation',
	encounter: 'Encounter',
	insurance: 'Insurance',
	healthcare: 'HealthcareService',
	organization: 'Organization',
	practitioner: 'Practitioner',
	provenance:'Provenance',
	patient:'Patient',
	location: 'Location',
	role: 'PractitionerRole',
	affiliation: 'Affiliation',
	list: 'List',
	knowledge: 'MedicationKnowledge',
	basic:"Basic",
	insurancePlan:"InsurancePlan"
};

export const CLINICAL_CATEGORY = ['AllergyIntolerance', 'Procedures', 'Immunization', 'Condition', 'Medication', 'Practitioner', 'MedicationStatement', 'MedicationRequest', 'DocumentReference', 'Observation', 'CarePlan', 'CareTeam', 'Coverage', 'Device', 'DiagnosticReport','Provenance','Goal'];
export const CLAIMS_CATEGORY = ['ExplanationOfBenefit', 'Organization', 'Practitioner'];
export const SECURE_US_DRUG = ['List', 'MedicationKnowledge'];
export const FORMULARY_NET = ['Practitioner', 'HealthcareService', 'Organization', 'Location', 'OrganizationAffiliation', 'Insurance'];

export interface TimelineResource {
		name: string
		resource:string
		count:string
	}

export const TimelineResources = [
	{
		name: 'ExplanationOfBenefit',
		resource: 'ExplanationOfBenefit',
	},
	{
		name: 'Allergy',
		resource: 'AllergyIntolerance',
	},
	{
		name: 'Procedure',
		resource: 'Procedure',
	},
	{
		name: 'Immunization',
		resource: 'Immunization',
	},
	{
		name: 'Condition',
		resource: 'Condition',
	},
	{
		name: 'Coverage',
		resource: 'Coverage',
	},
	{
		name: 'Medication',
		resource: 'Medication',	
	},
	{
		name: 'Medication Request',
		resource: 'MedicationRequest',
	},
	{
		name: 'Prescription',
		resource: 'Prescription',
	},
	{
		name: 'Patient',
		resource: 'Patient',
	},
	{
		name: 'Provenance',
		resource: 'Provenance',
	},
	{
		name: 'Observation',
		resource: 'Observation',
	},
	{
		name: 'Care Plan',
		resource: 'CarePlan',
	},
	{
		name: 'Care Team',
		resource: 'CareTeam',
	},
	{
		name: 'Device',
		resource: 'Device',
	},
	{
		name: 'Diagnostic Report',
		resource: 'DiagnosticReport',
	},
	{
		name: 'Document Reference',
		resource: 'DocumentReference',
	},
	{
		name: 'Encounter',
		resource: 'Encounter',
	},
	{
		name: 'Insurance',
		resource: 'Insurance',
	},
	{
		name: 'Insurance Plan',
		resource: 'InsurancePlan',
	},
	{
		name: 'Basic',
		resource: 'Basic',
	},
	{
		name: 'Goal',
		resource: 'Goal',
	},
	{
		name: 'Healthcare',
		resource: 'HealthcareService',
	},
	{
		name: 'Organization',
		resource: 'Organization',
	},
	{
		name: 'Practitioner',
		resource: 'Practitioner',
	},
	{
		name: 'Location',
		resource: 'Location',
	},
	{
		name: 'Practitioner Role',
		resource: 'PractitionerRole',
	},
	{
		name: 'Organization Affiliation',
		resource: 'Affiliation',
	},
	{
		name: 'Knowledge',
		resource: 'MedicationKnowledge',
	},
	{
		name: 'List',
		resource: 'List',
	},
];

export const TimelineDateFormat = 'D MMM, YYYY';
export const IMPLEMENTATION_GUIDE = 'implementation_guide'