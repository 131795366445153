import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "@mui/material/Button";
import { appInsights } from "../../AppInsights/Appinsights";
import Cards from "../../components/Cards/Cards";
import AppLayout from "../../components/Layout/AppLayout";
import { TimelineResources } from "../../constants/constants";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { BundleListActions } from "../../redux/feature/BundleListSlice";
import "./MyHealthData.scss";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import DownloadData from "../../components/DownloadData/DownloadData";
import ErrorModal from "../../components/Modals/ErrorModal/ErrorModal";
import useWebSocket from "react-use-websocket";
import LodaingResourceView from "../../components/Loading/LodaingResourceView";
import { bundleActions } from "../../redux/feature/BundleSlice";
import { LinearProgress } from "@mui/material";

function MyHealthData(): JSX.Element {
  const storedTheme = sessionStorage.getItem("theme");
  const dispatch = useAppDispatch()
  const [downloadData, setDownloadData] = useState(false);
  const [isCardsClickable, setIsCardsClickable] = useState(true);
  const [isError, setIsError] = useState(false)
  const client_local = localStorage.getItem("client")
  const auth = useAppSelector((state) => state.auth)
  const [progress, setProgress] = useState(0);

  const handleErrorModalClose = (
    event: Record<string, unknown>,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return
    }
    setIsError(false)
  }

  const endpoint = `${process.env.REACT_APP_API_CLARITY_WEBSOCKET}/list-resources/`;
  const msg = {
    'AuthToken': auth?.authToken,
    'ClientName': client_local,
  };
  const { lastMessage, readyState, sendJsonMessage } = useWebSocket(endpoint);
  appInsights.trackEvent({ name: `API: ${endpoint}, Connection: ${readyState}` })

  const receivedMessages = useAppSelector((state) => state.BundleList.selectedBundleList);
  const processedResults = receivedMessages.map((item) => item.resource)

  useEffect(() => {
    sessionStorage.setItem("ListResource", JSON.stringify(receivedMessages))
  }, [])

  if (readyState === 3) {
    appInsights.trackEvent({
      name: `Error: connection lost since websocket state is ${readyState}`,
    });
    setIsError(true)
  }

  useEffect(() => {
    dispatch(bundleActions.resetBundle())
    appInsights.trackEvent({ name: `Fetch ListResource API called for ${client_local} ’s IDP.` })
    appInsights.trackEvent({
      name: `ListResource URL: ${endpoint}`,
    });
    appInsights.trackEvent({
      name: `message: ${msg}`,
    });
    if (receivedMessages.length === 0)
      sendJsonMessage(msg);
  }, []);


  useEffect(() => {
    if (lastMessage !== null) {
      const parsedMessage = JSON.parse(lastMessage.data);
      if (parsedMessage.Status === "End of Stream") {
        if (receivedMessages.length === 0) {
          setIsError(true);
        }
        setProgress(100);
        setIsCardsClickable(true);
      } else {
        setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
        setIsCardsClickable(false);
      }

      // Filter TimelineResources based on API response type
      const filteredResource = TimelineResources.find(
        resource => resource.resource === parsedMessage.type
      );

      // If a matching resource is found, add it to the receivedMessages array with the count
      const countText = parsedMessage.count === 1 ? `${parsedMessage.count} total ${filteredResource?.resource.toLowerCase()}` : `${parsedMessage.count} total ${filteredResource?.resource.toLowerCase()}s`;
      if (filteredResource) {
        dispatch(
          BundleListActions.updateselectedBundleList({
            name: filteredResource.name,
            resource: filteredResource.resource,
            count: countText,
          })
        );
      }
    }
  }, [lastMessage, dispatch, setIsCardsClickable, setIsError]);

  return (
    <AppLayout subHeading="My Health Data">
      <Container id={storedTheme !== 'dark' ? 'MyHealthData_Page' : 'MyHealthData_Page-dark'}>
        {
          <DownloadData
            show={downloadData}
            handleClose={() => {
              setDownloadData(false);
            }}
            title="All Resources"
          />
        }
        <Row id="main_container">
          <Container style={{ 'height': '100%' }}>
            <Row className="filter_div">
              <Col>
                <h1 style={{ fontSize: "1.5rem", color: storedTheme !== 'dark' ? 'black' : 'white' }} className="div_title">Clinical Data</h1>
              </Col>
              <Col>
                <Button
                  id="basic-button"
                  onClick={() => {
                    setDownloadData(true);
                  }}
                >
                  <NorthEastIcon />
                  &nbsp; Export Medical Records
                </Button>
              </Col>
            </Row>
            <LinearProgress variant="determinate" value={progress} sx={{ position: 'sticky' }} />
            <Col className="resourceList_container">
              {/* <LinearProgress variant="determinate" value={progress} sx={{ position: 'sticky' }} /> */}
              {receivedMessages.length > 0 ? (
                <div>
                  {receivedMessages.map((message, index) => (
                    <Cards resName={message.name} resource={message.resource} countText={message.count} isClickable={isCardsClickable} key={index} />
                  ))}
                </div>
              ) : <LodaingResourceView />}
            </Col>
            <Row className="disclaimer_div">
              <Col>
                <p className="disclaimer_txt">
                  <b>DISCLAIMER:</b> The data present here are the resources from
                  the implimentation guides and follow the standards set by FHIR.{" "}
                </p>
              </Col>
            </Row>
          </Container>
        </Row>
        <ErrorModal open={isError} onClose={handleErrorModalClose}>
          An error occurred while trying to retrieve your healthcare
          data. Please try again after some time or contact our{' '}
          <a>support</a>.
        </ErrorModal>
      </Container>
    </AppLayout >
  );
}

export default MyHealthData;
