import { formatDatePeriod } from "../util/index";
import TimelineDetailsCardDropdown from "../../components/TimelineDetailsCardDropdown/TimelineDetailsCardDropdown";
import TimelineDetailsCardItem from "../../components/TimelineDetailsCardItem/TimelineDetailsCardItem";
import './TimelineCard.css'
import TimelineDetailsStatus from "../TimelineDetailsCardItem/TimelineStatus";

const AllergyTimelineCard = ({ resource }: any) => {
  const storedTheme = sessionStorage.getItem("theme");
  return (
    <div id="allergy-timeline-card">
      <div className="status-div">
        {resource.clinicalStatus &&
          resource.clinicalStatus.coding &&
          resource.clinicalStatus.coding.length &&
          (resource.clinicalStatus.coding[0].display ||
            resource.clinicalStatus.coding[0].code) && (
            <TimelineDetailsStatus
              label="Clinical Status : "
              value={
                resource.clinicalStatus.coding[0].display ||
                resource.clinicalStatus.coding[0].code
              }
            />
          )}
        {resource.verificationStatus &&
          resource.verificationStatus.coding &&
          resource.verificationStatus.coding.length &&
          (resource.verificationStatus.coding[0].display ||
            resource.verificationStatus.coding[0].code) && (
            <TimelineDetailsStatus
              label="Verification Status : "
              value={
                resource.verificationStatus.coding[0].display ||
                resource.verificationStatus.coding[0].code
              }
            />
          )}
      </div>
      {resource.type && (
        <TimelineDetailsCardItem label="Type" value={resource.type} />
      )}
      <div className={storedTheme != 'dark' ? 'display-details' : 'display-details-dark'}>
        {!!resource.reaction?.length && resource.reaction && resource.reaction[0]?.substance?.coding[0].display && (
          <TimelineDetailsCardItem
            label="SUBSTANCE"
            value={resource.reaction[0].substance.coding[0].display} />
        )}
        {!!resource.reaction?.length && resource.reaction[0]?.manifestation[0]?.coding[0].display && (
          <TimelineDetailsCardItem
            label="MANIFESTATION"
            value={resource.reaction[0].manifestation[0].coding[0].display}
          />
        )}
        {!!resource.reaction?.length && resource.reaction[0]?.severity && (
          <TimelineDetailsCardItem
            label="SEVERITY"
            value={resource.reaction[0].severity}
          />
        )}

        {!!resource.category?.length && (
          <TimelineDetailsCardDropdown
            header="Category"
            items={resource.category.map((item: any) => (
              <TimelineDetailsCardItem label="Value" value={item} />
            ))}
          />
        )}

        {resource.criticality && (
          <TimelineDetailsCardItem
            label="CRITICALITY"
            value={`${resource.criticality}`}
          />
        )}

        {resource.code?.coding &&
          resource.code?.coding.length &&
          resource.code?.coding[0].display && (
            <TimelineDetailsCardItem
              label="CODE"
              value={`${resource.code?.coding[0].display}`}
            />
          )}

        {!!resource.encounter?.length && (
          <TimelineDetailsCardDropdown
            header="Encounter"
            items={resource.encounter.map((item: any) => (
              <TimelineDetailsCardItem label="" value={item.reference} />
            ))}
          />
        )}

        {resource.onsetDateTime && (
          <TimelineDetailsCardItem
            label="ONSET DATE"
            value={formatDatePeriod(resource.onsetDateTime)}
          />
        )}
        {resource.recordedDate && (
          <TimelineDetailsCardItem
            label="RECORDED DATE"
            value={formatDatePeriod(resource.recordedDate)}
          />
        )}
        {resource.recorder &&
          resource.recorder.practitioner &&
          resource.recorder.practitioner.name && (
            <TimelineDetailsCardItem
              label="RECORDER"
              value={resource.recorder.practitioner.name}
            />
          )}

        {!!resource.note?.length && (
          <TimelineDetailsCardDropdown
            header="Notes"
            items={resource.note.map((item: any) => (
              <TimelineDetailsCardItem label="Text" value={item.text || ""} />
            ))}
          />
        )}


      </div>
    </div>
  );
};

export default AllergyTimelineCard;
