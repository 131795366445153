import { fetchTermsAndConditions } from "../../apis/apis";
import { responsestatus } from "./PrivacyPolicyFuncs";

//@ts-ignore
export const fetchTermsNConditions = async (setTnc, setShowTnc, setShowSpinner) => {

    try {
        const res = await fetchTermsAndConditions();
        setTnc(res.data);
        responsestatus(res.status, setShowTnc, setShowSpinner);
    }
    catch (err) {
        setTnc("Some Error occured while loading data. Please try again after some time.")
        responsestatus(500, setShowTnc, setShowSpinner);
    }
}