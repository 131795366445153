function RoleIcon({ className }: { className?: string }) {

    return (
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_236_392)">
                <path className={className} d="M44.2475 24.5266H37.1652V21.316C37.1652 20.8972 36.8553 20.5715 36.457 20.5715H27.9583C27.5599 20.5715 27.25 20.8972 27.25 21.316V24.5266H20.1677C19.7693 24.5266 19.4595 24.8523 19.4595 25.2711V44.2555C19.4595 44.6743 19.7693 45.0001 20.1677 45.0001H44.2918C44.6901 45.0001 45 44.6743 45 44.2555V25.2246C44.9558 25.178 45 24.5266 44.2475 24.5266ZM35.7488 22.0605V24.5266H28.6665V22.0605H35.7488ZM30.6584 38.9511H33.7569C34.1553 38.9511 34.4651 38.6254 34.4651 38.2066V37.0899C36.6783 36.9037 40.6179 36.2523 43.5835 33.9258V43.5577H20.876V33.8793C23.8417 36.2057 27.7812 36.8572 29.9944 37.0433V38.1601C29.9502 38.6254 30.3043 38.9511 30.6584 38.9511ZM33.0487 33.7397V37.4621H31.3666V33.7397H33.0487ZM41.0162 33.7862C39.1571 34.7633 36.944 35.3683 34.4651 35.6009V32.9952C34.4651 32.5764 34.1553 32.2506 33.7569 32.2506H30.6584C30.6142 32.2506 30.6142 32.2506 30.5698 32.2506C30.2158 32.2972 29.9502 32.623 29.9502 32.9952V35.5543C27.4714 35.3217 25.2581 34.7169 23.399 33.7397C21.9826 32.9952 21.1416 32.2042 20.8317 31.8784V25.9691H43.5393V31.925C43.2738 32.2506 42.477 33.0417 41.0162 33.7862Z" fill="black" />
                <path className={className} d="M17.4826 45H0.864518C0.384245 45 0 44.6213 0 44.1482V0.851779C0 0.378526 0.384245 0 0.864518 0H28.7213C28.9614 0 29.1535 0.0945989 29.3456 0.236562L37.4145 7.99679C37.6065 8.13875 37.7027 8.37544 37.7027 8.612V16.5616C37.7027 17.0347 37.3185 17.4132 36.8382 17.4132C36.3579 17.4132 35.9737 17.0347 35.9737 16.5616V8.99053L28.3851 1.70343H1.77705V43.2964H17.4826C17.9628 43.2964 18.3471 43.675 18.3471 44.1482C18.3951 44.6213 17.9628 45 17.4826 45Z" fill="black" />
                <path className={className} d="M6.05551 8.9999H27.9985C28.6789 8.9999 29.1892 8.44884 29.1892 7.7141C29.1892 6.97953 28.6789 6.42847 27.9985 6.42847H6.05551C5.37517 6.42847 4.86487 6.97953 4.86487 7.7141C4.86487 8.44884 5.37517 8.9999 6.05551 8.9999Z" fill="black" />
                <path className={className} d="M5.81738 21.8571H23.3718C23.9161 21.8571 24.3243 21.306 24.3243 20.5713C24.3243 19.8367 23.9161 19.2856 23.3718 19.2856H5.81738C5.27311 19.2856 4.86487 19.8367 4.86487 20.5713C4.86487 21.306 5.27311 21.8571 5.81738 21.8571Z" fill="black" />
                <path className={className} d="M6.05551 15.4286H27.9985C28.6789 15.4286 29.1892 14.8776 29.1892 14.1428C29.1892 13.4082 28.6789 12.8572 27.9985 12.8572H6.05551C5.37517 12.8572 4.86487 13.4082 4.86487 14.1428C4.86487 14.8776 5.37517 15.4286 6.05551 15.4286Z" fill="black" />
                <path className={className} d="M5.79734 27.6996H15.8775C16.4103 27.6996 16.8099 27.2742 16.8099 26.7071C16.8099 26.1398 16.3659 25.7144 15.8775 25.7144H5.79734C5.26452 25.7144 4.86487 26.1398 4.86487 26.7071C4.86487 27.2742 5.26452 27.6996 5.79734 27.6996Z" fill="black" />
                <path className={className} d="M12.1031 32.8426H5.79746C5.26452 32.8426 4.86487 32.4171 4.86487 31.8499C4.86487 31.2826 5.26452 30.8572 5.79746 30.8572H12.0587C12.5915 30.8572 12.9911 31.2826 12.9911 31.8499C12.9911 32.4171 12.5915 32.8426 12.1031 32.8426Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_236_392">
                    <rect width="45" height="45" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default RoleIcon;

